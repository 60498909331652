import React, { useEffect, useState } from 'react';
import {useUser} from "../../../Services/userProvider";
import {toast} from "react-toastify";
import ManifestAPI from "../../../apis/manifestApi/manifestAPI";
import Pagination from "../../assets/Pagination";
import TableLoader from "../../loaders/TableLoader";
import Moment from "moment/moment";
import PrintComponent from "./printer";

const AllWeeklyBusManifest = () => {
    const user = useUser();

    const [total, setTotal] = useState("");
    const [allFrets, setAllBusFret] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [setting, setSetting] = useState([]);

    const [vivo, setOutPutData] = useState([]);
    const [startAt, setStartAt] = useState("");
    const [endAt, setEndAt] = useState("");

    const fetchSetting = async () => {
        try{
            const data =  await ManifestAPI.findSetting();
            setSetting(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };

    const getDailyFrets = async () => {
        try{
            const data =  await ManifestAPI.allWeeklyFretByBus();
            setAllBusFret(data.buses);
            setOutPutData(data.buses);
            setTotal(data.total);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des frets du jours !");
        }
    };

    useEffect(() => {
        getDailyFrets();
        fetchSetting();
    },[]);


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 500;

    //Filtrage des buses en fonction de la recherche
    const filteredFrets = vivo.filter(
        b =>
            b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
            b.to.toLowerCase().includes(search.toLowerCase()) ||
            b.vendor_name.toLowerCase().includes(search.toLowerCase()) ||
            b.from.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedFrets = Pagination.getData(
        filteredFrets,
        currentPage,
        itemsPerPage
    );


    let counter = 1;

    const handleStartedDate = ({currentTarget}) => {
        setStartAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleEndedDate = ({currentTarget}) => {
        setEndAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleBtns = () => {

        if(startAt !== "" && endAt === "") {
            const filtered = allFrets.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") === Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        } else if (startAt !== "" && endAt !== "") {
            const filtered = allFrets.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD")
                    && Moment(item.created_at).format("YYYY/MM/DD") <= Moment(endAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        }else{
            const filtered = allFrets.filter(item=>
                Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered)
        }

    };

    const fretByDestination = (data) => {
        const destinations = data.map((key, value) => key = key.city_to);
        let data_table = [];
        destinations.forEach((val) => {
            if (data_table[val]) {
                data_table[val] += 1;
            } else {
                data_table[val] = 1;
            }
        });
        return data_table;
    };


    return (
        <div className="container">
            <div className="row">

                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-10">
                                    {/*<h2 className="card-title">*/}
                                        {/*Total : <b className="text-primary">{paginatedAllTikets ? paginatedAllTikets.length : 0} </b> |*/}
                                        {/*Bus : {total && (<><b className="text-success">{calculTotal(paginatedAllTikets)}</b> CDF</>)} |*/}
                                        {/*Fret : {total && (<><b className="text-success">{calculTotalFret(allFrets)}</b> CDF</>)}*/}
                                    {/*</h2>*/}

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Recherche par Bus
                                        </label>
                                        <input type="text" onChange={handleSearch} value={search}
                                               name="ByAll" id="ByAll" className="form-control"
                                               placeholder="Rechercher ..."
                                               autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Date debut
                                        </label>
                                        <input type="date" onChange={handleStartedDate} value={startAt}
                                               name="startDate" id="startDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-3">
                                        <label htmlFor="endDate" className="text-dark">
                                            Date fin
                                        </label>
                                        <input type="date" onChange={handleEndedDate} value={endAt}
                                               name="endDate" id="endDate" className="form-control"
                                               placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-3">
                                        <label htmlFor="filter" className="text-dark">.</label>
                                        <input type="button" onClick={handleBtns} value="Filtrer"
                                               name="filter" id="filter" className="form-control btn btn-dark" placeholder="Filtrer ..."
                                        />
                                    </div>
                                </div>
                                {/*<div className="col-md-2">*/}
                                    {/*<div className="form-group mt-4 d-flex float-end">*/}
                                        {/*<PrintComponent type="billet" critere={search} data={filteredAllTikets} />*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            </div>


                            <hr/>
                            {!loading && <ol className="list-group list-group-numbered">
                                {paginatedFrets.map((bus) => (
                                    (bus.colis.length > 0) && <div key={bus.id}>
                                        <li className="list-group-item d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold"><span className="text-success">{bus.bus_numberplate}</span></div>
                                                vers : <b>{bus.to}</b>, Depart : {bus.bus_hour}<br/>
                                                <span className="">
                                                {/*<b className="text-success">{calculSubTotal(bus.colis)} Fc </b>*/}
                                            </span>
                                                <small>
                                                    <b className="text-danger">Le {Moment(bus.created_at).format("DD/MM/YYYY")}</b>,
                                                    Vendeur {bus.vendor_name} <br/>
                                                    Chauffeur : <b>{bus.driver_name}</b>, Escorteur : <b>{bus.manager_name}</b>
                                                </small>
                                            </div>

                                            <span className="badge bg-dark rounded-pill">{bus.colis.length} Frets embarqués</span>
                                            <a className="badge bg-success mx-2" data-bs-toggle="collapse"
                                               href={"#tickets"+bus.id} role="button" aria-expanded="false"
                                               aria-controls={"tickets"+bus.id}>Voir <i className="bi bi-eye"></i> </a>

                                            <div className="fw-bold">
                                                {fretByDestination(bus.colis).Lubumbashi && (
                                                    <><a>
                                                        <PrintComponent color="success" keyWord="Lubumbashi" bus={bus} setting={setting}/>
                                                    </a>
                                                        <br/></>)}

                                                {fretByDestination(bus.colis).Kolwezi && (
                                                    <><a>
                                                        <PrintComponent color="primary" keyWord="Kolwezi" bus={bus} setting={setting}/>
                                                    </a> <br/></> )}

                                                {fretByDestination(bus.colis).Likasi && (
                                                    <><a>
                                                        <PrintComponent color="dark" keyWord="Likasi" bus={bus} setting={setting}/>
                                                    </a><br/></> )}

                                                {fretByDestination(bus.colis).Fungurume && (
                                                    <><a>
                                                        <PrintComponent color="danger" keyWord="Fungurume" bus={bus} setting={setting}/>
                                                    </a> <br/></>)}

                                                {fretByDestination(bus.colis).Kasumbalesa && (
                                                    <><a>
                                                        <PrintComponent color="info" keyWord="Kasumbalesa" bus={bus} setting={setting}/>
                                                    </a> <br/></> )}

                                                {fretByDestination(bus.colis).Sakania && (
                                                    <><a>
                                                        <PrintComponent color="warning" keyWord="Sakania" bus={bus} setting={setting}/>
                                                    </a><br/></> )}
                                            </div>

                                        </li>
                                        <div className="collapse multi-collapse" id={"tickets"+bus.id}>
                                            <div className="card card-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <table className="table table-hover table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th scope="col">N°</th>
                                                                <th scope="col">Exp</th>
                                                                <th scope="col">Tél</th>
                                                                <th scope="col">Code</th>
                                                                <th scope="col">Designation</th>
                                                                <th scope="col">Nombre</th>
                                                                <th scope="col">Destinataire</th>
                                                                <th scope="col">Tél</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {bus.colis.map((fret) => (
                                                                <tr key={fret.id}>
                                                                    <td>{counter++}</td>
                                                                    <td>{fret.expediteur}</td>
                                                                    <td>{fret.expediteur_phone}</td>
                                                                    <td><b>{fret.reference}</b></td>
                                                                    <td>{fret.name}</td>
                                                                    <td className="text-center"><b>{fret.code}</b></td>
                                                                    <td>{fret.recipient}</td>
                                                                    <td>{fret.recipient_phone}</td>
                                                                    <td>
                                                                        {/*<button*/}
                                                                        {/*onClick={() => {*/}
                                                                        {/*if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {*/}
                                                                        {/*handleDelete(ticket.id)*/}
                                                                        {/*}*/}
                                                                        {/*}}*/}
                                                                        {/*className="btn btn-sm btn-danger">*/}
                                                                        {/*Sup*/}
                                                                        {/*</button>*/}
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </ol>}
                        </div>
                        {loading && <TableLoader width={300} height={350} />}

                        {itemsPerPage < filteredFrets.length &&
                        <Pagination
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            length={filteredFrets.length}
                            onPageChange={handleChangeCurrentPage}
                        />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllWeeklyBusManifest;
