import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import { toast } from "react-toastify";
import storeAPI from "../../../apis/adminApi/StoreAPI";
import TableLoader from "../../loaders/TableLoader";
import Pagination from "../../assets/Pagination";

const AdminStoreIndex = () => {

    const [stores, setStores] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    //Permet d'aller recuperer les invoices
    const fetchStores = async () => {
        try{
            const data =  await storeAPI.findAll();
            setStores(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des produits !");
        }
    };



    //Au chargement du composant on va chercher les stores
    useEffect(() => {
        fetchStores();
    }, []);

    const tooltip = (data) => (
        <Tooltip className="in" id="tooltip">
            <p>{data}</p>
        </Tooltip>
    );

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des stores en fonction de la recherche

    const filteredStores = stores.filter(
        b =>
            b.store_name.toLowerCase().includes(search.toLowerCase()) ||
            b.unit_name.toLowerCase().includes(search.toLowerCase()) ||
            b.store_quantity.toString().includes(search.toLowerCase()) ||
            b.min_store.toString().includes(search.toLowerCase()) ||
            b.max_store.toString().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedStores = Pagination.getData(
        filteredStores,
        currentPage,
        itemsPerPage
    );

    let numerotation = 1;

    const handleDeleteFret = async id => {

        try{
            await storeAPI.delete(id);
            toast.success("le produit a bien été supprimée !");
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de la suppression du produit !");
        }
    };



    return (
        <>
            <div className="pagetitle">
                <h1>Les produits</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les produits de garages</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-11">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-9">
                                        <h5 className="card-title">Total {stores ? stores.length : 0}</h5>
                                    </div>
                                    {/*<div className="col-3">*/}
                                    {/*    <Link className="btn btn-sm btn-primary mt-3 d-block" to="/admin/stores/add">*/}
                                    {/*        <i className="bi bi-plus-circle"></i> Ajouter un nouveau produit*/}
                                    {/*    </Link>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="form-group col-6">
                                    <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                </div>
                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nom</th>
                                        <th scope="col">Unité</th>
                                        <th scope="col">Quantité</th>
                                        <th scope="col">Qty Min</th>
                                        <th scope="col">Qty Max</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedStores.map((store) => (
                                            <tr key={store.id}>
                                                <th scope="row">{numerotation++}</th>
                                                <td>{store.store_name}</td>
                                                <td>{store.unit_name}</td>
                                                <td>{store.store_quantity}</td>
                                                <td>{store.min_store}</td>
                                                <td>{store.max_store}</td>
                                                <td>
                                                    <Link className="btn btn-sm btn-outline-primary mx-1" to={`/admin/stores/edit/${store.id}`}>
                                                        <i className="bi bi-pen-fill"></i>
                                                    </Link>
                                                    <button
                                                        onClick={() => {
                                                            if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                handleDeleteFret(store.id)
                                                            }
                                                        }}
                                                        className="btn btn-sm btn-danger">
                                                        Sup
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredStores.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredStores.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AdminStoreIndex;
