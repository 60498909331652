 import React, {useEffect, useState} from 'react';
 import {Link, useNavigate} from "react-router-dom";
 import {toast} from "react-toastify";
 import UsersAPI from "../../../apis/adminApi/userAPI";
 import Pagination from "../../assets/Pagination";
 import TableLoader from "../../loaders/TableLoader";

 const DispatchersIndex = () => {


     const [dispatchers, setDispatchers] = useState([]);
     const [currentPage, setCurrentPage] = useState(1);
     const [search, setSearch] = useState("");
     const [loading, setLoading] = useState(true);

     //Permet d'aller recuperer les invoices
     const fetchDispatchers = async () => {
         try{
             const data =  await UsersAPI.findAllDispatchers();
             setDispatchers(data);
             setLoading(false);
         }catch(error){
             toast.error("Erreur lors du chargement des users !");
         }
     };

     useEffect(() => {
         fetchDispatchers();
     },[]);



     //Gestion du changement de page
     const handleChangeCurrentPage = page => {
         setCurrentPage(page);
     };

     //Gestion de la recherche
     const handleSearch = ({currentTarget}) => {
         setSearch(currentTarget.value);
         setCurrentPage(1);
     };

     const itemsPerPage = 100;

     //Filtrage des buses en fonction de la recherche

     const filteredDispatchers = dispatchers.filter(
         v =>
             v.name.toLowerCase().includes(search.toLowerCase()) ||
             v.email.toLowerCase().includes(search.toLowerCase()) ||
             v.phone.toLowerCase().includes(search.toLowerCase()) ||
             v.city.toLowerCase().includes(search.toLowerCase())
     );

     //Pagination des données
     const paginatedDispatchers = Pagination.getData(
         filteredDispatchers,
         currentPage,
         itemsPerPage
     );



     let count = 1;


     return (
         <>
             <div className="pagetitle">
                 <h1>Les dispatchers</h1>
                 <nav>
                     <ol className="breadcrumb">
                         <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                         <li className="breadcrumb-item active">Les dispatchers de bus KKS</li>
                     </ol>
                 </nav>
             </div>

             <section className="section">
                 <div className="row">
                     <div className="col-lg-12">
                         <div className="card">
                             <div className="card-body">
                                 <div className="row">
                                     <div className="col-6">
                                         <h5 className="card-title">Total {dispatchers ? dispatchers.length : 0}</h5>
                                     </div>
                                     <div className="col-6">
                                         <Link className="btn btn-sm btn-primary mt-3" to="/admin/users/add">
                                             <i className="bi bi-plus-circle"></i> Ajouter un dispatcher
                                         </Link>
                                     </div>
                                 </div>
                                 <div className="row">
                                     <div className="col-3">
                                         <div className="form-group">
                                             <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                         </div>
                                     </div>
                                 </div>
                                 <table className="table table-hover">
                                     <thead>
                                     <tr>
                                         <th scope="col">#</th>
                                         <th scope="col">Nom</th>
                                         <th scope="col">Email</th>
                                         <th scope="col">Telephone</th>
                                         <th scope="col">Ville de residence</th>
                                         <th scope="col">Options</th>
                                     </tr>
                                     </thead>
                                     {!loading && <tbody>
                                     {paginatedDispatchers.map((manager) => (
                                         <tr key={manager.id}>
                                             <th scope="row">{count++}</th>
                                             <td>{manager.name}</td>
                                             <td>{manager.email}</td>
                                             <td>{manager.phone}</td>
                                             <td>{manager.city}</td>

                                             <td>
                                                 <Link className="btn btn-sm btn-success mx-1" to={`/admin/users/edit/${manager.id}`}>
                                                     <i className="bi bi-pen-fill"></i>
                                                 </Link>
                                                 <Link className="btn btn-sm btn-outline-danger mx-2" to={`/admin/users/updateUserPassword/${manager.id}`}>
                                                     Mot de passe
                                                 </Link>
                                             </td>
                                         </tr>
                                     ))
                                     }

                                     </tbody> }
                                 </table>

                                 {loading && <TableLoader width={500} height={550} />}

                                 {itemsPerPage < filteredDispatchers.length &&
                                 <Pagination
                                     currentPage={currentPage}
                                     itemsPerPage={itemsPerPage}
                                     length={filteredDispatchers.length}
                                     onPageChange={handleChangeCurrentPage}
                                 />
                                 }

                             </div>
                         </div>
                     </div>
                 </div>
             </section>
         </>
     );
 };

 export default DispatchersIndex;
