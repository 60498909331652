import axios from "axios";
import {STORES_API, MVT_API, MAINTAINER_API, REQUESTS_API} from "../../config";
import Cache from "../cache";

async function findAll() {
    return axios
        .get(STORES_API)
        .then(response => {
            return response.data.stores;
        });
}

async function findAlMvtOut(){
    return axios
        .get(MVT_API + "/out")
        .then(response => {
            return response.data.movements;
        });
}

async function findAlMvt() {
    return axios
        .get(MVT_API)
        .then(response => {
            return response.data.movements;
        });
}

async function find(id){
    return axios.get(STORES_API + "/" + id)
        .then(response => {
            return response.data.store;
        });
}


function deleteStore(id) {

    return axios
        .get(STORES_API + "/" + id + "/del")
        .then(async response => {
            return response;
        });
}

function updateStore(id, store){

    return axios.post(STORES_API + "/store", {...store, id: id})
        .then(async response => {
            return response;
        });
}

function create(store){

    return axios.post(STORES_API + "/store", store)
        .then(async response => {
            return response;
        });
}

function addMouvement(movement){

    return axios.post(STORES_API + "/movements/store", movement)
        .then(async response => {
            return response;
        });
}

async function findRequestsStatistics()
{
    return axios
        .get(REQUESTS_API+"/statistics")
        .then(response => {
            return response.data;
        });
}

async function findRequests(){
    return axios
        .get(REQUESTS_API)
        .then(response => {
            return response.data.requests;
        });
}

async function findRequestsByBus(budId){
    return axios
        .get(REQUESTS_API + "/" + budId + "/byBus")
        .then(response => {
            return response.data;
        });
}

function changeRequestStatus(id) {
    return axios
        .get(REQUESTS_API+ "/"+ id + "/changeStatus")
        .then(async response => {
            return response;
        });
}



export default {
    findAll,
    changeRequestStatus,
    findRequests,
    findRequestsStatistics,
    findRequestsByBus,
    findAlMvt,
    findAlMvtOut,
    addMouvement,
    find,
    create,
    updateStore,
    delete : deleteStore,
};