import React, {useEffect, useState} from 'react';
import AuthService from "../../../Services/auth/authAPI";
import {Link, useNavigate, useParams} from "react-router-dom";
import MaintainerAPI from "../../../apis/maintainerApi/MaintainerAPI";
import {toast} from "react-toastify";

const MaintainerSideBar = ({pathname}) => {

    const id = useParams();

    const navigate = useNavigate();
    const [me, setMe] = useState(AuthService.getAuthenticatedUser());
    const [movements, setMovements] = useState([]);
    const [unreceived, setUnreceived] = useState("");
    const [partialReceived, setPartialReceived] = useState("");
    const fetchStockMovements = async () => {
        try{
            const data =  await MaintainerAPI.findStockMovements();
            //setMovements(data.movements);
            setUnreceived(data.unreceived);
            setPartialReceived(data.partialReceived);
        }catch(error){
            toast.error("Erreur lors du chargement des mouvements !");
        }
    };

    useEffect(() => {
        fetchStockMovements();
    },[]);

    const handleLogout = () => {
        AuthService.logout();
        navigate("/login")
    };

    return (
        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <Link className={pathname === '/maintainer' ? 'nav-link ' : 'nav-link collapsed'} to="/maintainer">
                        <i className="bi bi-grid"></i>
                        <span>Tableau de bord</span>
                    </Link>
                </li>

                <li className="nav-heading">MECANICIENS</li>

                <li className="nav-item">
                    <Link className={pathname === '/maintainer/mechanics' ? 'nav-link ' : 'nav-link collapsed'} to="/maintainer/mechanics">
                        <i className="bi bi-person"></i>
                        <span>Les mecaniciens</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/maintainer/mechanicTasks/allBus' ? 'nav-link ' : 'nav-link collapsed'} to="/maintainer/mechanicTasks/allBus">
                        <i className="bi bi-person"></i>
                        <span>Toutes Taches de mecaniciens</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/maintainer/mechanicTasks' ? 'nav-link ' : 'nav-link collapsed'} to="/maintainer/mechanicTasks">
                        <i className="bi bi-person"></i>
                        <span>Taches de mecaniciens par bus</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/maintainer/drivers' ? 'nav-link ' : 'nav-link collapsed'} to="/maintainer/drivers">
                        <i className="bi bi-person"></i>
                        <span>Les chauffeurs</span>
                    </Link>
                </li>


                <li className="nav-heading">Bus</li>

                <li className="nav-item">
                    <Link className={pathname === '/maintainer/brands' ? 'nav-link ' : 'nav-link collapsed'} to="/maintainer/brands">
                        <i className="bi bi-bus-front"></i>
                        <span>Les genres de bus</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/maintainer/buses' ? 'nav-link ' : 'nav-link collapsed'} to="/maintainer/buses">
                        <i className="bi bi-bus-front"></i>
                        <span>Les bus</span>
                    </Link>
                </li>


                <li className="nav-heading">STOCK DE PIECES</li>

                <li className="nav-item">
                    <Link className={pathname === '/maintainer/stocks' ? 'nav-link ' : 'nav-link collapsed'} to="/maintainer/stocks">
                        <i className="bi bi-boxes"></i>
                        <span>Le stock</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/maintainer/stocks/stock-movements' ? 'nav-link ' : 'nav-link collapsed'} to="/maintainer/stocks/stock-movements">
                        <i className="bi bi-activity"></i>
                        <span>Mouvement de stock
                            {(unreceived + partialReceived) > 0 &&
                                 <span className="badge rounded-pill bg-success"> {unreceived + partialReceived}</span>
                            }
                        </span>
                    </Link>
                </li>

                <li className="nav-heading">Maintenance</li>

                <li className="nav-item">
                    <Link className={pathname === '/maintainer/maintenances' ? 'nav-link ' : 'nav-link collapsed'} to="/maintainer/maintenances">
                        <i className="bi bi-key"></i>
                        <span>Entretiens par Bus</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/maintainer/maintenances/allBus' ? 'nav-link ' : 'nav-link collapsed'} to="/maintainer/maintenances/allBus">
                        <i className="bi bi-key"></i>
                        <span>Entretiens Tous les bus</span>
                    </Link>
                </li>

                <li className="nav-heading">Demande d'achat</li>

                <li className="nav-item">
                    <Link className={pathname === '/maintainer/requests' ? 'nav-link ' : 'nav-link collapsed'} to="/maintainer/requests">
                        <i className="bi bi-box-seam"></i>
                        <span>demandes par Bus</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/maintainer/requests/allBus' ? 'nav-link ' : 'nav-link collapsed'} to="/maintainer/requests/allBus">
                        <i className="bi bi-box-seam"></i>
                        <span>Toutes les demandes</span>
                    </Link>
                </li>


            </ul>

        </aside>
    );
};

export default MaintainerSideBar;