import React, { useEffect, useState, useRef } from 'react';
import './printer.css';
import logo from './../logo.png';
import Moment from "moment";
import {Link} from "react-router-dom";
import PrintComponent from "./printer";

class ComponentToPrint extends React.Component {

    render() {
        const param = this.props.param;
        const maintenances = this.props.maintenances;
        const bus = this.props.bus;
        let counter = 1;

        return (
            <div className="ms-2">
                {maintenances && <div className="">
                        <center><img src={logo} alt="logo" /></center>
                    <p className="centered"><b>{param && param.company_address}</b></p>
                    <hr/>
                    <table className="ms-3">
                        <thead>
                        <tr>
                            <th style={{fontSize:"19px"}}>DEMANDE D'ACHAT</th>
                        </tr>
                        {bus.id && <tr>
                            <th style={{fontSize:"19px"}}>Bus : {bus.bus_numberplate}</th>
                        </tr>}

                        </thead>
                    </table>
                    <hr/>
                    <table className="table table-hover table-striped table-responsive">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Demandeur</th>
                            <th scope="col">Bus</th>
                            <th scope="col">Genre Bus</th>
                            <th scope="col">Chauffeur</th>
                            <th scope="col">Date</th>
                            <th scope="col">PIECE DE RECHANGE</th>
                            <th scope="col">Montant</th>
                            <th scope="col">OBSERVATION</th>
                            <th scope="col">Livré au garage</th>
                        </tr>
                        </thead>
                        <tbody>
                        {maintenances.map((requestModel) => (
                            <tr key={requestModel.id}>
                                <th scope="row">{counter++}</th>
                                <td>{requestModel.applicant}</td>
                                <td>{requestModel.bus_numberplate}</td>
                                <td>{requestModel.bus_brand}</td>
                                <td>{requestModel.driver_name}</td>
                                <td>{Moment(requestModel.created_at).format("DD/MM/YYYY")}</td>
                                <td>
                                    <ul>
                                        {requestModel.request_items.map((item) =>
                                            <li>{item.product_name} : {item.requested_quantity}</li>
                                        )}
                                    </ul>
                                </td>
                                <td>{requestModel.total_amount} CDF</td>
                                <td>{requestModel.observation}</td>
                                <td>
                                    {requestModel.delivered === 2
                                        ?
                                        <span className="badge badge-sm bg-success">Oui</span>
                                        :
                                        <span className="badge badge-sm bg-danger">Non</span>
                                    }
                                </td>
                            </tr>
                        ))
                        }
                        </tbody>
                    </table>
                   
                        <p className="centered">{param && param.company_idnat}<br />
                        {param && param.company_rccm}<br />
                            {param && param.company_impot} </p>

                    </div>}
            </div>
        );
    }
};
export default ComponentToPrint;
