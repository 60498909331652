import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import MechanicAPI from "../../apis/maintainerApi/MaintainerAPI";
import Field from "../forms/Field";
import MyButton from "../assets/MyButton";
import FormContentLoader from "../loaders/FormContentLoader";
import MaintainerAPI from "../../apis/maintainerApi/MaintainerAPI";

const EditStockMaintainer = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {stockId} = useParams();
    const [showLoader, setShowLoader] = useState(false);
    const [checked, setChecked] = useState(false);

    const [stock, setStock] = useState({
        stock_name : "", quantity : "", brand_id : "", id : stockId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        stock_name : "", quantity : "", brand_id : ""
    });

    const [brands, setBrands] = useState([]);

    const fetchBrands = async () => {
        try{
            const data =  await MaintainerAPI.findBrands();
            setBrands(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des genres !");
        }
    };

    useEffect(() => {
        fetchBrands();
    },[]);

    const fetchStock = async id => {
        try{
            const { stock_name, quantity, brand_id} = await MechanicAPI.findOneStock(id);
            setStock({ stock_name, quantity, brand_id });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le mecanicien démandé !");
            navigate("/maintainer/mechanics");
        }
    };



    useEffect(() => {
        setEditing(true);
        fetchStock(stockId);
    }, [stockId]);


    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setStock({...stock, [name] : value})
    };

    let redirect = "stocks";



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await MechanicAPI.updateStock(stockId, stock);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Piece modifiés avec succès");
            navigate('/maintainer/stocks')
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {error} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification de la piece !");
            }else if (error) {
                toast.error(error);
            }
        }
    };



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Modifier une piece</h5>

                                <Field
                                    name="stock_name"
                                    error={errors.stock_name}
                                    value={stock.stock_name}
                                    onChange={handleChange}
                                    label="Nom Complet"
                                />

                                <Field
                                    name="quantity"
                                    type="number"
                                    error={errors.quantity}
                                    value={stock.quantity}
                                    onChange={handleChange}
                                    label="Quantity"
                                />
                                <label htmlFor="brand_id" className="form-label">Genre</label>
                                <select
                                    className={"form-select mb-3 " + ( errors.brand_id && "is-invalid")}
                                    //className={"form-select-lg mb-1 form-control " + ( errors.brand_id && "is-invalid")}
                                    size={brands.length}
                                    name="brand_id"
                                    id="brand_id"
                                    value={stock.brand_id}
                                    onChange={handleChange}
                                >
                                    <option value="" selected>Choisir...</option>
                                    {brands && brands.map(brand =>
                                        <option
                                            style={{fontWeight:"bold"}}
                                            key={brand.id} value={brand.id}>{brand.brand_name}</option>)
                                    }
                                </select>
                                {errors.brand_id && <div className="invalid-feedback mb-4">{errors.brand_id}</div>}


                                <MyButton
                                    text="Modifier"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to={`/maintainer/${redirect}`} className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </div>
                    </form> }
                </div>
            </div>
        </div>
    );
};

export default EditStockMaintainer;
