import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Moment from "moment";
import DispatcherAPI from "../../apis/dispatcherApi/dispatcherAPI";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import {toast} from "react-toastify";
import Pagination from "../assets/Pagination";
import TableLoader from "../loaders/TableLoader";

const AllDailyAvalablities = () => {

    const [availablities, setAvailablities] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    let counter = 1;

    const fetchBusesAvailabilities = async () => {
        try{
            const data =  await DispatcherAPI.findAllDailyDispatchers();
            setAvailablities(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des disponibilités des bus !");
        }
    };

    useEffect(() => {
        fetchBusesAvailabilities();
        //console.log(availablities);
    },[]);

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des buses en fonction de la recherche

    const filteredAvailabilities = availablities.filter(
        b =>
            b.bus_name.toLowerCase().includes(search.toLowerCase()) ||
            b.driver_name.toLowerCase().includes(search.toLowerCase()) ||
            b.escort_name.toLowerCase().includes(search.toLowerCase()) ||
            b.city_from.toLowerCase().includes(search.toLowerCase()) ||
            b.city_to.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedAvailabilities = Pagination.getData(
        filteredAvailabilities,
        currentPage,
        itemsPerPage
    );

    let countir = 1;

    const tooltip = (data) => (
        <Tooltip className="in" id="tooltip">
            <p>{data}</p>
        </Tooltip>
    );


    return (
        <>
            <div className="pagetitle">
                <h1>Les bus du jour</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dispatcher">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les bus disponibles</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-5">
                                        <h5 className="card-title">Total {availablities ? availablities.length : 0}</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group mt-2">
                                            <input type="text" onChange={handleSearch}
                                                   value={search} className="form-control"
                                                   placeholder="Rechercher tout ..." />
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <Link to="/dispatcher/buses/addDispatcherBus" className="mt-2 btn btn-sm btn-primary">
                                            Programmer un bus <i className="bi bi-bus-front"></i>
                                        </Link>
                                    </div>
                                </div>

                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Bus</th>
                                        <th scope="col">Priorité</th>
                                        <th scope="col">Origine</th>
                                        <th scope="col">Destination</th>
                                        <th scope="col">Driver</th>
                                        <th scope="col">Escorteur</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Affecté</th>
                                        <th scope="col"><i className="bi bi-gear-fill"></i></th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedAvailabilities.map((dispatch) => (
                                        <tr key={dispatch.id}>
                                            <th scope="row">{countir++}</th>
                                            <td><b>{dispatch.bus_name}</b></td>
                                            <td><b>{dispatch.priority}</b></td>
                                            <td className="text-danger">{dispatch.city_from}</td>
                                            <td className="text-success">{dispatch.city_to}</td>
                                            <td>{dispatch.driver_name}</td>
                                            <td>{dispatch.escort_name}</td>
                                            <td>{Moment(dispatch.day).format("DD-MM-YYYY")}</td>
                                            <td>{dispatch.programed ? (<span className="text-success">Oui</span>):(<span className="text-danger">Non</span>)}</td>
                                            <td>
                                                {!dispatch.programed ? <Link className="btn btn-sm btn-primary" to={`/dispatcher/dispatch/${dispatch.id}/edit`}>
                                                    <i className="bi bi-pen-fill"></i>
                                                    <span>Modif</span>
                                                </Link> : <></>}
                                            </td>
                                        </tr>
                                        ))
                                    }

                                    </tbody>}
                                </table>

                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredAvailabilities.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredAvailabilities.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AllDailyAvalablities;
