import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import {useForm} from "react-hook-form";
import BusesAPI from "../../../apis/adminApi/busAPI";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";
import MaintainerAPI from "../../../apis/maintainerApi/MaintainerAPI";

const AddTrajet = () => {
    const { formState } = useForm();
    const { isSubmitting } = formState;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [showLoader, setShowLoader] = useState(false);
    const [brands, setBrands] = useState([]);

    const [bus, setBus] = useState({
        bus_code : "", brand_id: "", bus_numberplate : "", bus_seat_number : ""
    });

    const [errors, setErrors] = useState({
        bus_code : "",  brand_id: "",bus_numberplate : "", bus_seat_number : ""
    });

    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setBus({...bus, [name] : value})
    };

    const fetchBrands = async () => {
        try{
            const data =  await MaintainerAPI.findBrands();
            setBrands(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des genres !");
        }
    };

    useEffect(() => {
        fetchBrands();
    },[]);


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await BusesAPI.create(bus);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Bus ajouté avec succès");
            navigate("/admin/buses");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du bus !");
            }
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h5 className="card-title">Ajouter un bus</h5>
                                <Field
                                name="bus_code"
                                error={errors.bus_code}
                                value={bus.bus_code}
                                onChange={handleChange}
                                label="Code du bus"
                                placeholder="Code du bus"
                                />

                                <Field
                                    name="bus_numberplate"
                                    error={errors.bus_numberplate}
                                    value={bus.bus_numberplate}
                                    onChange={handleChange}
                                    label="Numero de plaque"
                                    placeholder="Plaque"
                                />

                                <Field
                                    name="bus_seat_number"
                                    error={errors.bus_seat_number}
                                    value={bus.bus_seat_number}
                                    onChange={handleChange}
                                    label="Nombre de sièges"
                                    placeholder="Siege"
                                />

                                <label htmlFor="brand_id" className="form-label">Marque</label>
                                <select
                                    className={"form-select mb-3 " + ( errors.brand_id && "is-invalid")}
                                    //className={"form-select-lg mb-1 form-control " + ( errors.brand_id && "is-invalid")}
                                    size={brands.length}
                                    name="brand_id"
                                    id="brand_id"
                                    value={bus.brand_id}
                                    onChange={handleChange}
                                >
                                    <option value="" selected>Choisir...</option>
                                    {brands && brands.map(brand =>
                                        <option
                                            style={{fontWeight:"bold"}}
                                            key={brand.id} value={brand.id}>{brand.brand_name}</option>)
                                    }
                                </select>
                                {errors.brand_id && <div className="invalid-feedback mb-4">{errors.brand_id}</div>}

                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to="/admin/buses" className="btn btn-info mx-2">Revenir aux bus</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTrajet;
