import axios from "axios";
import {DISPATCHER_API} from "../../config";
import Cache from "../cache";


async function findAll() {
    const cachedBuses = await Cache.get("DispatcherBuses");
    //if(cachedBuses) return cachedBuses;
    return axios
        .get(DISPATCHER_API + "/buses")
        .then(response => {
            const buses = response.data.buses;
            Cache.set("DispatcherBuses", buses);
            return buses;
        });
}

async function findAvailableDailyBuses() {
    const cachedDispatcherAvailablesBuses = await Cache.get("DispatcherAvailablesBuses");
    //if(cachedDispatcherAvailablesBuses) return cachedDispatcherAvailablesBuses;
    return axios
        .get(DISPATCHER_API)
        .then(response => {
            const buses = response.data.buses;
            Cache.set("DispatcherAvailablesBuses", buses);
            return buses;
        });
}
async function findDrivers() {
    const cachedDispatcherDrivers = await Cache.get("DispatcherDrivers");
    //if(cachedDispatcherDrivers) return cachedDispatcherDrivers;
    return axios
        .get(DISPATCHER_API + "/users/drivers")
        .then(response => {
            const drivers = response.data.drivers;
            Cache.set("DispatcherDrivers", drivers);
            return drivers;
        });
}

async function findBusEscortors() {
    const cachedDispatcherExcortors = await Cache.get("DispatcherExcortors");
    //if(cachedDispatcherExcortors) return cachedDispatcherExcortors;
    return axios
        .get(DISPATCHER_API + "/users/escorsteurs")
        .then(response => {
            const escorsteurs = response.data.bus_managers;
            Cache.set("DispatcherExcortors", escorsteurs);
            return escorsteurs;
        });
}

async function findVendors() {
    const cachedDispatcherVendeurs = await Cache.get("DispatcherVendeurs");
    //if(cachedDispatcherVendeurs) return cachedDispatcherVendeurs;
    return axios
        .get(DISPATCHER_API + "/users/vendors")
        .then(response => {
            const vendors = response.data.vendeurs;
            Cache.set("DispatcherVendeurs", vendors);
            return vendors;
        });
}

async function findAllDispatchers()
{
    return axios
        .get(DISPATCHER_API + "/buses/allDispatchers")
        .then(response => {
            return response.data.dispatchers;
        });
}

async function findAllWeeklyDispatchers(){
    return axios
        .get(DISPATCHER_API + "/buses/weeklyDispatchers")
        .then(response => {
            return response.data.dispatchers;
        });
}

async function findAllDailyDispatchers() {
    return axios
        .get(DISPATCHER_API + "/buses/dailyDispatchers")
        .then(response => {
            return response.data.dispatchers;
        });
}

async function findDailyAvailabilities() {
    return axios
        .get(DISPATCHER_API + "/buses/availablitiesDaily")
        .then(response => {
            return response.data.availabilities;
        });
}

async function findSheduledAvailabilities() {
    return axios
        .get(DISPATCHER_API + "/availablitiesSheduled")
        .then(response => {
            return response.data.availabilities;
        });
}


async function dailyAvailabilities() {
    return axios
        .get(DISPATCHER_API + "/buses/dailyAvailabilities")
        .then(response => {
            //console.log(response.data);
            return response.data;
        });
}

async function findOneDispatch(id){


    return axios.get(DISPATCHER_API + "/buses/dispatchers/" + id)
        .then(response => {
            return response.data.dispatch;
        });
}

function updateDispatcher (id, dispatch){

    return axios.post(DISPATCHER_API + "/buses/editDispatcher", {...dispatch, id: id})
        .then(async response => {
            return response;
        });
}

function addDispatch(bus){

    return axios.post(DISPATCHER_API + "/buses/addDispatch", bus)
        .then(async response => {
            return response;
        });
}

async function findBusHours() {
    const cachedBusHours = await Cache.get("DispatcherBusHours");
    //if(cachedBusHours) return cachedBusHours;
    return axios
        .get(DISPATCHER_API + "/buses/busesHours")
        .then(response => {
            const busesHours = response.data.busesHours;
            Cache.set("DispatcherBusHours", busesHours);
            return busesHours;
        });
}


async function findTrajects() {
    const cachedDispatcherTrajets = await Cache.get("DispatcherTrajets");
    //if(cachedDispatcherTrajets) return cachedDispatcherTrajets;
    return axios
        .get(DISPATCHER_API + "/trajets")
        .then(response => {
            const trajets = response.data.trajets;
            Cache.set("DispatcherTrajets", trajets);
            return trajets;
        });
}


function createAssignment(assignment){

    return axios.post(DISPATCHER_API + "/addBusAssignment", assignment)
        .then(async response => {
            const cachedDispatcherDailyAssignment = await Cache.get("DispatcherDailyAssignments");

            if(cachedDispatcherDailyAssignment){
                Cache.set("DispatcherDailyAssignments", [...cachedDispatcherDailyAssignment, response.data.assignment]);
            }

            return response;
        });
}


function updateAssignment(id, assignment){
    return axios.post(DISPATCHER_API + "/editBusAssignment", {...assignment, id: id})
        .then(async response => {
            const cachedDispatcherDailyAssignments = await Cache.get("DispatcherDailyAssignments");

            const cachedDispatcherDailyAssignment = await Cache.get("DispatcherDailyAssignments." + id);

            if(cachedDispatcherDailyAssignment){
                Cache.set("DispatcherDailyAssignments." + id, response.data.assignment);
            }

            if(cachedDispatcherDailyAssignments){
                const index = cachedDispatcherDailyAssignments.findIndex(b => b.id === +id);
                cachedDispatcherDailyAssignments[index] = response.data.assignment;
            }

            return response;
        });
}



export default {
    findAll,
    findAllDispatchers,
    addDispatch,
    dailyAvailabilities,
    findDailyAvailabilities,
    findSheduledAvailabilities,
    findOneDispatch,
    updateDispatcher,
    findAllDailyDispatchers,
    findAllWeeklyDispatchers,
    findDrivers,
    findBusEscortors,
    findVendors,
    findAvailableDailyBuses,
    findBusHours,
    findTrajects,
};