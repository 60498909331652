import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import { toast } from "react-toastify";
import DocumentApi from "../../../apis/adminApi/DocumentAPI";
import TableLoader from "../../loaders/TableLoader";
import Pagination from "../../assets/Pagination";
import Moment from "moment";
import logo from "./alert.gif";

const AdminBusDocumentIndex = () => {

    const [documents, setBusDocuments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    //Permet d'aller recuperer les invoices
    const fetchBusDocuments = async () => {
        try{
            const data =  await DocumentApi.findBusDocuments();
            setBusDocuments(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des documents de bord !");
        }
    };



    //Au chargement du composant on va chercher les busDocuments
    useEffect(() => {
        fetchBusDocuments();
    }, []);

    const tooltip = (data) => (
        <Tooltip className="in" id="tooltip">
            <p>{data}</p>
        </Tooltip>
    );

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des busDocuments en fonction de la recherche
    const filteredStores = documents.filter(
        b =>
            b.document_name.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
            b.edition.toString().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedStores = Pagination.getData(
        filteredStores,
        currentPage,
        itemsPerPage
    );

    let numerotation = 1;

    function parseDate(date1, date2) {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const firstDate = new Date(date1);
        const secondDate = new Date(date2);

        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
        return diffDays;
    }


    //console.log(new Date().getDay());
    //console.log(new Date().getDate());
    return (
        <>
            <div className="pagetitle">
                <h1>Les documents de bord</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les documents de bord en cours</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-11">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {documents ? documents.length : 0}</h5>
                                    </div>
                                    {/*<div className="col-3">*/}
                                    {/*    <Link className="btn btn-sm btn-primary mt-3 d-block" to="/admin/documents/add">*/}
                                    {/*        <i className="bi bi-plus-circle"></i> Ajouter un document*/}
                                    {/*    </Link>*/}
                                    {/*</div>*/}
                                    <div className="col-3">
                                        <Link className="btn btn-sm btn-success mt-3 d-block" to="/admin/documents/bus_documents/add">
                                            <i className="bi bi-plus-circle"></i> Lier un document à un bus
                                        </Link>
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                </div>
                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Alerte</th>
                                        <th scope="col">Bus</th>
                                        <th scope="col">Document</th>
                                        <th scope="col">Edition</th>
                                        <th scope="col">Date d'expiration</th>
                                        <th scope="col">Date d'alerte</th>
                                        <th scope="col">Expire dans</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedStores.map((store) => (
                                            <tr className={new Date().getDate() >= store.alerted_at ? "bg-danger-light" : ""} key={store.id}>
                                                <th scope="row">{numerotation++}</th>
                                                <td>{parseDate(store.expired_at, new Date()) <= 10 ? <><img width={35} src={logo} alt="logo" /></> : <></>}</td>
                                                <td>{store.bus_numberplate}</td>
                                                <td>{store.document_name}</td>
                                                <td>{store.edition}</td>
                                                <td>Le {Moment(store.expired_at).format("DD/MM/YYYY")}</td>
                                                <td className="text-primary">Le {Moment(store.alerted_at).format("DD/MM/YYYY")}</td>
                                                <td>{parseDate(store.expired_at, new Date())} Jour(s)</td>
                                                <td>
                                                    <Link className="btn btn-sm btn-outline-primary mx-1" to={`/admin/documents/bus_documents/edit/${store.id}`}>
                                                        <i className="bi bi-pen-fill"></i> Modifier
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredStores.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredStores.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AdminBusDocumentIndex;
