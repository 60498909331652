import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import { toast } from "react-toastify";
import BusesAPI from "../../../apis/adminApi/busAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import Field from "../../forms/Field";
import {useForm} from "react-hook-form";
import MyButton from "../../assets/MyButton";
import MaintainerAPI from "../../../apis/maintainerApi/MaintainerAPI";

const EditBus = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {busId} = useParams();

    const [showLoader, setShowLoader] = useState(false);
    const [brands, setBrands] = useState([]);

    const [bus, setBus] = useState({
        bus_code : "", brand_id: "", bus_numberplate : "", bus_seat_number : "", id : busId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        bus_code : "", bus_numberplate : "", bus_seat_number : "" , brand_id: ""
    });


    //Recuperation d'une facture
    const fetchBus = async id => {
        try{
            const { bus_code, bus_numberplate, bus_seat_number } = await BusesAPI.find(id);
            setBus({ bus_code, bus_numberplate, bus_seat_number });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger la facture démandée !");
            navigate("/buses");
        }
    };


    useEffect(() => {
        setEditing(true);
        fetchBus(busId);
    }, [busId]);

    const fetchBrands = async () => {
        try{
            const data =  await MaintainerAPI.findBrands();
            setBrands(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des genres !");
        }
    };

    useEffect(() => {
        fetchBrands();
    },[]);


    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setBus({...bus, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await BusesAPI.updateBus(busId, bus);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Bus modifié avec succès");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du bus !");
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}

                    {!loading && <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier un bus</h5>

                                        <Field
                                            name="bus_code"
                                            error={errors.bus_code}
                                            value={bus.bus_code}
                                            onChange={handleChange}
                                            label="Code du bus"
                                            placeholder="Code du bus"
                                        />

                                        <Field
                                            name="bus_numberplate"
                                            error={errors.bus_numberplate}
                                            value={bus.bus_numberplate}
                                            onChange={handleChange}
                                            label="Numero de plaque"
                                            placeholder="Plaque"
                                        />

                                        <Field
                                            name="bus_seat_number"
                                            error={errors.bus_seat_number}
                                            value={bus.bus_seat_number}
                                            onChange={handleChange}
                                            label="Nombre de sièges"
                                            placeholder="Siege"
                                        />


                                        <label htmlFor="brand_id" className="form-label">Marque</label>
                                        <select
                                            className={"form-select mb-3 " + ( errors.brand_id && "is-invalid")}
                                            //className={"form-select-lg mb-1 form-control " + ( errors.brand_id && "is-invalid")}
                                            size={brands.length}
                                            name="brand_id"
                                            id="brand_id"
                                            value={bus.brand_id}
                                            onChange={handleChange}
                                        >
                                            <option value="" selected>Choisir...</option>
                                            {brands && brands.map(brand =>
                                                <option
                                                    style={{fontWeight:"bold"}}
                                                    key={brand.id} value={brand.id}>{brand.brand_name}</option>)
                                            }
                                        </select>
                                        {errors.brand_id && <div className="invalid-feedback mb-4">{errors.brand_id}</div>}

                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to="/admin/buses" className="btn btn-info mx-2">Revenir aux bus</Link>
                                    </div>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    );
};

export default EditBus;
