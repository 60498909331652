import React, {useEffect, useState} from 'react';
import AuthService from "../../../Services/auth/authAPI";
import {Link, useNavigate, useParams} from "react-router-dom";
import StoreAPI from "../../../apis/adminApi/StoreAPI";
import {toast} from "react-toastify";
import DocumentApi from "../../../apis/adminApi/DocumentAPI";
import logo from "../../admin/documents/alert.gif";

const AdminSidebar = ({pathname}) => {

    const id = useParams();
    const [undelivered, setRequests] = useState([]);
    const [partialDelivered, setPartialDelivered] = useState([]);
    const navigate = useNavigate();
    const [me, setMe] = useState(AuthService.getAuthenticatedUser());
    const [documents, setBusDocuments] = useState([]);

    const fetchRequests = async () => {
        try{
            const data =  await StoreAPI.findRequestsStatistics();
            setRequests(data.undelivered);
            setPartialDelivered(data.partialDelivered);
        }catch(error){
            toast.error("Erreur lors de chargement de demande de piece !");
        }
    };

    const fetchBusDocuments = async () => {
        try{
            const data =  await DocumentApi.findBusDocuments();
            setBusDocuments(data);
        }catch(error){
            toast.error("Erreur lors du chargement des documents de bord !");
        }
    };

    useEffect(() => {
        fetchBusDocuments();
        fetchRequests();
    }, []);

    const handleLogout = () => {
        AuthService.logout();
        navigate("/login")
    };

    function parseDate(date1, date2) {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const firstDate = new Date(date1);
        const secondDate = new Date(date2);
        return  Math.round(Math.abs((firstDate - secondDate) / oneDay));
    }

    const busDocumentsStats = (data) => {
        let data_table = 0;
        data.map((doc) =>
            parseDate(doc.expired_at, new Date()) <= 10 ? data_table +=1 : data_table += 0
        );

        return data_table;
    };



    return (
        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <Link className={pathname === '/admin' ? 'nav-link ' : 'nav-link collapsed'} to="/admin">
                        <i className="bi bi-grid"></i>
                        <span>Dashboard</span>
                    </Link>
                </li>

                <li className="nav-heading">UTILISATEURS</li>


                <li className="nav-item">
                    <a className={pathname === '/admin/vendeurs' || pathname === '/admin/comptables' || pathname ==='/admin/users/add'
                    || pathname ==='/admin/users/edit/'+id.userId
                        ? 'nav-link ' : 'nav-link collapsed'}
                       data-bs-target="#icons-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-person"></i><span>Utilisateurs</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="icons-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/managers">
                                <i className="bi bi-person"></i><span>Gestionnare bus</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin/dispatchers">
                                <i className="bi bi-person"></i><span>Dispatchers</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin/maintainers">
                                <i className="bi bi-person"></i><span>Maintainers</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/vendeurs">
                                <i className="bi bi-person"></i><span>Vendeurs</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/fretOperateurs">
                                <i className="bi bi-person"></i><span>Agents Frets</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/manifests">
                                <i className="bi bi-person"></i><span>Agents Manifestes</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/drivers">
                                <i className="bi bi-person"></i><span>Chauffeurs</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/bus_managers">
                                <i className="bi bi-person"></i><span>Escorteurs</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/admins">
                                <i className="bi bi-person"></i><span>Admins</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/passengers">
                                <i className="bi bi-person"></i><span>Les clients</span>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="nav-heading">LES BUS</li>

                <li className="nav-item">
                    <a className={pathname === '/admin/buses' ? 'nav-link ' : 'nav-link collapsed'}
                       data-bs-target="#bus-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-bus-front"></i><span>LES BUS</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="bus-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li className="nav-item">
                            <Link className={pathname === '/admin/brands' || pathname ==='/admin/brands/add'
                            || pathname ==='/admin/brands/edit/'+id.brandId
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/brands">
                                <i className="bi bi-bus-front"></i>
                                <span>Les marques de bus</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className={pathname === '/admin/buses' || pathname ==='/admin/buses/add'
                            || pathname ==='/admin/buses/edit/'+id.busId
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/buses">
                                <i className="bi bi-bus-front"></i>
                                <span>Les bus</span>
                            </Link>
                        </li>

                    </ul>
                </li>

                <li className="nav-heading">Transport</li>

                <li className="nav-item">
                    <a className={pathname === '/admin/buses' ? 'nav-link ' : 'nav-link collapsed'}
                       data-bs-target="#transport-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-map"></i><span>TRANSPORT</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="transport-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li className="nav-item">
                            <Link className={pathname === '/admin/trajets' || pathname ==='/admin/trajets/add'
                            || pathname ==='/admin/trajets/edit/'+id.trajetId
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/trajets">
                                <i className="bi bi-diagram-3"></i>
                                <span>Les trajets</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className={pathname === '/admin/agencies' || pathname ==='/admin/agencies/add'
                            || pathname ==='/admin/agencies/edit/'+id.pvId
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/agencies">
                                <i className="bi bi-shop-window"></i>
                                <span>Les Agences</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className={pathname === '/admin/pvs' || pathname ==='/admin/pvs/add'
                            || pathname ==='/admin/pvs/edit/'+id.pvId
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/pvs">
                                <i className="bi bi-shop"></i>
                                <span>Points de vente</span>
                            </Link>
                        </li>

                    </ul>
                </li>

                <li className="nav-heading">Rapports</li>

                <li className="nav-item">
                    <a className="nav-link collapsed"
                       data-bs-target="#rap-billet-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-repeat"></i><span>Billeterie</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="rap-billet-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li className="nav-item">
                            <Link className={pathname === '/admin/rapports/dailybilletterie'
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/dailybilletterie">
                                <i className="bi bi-clipboard"></i>
                                <span>Rapport du jour</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className={pathname === '/admin/rapports/dailySumary'
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/dailySumary">
                                <i className="bi bi-clipboard-check-fill"></i>
                                <span>BUS 7 DERNIERS JOURS </span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className={pathname === '/admin/rapports/billetterie'
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/billetterie">
                                <i className="bi bi-clipboard-fill"></i>
                                <span>RAPPORT GENERAL</span>
                            </Link>
                        </li>

                    </ul>


                    <a className="nav-link collapsed"
                       data-bs-target="#rap-fret-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-columns-gap"></i><span>Frets</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="rap-fret-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li className="nav-item">
                            <Link className={pathname === '/admin/rapports/dailyfrets'
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/dailyfrets">
                                <i className="bi bi-inboxes"></i>
                                <span>Rapport fret du jour</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className={pathname === '/admin/rapports/frets'
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/weeklyFrets">
                                <i className="bi bi-inboxes-fill"></i>
                                <span>Frets 7 DERNIERS JOURS</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className={pathname === '/admin/rapports/frets'
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/frets">
                                <i className="bi bi-boxes"></i>
                                <span>Tous les Frets</span>
                            </Link>
                        </li>

                    </ul>


                    <a className="nav-link collapsed"
                       data-bs-target="#rap-spent-bus-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-columns-gap"></i><span>depnses de bus</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="rap-spent-bus-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li className="nav-item">
                            <Link className={pathname === '/admin/rapports/dailyBusSpents'
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/dailyBusSpents">
                                <i className="bi bi-play-fill"></i>
                                <span>Aujourd'hui</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className={pathname === '/admin/rapports/allBusSpents'
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/weeklyBusSpents">
                                <i className="bi bi-play-fill"></i>
                                <span>Depenses 7 DERNIERS JOURS</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className={pathname === '/admin/rapports/allBusSpents'
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/allBusSpents">
                                <i className="bi bi-play-fill"></i>
                                <span>Toutes les depenses de bus</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className={pathname === '/admin/rapports/allSpents'
                                ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/allSpents">
                                <i className="bi bi-play-fill"></i>
                                <span>Autres depenses</span>
                            </Link>
                        </li>

                    </ul>
                </li>



                <li className="nav-heading">GESTION STOCK</li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/units' || pathname ==='/admin/units/add'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/units">
                        <i className="bi bi-gear"></i>
                        <span>Unités de mésure</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/stores' || pathname ==='/admin/stores/add'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/stores">
                        <i className="bi bi-boxes"></i>
                        <span>Stock</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/stores/movements' || pathname ==='/admin/stores/movements/add'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/stores/movements">
                        <i className="bi bi-stack"></i>
                        <span>Entrée stock</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/stores/movements/out' || pathname ==='/admin/stores/movements/add'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/stores/movements/out">
                        <i className="bi bi-outlet"></i>
                        <span>Sortie stock</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/requests'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/requests">
                        <i className="bi bi-boxes"></i>
                        <span>
                            Demande de piece
                            {(undelivered + partialDelivered) > 0 &&
                                <span className="badge rounded-pill bg-success"> {undelivered + partialDelivered}</span>
                            }
                        </span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/adminMechanicTasks/allBus'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/adminMechanicTasks/allBus">
                        <i className="bi bi-wrench-adjustable"></i>
                        <span>Taches de mecaniciens</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/adminMaintenances/allBus'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/adminMaintenances/allBus">
                        <i className="bi bi-house-gear"></i>
                        <span>Rapport de maintenance</span>
                    </Link>
                </li>

                <li className="nav-heading">Documents</li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/documents' || pathname ==='/admin/documents/add'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/documents">
                        <i className="bi bi-file-check"></i>
                        <span>Document</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/documents/bus_documents' || pathname ==='/admin/documents/bus_documents/add'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/documents/bus_documents">
                        <i className="bi bi-file-check"></i>
                        <span>
                            Document de bord
                            {busDocumentsStats(documents) >= 1 ? <><img width={35} src={logo} alt="logo" /></> : <></>}

                        </span>
                    </Link>
                </li>

                <li className="nav-heading">Paramettres</li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/settings' || pathname ==='/admin/settings/add'
                    || pathname ==='/admin/settings/edit/'+id.settingId
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/settings">
                        <i className="bi bi-gear"></i>
                        <span>Paramettres</span>
                    </Link>
                </li>

            </ul>

            <div className="fixed-bottom mx-4 my-3">
                <button className="btn btn-danger" onClick={handleLogout}>
                    <i className="bi bi-box-arrow-left"></i>
                    <span> Se deconnecter</span>
                </button>
            </div>



        </aside>
    );
};

export default AdminSidebar;
