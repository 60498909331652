import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Moment from "moment";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import {toast} from "react-toastify";
import DispatcherAPI from "../../apis/dispatcherApi/dispatcherAPI";
import Pagination from "../assets/Pagination";
import TableLoader from "../loaders/TableLoader";

const ManagerBus = () => {

    const [buses, setBuses] = useState([]);
    const [dailyavailablities, setdailyavailablities] = useState([]);
    const [sheduledAvailablities, setSheduledAvailablities] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    let counter = 1;
    let countir = 1;

    //Permet d'aller recuperer les invoices
    const fetchBuses = async () => {
        try{
            const data =  await DispatcherAPI.findAll();
            setBuses(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des buses !");
        }
    };

    const fetchBusesAvailabilities = async () => {
        try{
            const data =  await DispatcherAPI.dailyAvailabilities();
            setdailyavailablities(data.availabilities);
            setSheduledAvailablities(data.sheduleds);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des disponibilités des bus !");
        }
    };


    //Au chargement du composant on va chercher les buses
    useEffect(() => {
        fetchBuses();
        fetchBusesAvailabilities();
    }, []);


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 50;

    //Filtrage des buses en fonction de la recherche

    const filteredBuses = buses.filter(
        b =>
            b.bus_code.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_seat_number.toString().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedBuses = Pagination.getData(
        filteredBuses,
        currentPage,
        itemsPerPage
    );

    const tooltip = (data) => (
        <Tooltip className="in" id="tooltip">
            <p>{data}</p>
        </Tooltip>
    );

    return (
        <>
            <div className="pagetitle">
                <h1>Les buses</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dispatcher">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les bus KKS</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-3">
                                        <h5 className="card-title">Total {buses ? buses.length : 0}</h5>
                                    </div>
                                    {/*<div className="col-4">*/}
                                    {/*    <Link className="btn btn-sm btn-primary mt-3 d-block"*/}
                                    {/*          to="/dispatcher/buses/add_availablity">*/}
                                    {/*         Ajouter une disponibilité*/}
                                    {/*    </Link>*/}
                                    {/*</div>*/}
                                    <div className="col-4">
                                        <Link className="btn btn-sm btn-danger mt-3 d-block"
                                              to="/dispatcher/buses/add-Sheduled-availablity">
                                            Programmer une disponibilité
                                        </Link>
                                    </div>
                                </div>
                                <br/>
                                <div className="form-group">
                                    <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                </div>
                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Numéro</th>
                                        <th scope="col">Plaque</th>
                                        <th scope="col">Sièges</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedBuses.map((bus) => (
                                            <tr key={bus.id}>
                                                <th scope="row">{counter++}</th>
                                                <td>{bus.bus_code}</td>
                                                <td>{bus.bus_numberplate}</td>
                                                <td>{bus.bus_seat_number}</td>
                                            </tr>
                                        ))
                                    }

                                    </tbody>}
                                </table>

                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredBuses.length &&
                                    <Pagination
                                        currentPage={currentPage}
                                        itemsPerPage={itemsPerPage}
                                        length={filteredBuses.length}
                                        onPageChange={handleChangeCurrentPage}
                                    />
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title">
                                    Aujourd'hui :
                                    <b className="text-primary">
                                        {dailyavailablities ? dailyavailablities.length : 0}
                                    </b>
                                </h2>
                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Bus</th>
                                        <th scope="col">Plaque</th>
                                        <th scope="col">Heure</th>
                                        <th scope="col">Ville</th>
                                        <th scope="col">Status</th>
                                        <th scope="col"><i className="bi bi-gear-fill"></i></th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {dailyavailablities && dailyavailablities.map((dispo) => (
                                            <tr key={dispo.id}>
                                                <th scope="row">{countir++}</th>
                                                <td>{dispo.bus_code}</td>
                                                <td><b>{dispo.bus_numberplate}</b></td>
                                                <td>{Moment(dispo.created_at).format("HH:mm")}</td>
                                                <td>{dispo.city}</td>
                                                <td>
                                                    {dispo.available === 1  ?(<span className="text-success"><i className="bi bi-check-all"></i></span>)
                                                    :(<ButtonToolbar>
                                                            <OverlayTrigger placement="top" overlay={tooltip(dispo.pattern)}>
                                                                <Button className="badge btn btn-danger">
                                                                    Indispo <i className="bi bi-info-circle"></i>
                                                                </Button>
                                                            </OverlayTrigger>
                                                        </ButtonToolbar>)
                                                    }
                                                </td>
                                                <td>
                                                    <div className="btn-group">
                                                        <Link className="btn btn-sm btn-outline-success mx-1" to={`/manager/buses/availablity/${dispo.id}/edit`}>
                                                            <i className="bi bi-pen-fill"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }

                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title">
                                    Programmés :
                                    <b className="text-primary">
                                        {sheduledAvailablities ? sheduledAvailablities.length : 0}
                                    </b>
                                </h2>
                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Bus</th>
                                        <th scope="col">Plaque</th>
                                        <th scope="col">Jour</th>
                                        <th scope="col">Ville</th>
                                        <th scope="col">Status</th>
                                        <th scope="col"><i className="bi bi-gear-fill"></i></th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {sheduledAvailablities && sheduledAvailablities.map((dispo) => (
                                        <tr key={dispo.id}>
                                            <th scope="row">{countir++}</th>
                                            <td>{dispo.bus_code}</td>
                                            <td><b>{dispo.bus_numberplate}</b></td>
                                            <td>{Moment(dispo.created_at).format("DD-MM-YYYY")}</td>
                                            <td>{dispo.city}</td>
                                            <td>
                                                {dispo.available === 1  ?(<span className="text-success"><i className="bi bi-check-all"></i></span>)
                                                    :(<ButtonToolbar>
                                                        <OverlayTrigger placement="top" overlay={tooltip(dispo.pattern)}>
                                                            <Button className="badge btn btn-danger">
                                                                Indispo <i className="bi bi-info-circle"></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </ButtonToolbar>)
                                                }
                                            </td>
                                            <td>
                                                <div className="btn-group">
                                                    <Link className="btn btn-sm btn-outline-success mx-1" to={`/manager/buses/availablity/${dispo.id}/edit`}>
                                                        <i className="bi bi-pen-fill"></i>
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    }

                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ManagerBus;
