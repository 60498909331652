import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import BusesAPI from "../../apis/managerApi/busAPI";
import FormContentLoader from "../loaders/FormContentLoader";
import Select from "../forms/Select";
import MyButton from "../assets/MyButton";
import Field from "../forms/Field";

const EditAssignment = () => {

    let {assiId} = useParams();
    const navigate = useNavigate();
    const { formState } = useForm();
    const { isSubmitting } = formState;
    const [vendeurs, setVendeurs] = useState([]);
    const [loading, setLoading] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        bus_hour_id: "", p_id : "", vendor_id: "", bus_hour: "",
    });

    const [assignment, setAssignment] = useState({
        bus_hour_id: "", p_id : "", vendor_id: "", bus_hour: "", id : assiId
    });

    const fetchAssignment = async id => {
        try{
            const {bus_hour_id, bus_hour, p_id, vendor_id} = await BusesAPI.findOneAssignment(id);
            setAssignment({bus_hour_id, bus_hour, p_id, vendor_id});
            //setTrajet({traject});
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger l'affectation démandée !");
            navigate("/manager/new_assignment");
        }
    };


    const fetchVendeurs = async () => {
        try{
            const data =  await BusesAPI.findVendors();
            setVendeurs(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des vendeurs !");
        }
    };



    useEffect(() => {
        fetchAssignment(assiId);
    },[assiId]);

    useEffect(() => {
        fetchVendeurs();
    },[]);


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setAssignment({...assignment, [name] : value});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try{
            setShowLoader(true);
            const response = await BusesAPI.updateAssignment(assiId, assignment);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success(response.data.message);
            navigate("/manager/new_assignment");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification !");
            }else{
                toast.error(response.data.message);
            }
        }
    };


    return (
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            {loading && <FormContentLoader />}
                            {!loading && <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <h2 className="card-title">Modification d'une affectation</h2>
                                    <div className="row">
                                        <div className="col-md-3">

                                            <Field
                                                name="bus_hour"
                                                error={errors.bus_hour}
                                                value={assignment.bus_hour}
                                                onChange={handleChange}
                                                label="Heure de bus"
                                                placeholder="Ex: 06h30"
                                            />



                                        </div>
                                        <div className="col-md-3">

                                            <Select name="vendor_id" label="Vendeur" value={assignment.vendor_id}
                                                    error={errors.vendor_id} onChange={handleChange}>
                                                {vendeurs.map(vendeur =>
                                                    <option key={vendeur.id} value={vendeur.id}>{vendeur.name} | {vendeur.pos_name}</option>)
                                                }
                                            </Select>
                                        </div>
                                    </div>

                                    <MyButton
                                        text="Modifier"
                                        onSubmit={handleSubmit}
                                        loading={showLoader}
                                        disabled={showLoader}
                                    />
                                    <Link to="/manager/new_assignment" className="btn btn-dark mx-2">Annuler</Link>
                                </div>
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAssignment;
