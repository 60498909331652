import React,{useState} from 'react';
import AuthService from "../../../Services/auth/authAPI";
import {Link, useNavigate, useParams} from "react-router-dom";

const ManifestSidebar = ({pathname}) => {

    const id = useParams();

    const navigate = useNavigate();
    const [me, setMe] = useState(AuthService.getAuthenticatedUser());

    const handleLogout = () => {
        AuthService.logout();
        navigate("/login")
    };

    return (
        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <Link className={pathname === '/manifest/dashboard' ? 'nav-link ' : 'nav-link collapsed'} to="/manifest/dashboard">
                        <i className="bi bi-grid"></i>
                        <span>Tableau de bord</span>
                    </Link>
                </li>

                <li className="nav-heading">EMBARQUEMENT</li>

                <li className="nav-item">
                    <Link className={pathname === '/manifest/embarquerFret' ? 'nav-link ' : 'nav-link collapsed'} to="/manifest/embarquerFret">
                        <i className="bi bi-boxes"></i>
                        <span>Embarquement</span>
                    </Link>
                </li>



                <li className="nav-item">
                    <Link className={pathname === '/manifest/fretByBus' ? 'nav-link ' : 'nav-link collapsed'} to="/manifest/fretByBus">
                        <i className="bi bi-book-half"></i>
                        <span>Manifest Bus du jour</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/manifest/allWeeklyFretByBus' ? 'nav-link ' : 'nav-link collapsed'} to="/manifest/allWeeklyFretByBus">
                        <i className="bi bi-book-fill"></i>
                        <span>Manifests 7 derniers jours</span>
                    </Link>
                </li>


                <li className="nav-item">
                    <Link className={pathname === '/manifest/allFretByBus' ? 'nav-link ' : 'nav-link collapsed'} to="/manifest/allFretByBus">
                        <i className="bi bi-book-fill"></i>
                        <span>Tous les Manifests Bus</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/manifest/sentFrets' ? 'nav-link ' : 'nav-link collapsed'} to="/manifest/sentFrets">
                        <i className="bi bi-file-earmark-arrow-up-fill"></i>
                        <span>Colis envoyés</span>
                    </Link>
                </li>

                <li className="nav-heading">Livraison</li>

                <li className="nav-item">
                    <Link className={pathname === '/manifest/receivedFret' ? 'nav-link ' : 'nav-link collapsed'} to="/manifest/receivedFret">
                        <i className="bi bi-file-earmark-arrow-down-fill"></i>
                        <span>Colis Reçus</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/manifest/fretReceivedByBus' ? 'nav-link ' : 'nav-link collapsed'} to="/manifest/fretReceivedByBus">
                        <i className="bi bi-bus-front"></i>
                        <span>Manifest Bus Reçu</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/manifest/fretSDelivereds' ? 'nav-link ' : 'nav-link collapsed'} to="/manifest/fretSDelivereds">
                        <i className="bi bi-file-earmark-check"></i>
                        <span>Colis Livrés</span>
                    </Link>
                </li>

            </ul>

            <div className="fixed-bottom mx-4 my-3">
                <button className="btn btn-danger" onClick={handleLogout}>
                    <i className="bi bi-box-arrow-left"></i>
                    <span> Se deconnecter</span>
                </button>
            </div>

        </aside>
    );
};

export default ManifestSidebar;