import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import StoreAPI from "../../../apis/adminApi/StoreAPI";
import SettingApi from "../../../apis/adminApi/settingAPI";
import Pagination from "../../assets/Pagination";
import TableLoader from "../../loaders/TableLoader";
import {Tooltip} from "react-bootstrap";
import Moment from "moment/moment";
import PrintComponent from "../../maintainer/requestModel/printer";
import MaintainerAPI from "../../../apis/maintainerApi/MaintainerAPI";

const AdminRequestIndex = () => {

    const [requestModels, setRequests] = useState([]);
    const [bus, setBus] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [setting, setSetting] = useState([]);

    //Permet d'aller recuperer les invoices
    const fetchRequests = async () => {
        try{
            const data =  await StoreAPI.findRequests();
            setRequests(data);
            setBus([]);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des demandes !");
        }
    };

    const fetchSetting = async () => {
        try{
            const data =  await SettingApi.findSetting();
            setSetting(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };



    //Au chargement du composant on va chercher les requestModels
    useEffect(() => {
        fetchSetting();
        fetchRequests();
    }, []);


    const tooltip = (data) => (
        <Tooltip className="in" id="tooltip">
            <p>{data}</p>
        </Tooltip>
    );

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    const filteredRequests = requestModels.filter(
        b =>
            b.applicant_city.toLowerCase().includes(search.toLowerCase()) ||
            b.driver_name.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_brand.toLowerCase().includes(search.toLowerCase()) ||
            b.observation.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedRequests = Pagination.getData(
        filteredRequests,
        currentPage,
        itemsPerPage
    );

    let counter = 1;

    const handleChangeStatus = async id => {

        try{
            await StoreAPI.changeRequestStatus(id);
            toast.success("Le status a bien été changé!");
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors du changement de status !");
        }
    };


    return (
        <>
            <div className="pagetitle">
                <h1>Les demandes de piece</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/maintainer">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Demandes de piece</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {requestModels ? requestModels.length : 0}</h5>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mt-3">
                                            <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                        </div>
                                    </div>
                                </div>

                                <table className="table table-hover table-striped table-responsive">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Demandeur</th>
                                        <th scope="col">Bus</th>
                                        <th scope="col">Genre Bus</th>
                                        <th scope="col">Chauffeur</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">PIECE DE RECHANGE</th>
                                        <th scope="col">Montant</th>
                                        <th scope="col">OBSERVATION</th>
                                        <th scope="col">Livré au garage</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedRequests.map((requestModel) => (
                                        <tr key={requestModel.id}>
                                            <th scope="row">{counter++}</th>
                                            <td>{requestModel.applicant}</td>
                                            <td>{requestModel.bus_numberplate}</td>
                                            <td>{requestModel.bus_brand}</td>
                                            <td>{requestModel.driver_name}</td>
                                            <td>{Moment(requestModel.created_at).format("DD/MM/YYYY")}</td>
                                            <td>
                                                <ul>
                                                    {requestModel.request_items.map((item) =>
                                                        <li><b>{item.requested_quantity} </b> {item.product_name}</li>
                                                    )}
                                                </ul>
                                            </td>
                                            <td>{requestModel.total_amount} CDF</td>
                                            <td>{requestModel.observation}</td>
                                            <td>{requestModel.delivered === 2
                                                ?
                                                <span className="badge badge-sm bg-success">Oui</span>
                                                :
                                                <span className="badge badge-sm bg-danger">Non</span>
                                            }</td>
                                            <td>
                                                <div className="btn-group">
                                                    {requestModel.delivered !== 2 &&
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                    handleChangeStatus(requestModel.id)
                                                                }
                                                            }}
                                                            className="btn btn-sm btn-danger">
                                                            Envoyer au garage
                                                        </button>
                                                    }
                                                    <button className="btn btn-primary">
                                                        <PrintComponent bus={bus} setting={setting} requestModels={requestModels} />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredRequests.length &&
                                    <Pagination
                                        currentPage={currentPage}
                                        itemsPerPage={itemsPerPage}
                                        length={filteredRequests.length}
                                        onPageChange={handleChangeCurrentPage}
                                    />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AdminRequestIndex;
