import React, { useEffect, useState, useRef } from 'react';
import './printer.css';
import logo from './../logo.png';
import Moment from "moment";

class ComponentToPrint extends React.Component {

    render() {
        const param = this.props.param;
        const maintenances = this.props.maintenances;
        const bus = this.props.bus;
        let counter = 1;

        return (
            <div className="ms-2">
                {maintenances && <div className="cssTicket">
                        <center><img src={logo} alt="logo" /></center>
                    <p className="centered"><b>{param && param.company_address}</b></p>
                    <hr/>
                    <table className="ms-3">
                        <thead>
                        <tr>
                            <th style={{fontSize:"19px"}}>Fiche de maintenance de bus</th>
                        </tr>
                        {bus.id && <tr>
                            <th style={{fontSize:"19px"}}>Bus : {bus.bus_numberplate}</th>
                        </tr>}

                        </thead>
                    </table>
                    <hr/>
                        <table className="ms-3 table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Chauffeur</th>
                                    <th scope="col">Bus</th>
                                    <th scope="col">Date</th>
                                    <th scope="col" className="text-center">KM/ANCIEN</th>
                                    <th scope="col" className="text-center">KM/ACTUEL</th>
                                    <th scope="col" className="text-center">KM/PROCHAIN</th>
                                    <th scope="col">PIECE DE RECHANGE</th>
                                    <th scope="col">MECANICIEN</th>
                                    <th scope="col">OBSERVATION</th>
                                </tr>
                            </thead>
                            <tbody>

                            {maintenances.map((item) =>
                                <tr key={item.id}>
                                    <th scope="row">{counter++}</th>
                                    <td>{item.driver_name}</td>
                                    <td>{item.bus_numberplate}</td>
                                    <td>{Moment(item.input_date).format("DD/MM/YYYY")}</td>
                                    <td className="text-center">{item.old_mileage}</td>
                                    <td className="text-center">{item.actual_mileage}</td>
                                    <td className="text-center">{item.next_mileage}</td>
                                    <td>
                                        <ul>
                                            {item.products_in_tasks.map((item) =>
                                                <li>{item.product_name} : {item.task_id} {item.task_name}</li>
                                            )}
                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            {item.mechanic_in_tasks.map((item) =>
                                                <li>{item.mechanic_name}</li>
                                            )}
                                        </ul>
                                    </td>
                                    <td>{item.receiver_name}</td>
                                </tr>

                            )}
                            </tbody>
                        </table>
                   
                        <p className="centered">{param && param.company_idnat}<br />
                        {param && param.company_rccm}<br />
                            {param && param.company_impot} </p>

                    </div>}
            </div>
        );
    }
};
export default ComponentToPrint;
