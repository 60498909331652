import axios from "axios";
import {BUSESHOURS_API, MANAGER_BUSES_API} from "../../config";
import Cache from "../cache";

async function findAll() {
    const cachedBuses = await Cache.get("ManagerBuses");
    //if(cachedBuses) return cachedBuses;
    return axios
        .get(MANAGER_BUSES_API)
        .then(response => {
            const buses = response.data.buses;
            Cache.set("ManagerBuses", buses);
            return buses;
        });
}

async function findAvailableDailyBuses() {
    const cachedManagerAvailablesBuses = await Cache.get("ManagerAvailablesBuses");
    //if(cachedManagerAvailablesBuses) return cachedManagerAvailablesBuses;
    return axios
        .get(MANAGER_BUSES_API)
        .then(response => {
            const buses = response.data.buses;
            Cache.set("ManagerAvailablesBuses", buses);
            return buses;
        });
}

async function findAssignments() {
    const cachedManagerAssignments = await Cache.get("ManagerAssignments");
    //if(cachedManagerAssignments) return cachedManagerAssignments;
    return axios
        .get(MANAGER_BUSES_API + "/buses/assignments")
        .then(response => {
            const assignments = response.data.assignments;
            Cache.set("ManagerAssignments", assignments);
            return assignments;
        });
}

async function findDailyTransitAssignments() {
    const cachedManagerAssignments = await Cache.get("ManagerAssignments");
    //if(cachedManagerAssignments) return cachedManagerAssignments;
    return axios
        .get(MANAGER_BUSES_API + "/buses/dailyTransiteAllAssignments")
        .then(response => {
            const assignments = response.data.assignments;
            Cache.set("ManagerAssignments", assignments);
            return assignments;
        });
}

async function getPlacesOfDirectAssignementByTransiteCities() {
    return axios
        .get(MANAGER_BUSES_API + "/buses/getPlacesOfDirectAssignementByTransiteCities")
        .then(response => {
            return response.data.assignments;
        });
}

async function findOneAssignment(id){
    return axios.get(MANAGER_BUSES_API + "/buses/assignments/" + id)
        .then(response => {
            const affectation = response.data.assig;
            const traject = response.data.traj;
            return affectation;
        });
}

async function findDrivers() {
    const cachedManagerDrivers = await Cache.get("ManagerDrivers");
    //if(cachedManagerDrivers) return cachedManagerDrivers;
    return axios
        .get(MANAGER_BUSES_API + "/users/drivers")
        .then(response => {
            const drivers = response.data.drivers;
            Cache.set("ManagerDrivers", drivers);
            return drivers;
        });
}

async function findBusExcortors() {
    const cachedManagerExcortors = await Cache.get("ManagerExcortors");
    //if(cachedManagerExcortors) return cachedManagerExcortors;
    return axios
        .get(MANAGER_BUSES_API + "/users/bus_managers")
        .then(response => {
            const bus_managers = response.data.bus_managers;
            Cache.set("ManagerExcortors", bus_managers);
            return bus_managers;
        });
}

async function findVendors() {
    const cachedManagerVendeurs = await Cache.get("ManagerVendeurs");
    //if(cachedManagerVendeurs) return cachedManagerVendeurs;
    return axios
        .get(MANAGER_BUSES_API + "/users/vendors")
        .then(response => {
            const vendeurs = response.data.vendeurs;
            Cache.set("ManagerVendeurs", vendeurs);
            return vendeurs;
        });
}

async function fetchVendorsForTransite() {
    // const cachedManagerVendeurs = await Cache.get("ManagerVendeurs");
    // if(cachedManagerVendeurs) return cachedManagerVendeurs;
    return axios
        .get(MANAGER_BUSES_API + "/users/managerVendeursForTransit")
        .then(response => {
            //const vendeurs = response.data.vendeurs;
            //Cache.set("ManagerVendeurs", vendeurs);
            return response.data.vendeurs;
        });
}

async function findAllAvailabilities() {
    const cachedManagerAvas = await Cache.get("ManagerAvas");
    //if(cachedManagerAvas) return cachedManagerAvas;
    return axios
        .get(MANAGER_BUSES_API + "/availablitiesAll")
        .then(response => {
            const busesAvas = response.data.availabilities;
            Cache.set("ManagerAvas", busesAvas);
            return busesAvas;
        });
}

async function findDailyAvailabilities() {
    return axios
        .get(MANAGER_BUSES_API + "/availablitiesDaily")
        .then(response => {
            return response.data.availabilities;
        });
}

async function findDailyDispatchers() {
    return axios
        .get(MANAGER_BUSES_API + "/buses/myDailyDispatchers")
        .then(response => {
            return response.data.dispatchers;
        });
}

async function findDailyComingDispatchers(){
    return axios
        .get(MANAGER_BUSES_API + "/buses/myDailyComingDispatchers")
        .then(response => {
            return response.data.dispatchers;
        });
}

async function findSheduledAvailabilities() {
    return axios
        .get(MANAGER_BUSES_API + "/availablitiesSheduled")
        .then(response => {
            return response.data.availabilities;
        });
}


async function dailyAvailabilities() {
    return axios
        .get(MANAGER_BUSES_API + "/dailyAvailabilities")
        .then(response => {
            //console.log(response.data);
            return response.data;
        });
}

async function findOneAvailability(id){

    const cachedBuses = await Cache.get("ManagerBuses." + id);
    //if(cachedBuses) return cachedBuses;
    return axios.get(MANAGER_BUSES_API + "/availablities/" + id)
        .then(response => {
            const availability = response.data.availability;
            Cache.set("ManagerBuses." + id, availability);
            return availability;
        });
}

function updateBusAvailability (id, availability){

    return axios.post(MANAGER_BUSES_API + "/addBusAvailablity", {...availability, id: id})
        .then(async response => {
            return response;
        });
}

function create(bus){

    return axios.post(MANAGER_BUSES_API + "/addBusAvailablity", bus)
        .then(async response => {
            return response;
        });
}

async function findBusHours() {
    const cachedBusHours = await Cache.get("ManagerBusHours");
    //if(cachedBusHours) return cachedBusHours;
    return axios
        .get(MANAGER_BUSES_API + "/buses/busesHours")
        .then(response => {
            const busesHours = response.data.busesHours;
            Cache.set("ManagerBusHours", busesHours);
            return busesHours;
        });
}

async function findMyDailyDirectAssignments() {
    return axios
        .get(MANAGER_BUSES_API + "/buses/MydailyDirectAllAssignmentsForTransit")
        .then(response => {
            return response.data.assignments;
        });
}

async function findDailyDirectAssignments() {
    return axios
        .get(MANAGER_BUSES_API + "/buses/dailyDirectAllAssignmentsForTransit")
        .then(response => {
            return response.data.assignments;
        });
}

async function findSheduledDirectAssignments() {
    const cachedManagerDailyDirectAssignments = await Cache.get("ManagerDailyDirectAssignments");
    //if(cachedManagerDailyDirectAssignments) return cachedManagerDailyDirectAssignments;
    return axios
        .get(MANAGER_BUSES_API + "/sheduledDirectAllAssignments")
        .then(response => {
            const assignments = response.data.assignments;
            Cache.set("ManagerDailyDirectAssignments", assignments);
            return assignments;
        });
}


async function findTrajects() {
    const cachedManagerTrajets = await Cache.get("ManagerTrajets");
    //if(cachedManagerTrajets) return cachedManagerTrajets;
    return axios
        .get(MANAGER_BUSES_API + "/trajets")
        .then(response => {
            const trajets = response.data.trajets;
            Cache.set("ManagerTrajets", trajets);
            return trajets;
        });
}

async function findTrajectsForTransite() {
    const cachedManagerTrajets = await Cache.get("ManagerTrajets");
    //if(cachedManagerTrajets) return cachedManagerTrajets;
    return axios
        .get(MANAGER_BUSES_API + "/trajets/allForTransiteBus")
        .then(response => {
            const trajets = response.data.trajets;
            Cache.set("ManagerTrajets", trajets);
            return trajets;
        });
}

function createAssignment(assignment){

    return axios.post(MANAGER_BUSES_API + "/buses/addBusAssignment", assignment)
        .then(async response => {
            const cachedManagerDailyAssignment = await Cache.get("ManagerDailyAssignments");

            if(cachedManagerDailyAssignment){
                Cache.set("ManagerDailyAssignments", [...cachedManagerDailyAssignment, response.data.assignment]);
            }

            return response;
        });
}


function updateAssignment(id, assignment){
    return axios.post(MANAGER_BUSES_API + "/buses/editBusAssignment", {...assignment, id: id})
        .then(async response => {
            return response;
        });
}



export default {
    findAll,
    findDailyDispatchers,
    findDailyComingDispatchers,
    findDailyAvailabilities,
    findSheduledAvailabilities,
    findOneAvailability,
    create,
    findMyDailyDirectAssignments,
    updateBusAvailability,
    findAllAvailabilities,
    findAssignments,
    findDrivers,
    findBusExcortors,
    findVendors,
    findAvailableDailyBuses,
    findBusHours,
    findDailyDirectAssignments,
    findTrajects,
    createAssignment,
    updateAssignment,
    findOneAssignment,
    findDailyTransitAssignments,
    getPlacesOfDirectAssignementByTransiteCities,
    fetchVendorsForTransite,
    findTrajectsForTransite,
    dailyAvailabilities,
    findSheduledDirectAssignments
};