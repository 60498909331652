import axios from "axios";
import {BIELLETERIE_API} from "../../config";
import Cache from "../cache";


async function findAllTicketsByCity(city) {
    const cachedAdminAllTikets = await Cache.get("AdminAllTikets");
    //if(cachedAdminAllTikets) return cachedAdminAllTikets;
    return axios
        .get(BIELLETERIE_API + "/adminAllFretAmbarquedsByCity/"+city)
        .then(response => {
            const tickets = response.data.buses;
            Cache.set("AdminAllTikets", tickets);
            return tickets;
        });
}


async function findAllTickets() {
    const cachedAdminAllTikets = await Cache.get("AdminAllTikets");
    //if(cachedAdminAllTikets) return cachedAdminAllTikets;
    return axios
        .get(BIELLETERIE_API + "/adminAllFretAmbarqueds")
        .then(response => {
            const tickets = response.data.buses;
            Cache.set("AdminAllTikets", tickets);
            return tickets;
        });
}


async function findWeeklyTicketsByCity(city) {
    const cachedAdminDailyTikets = await Cache.get("AdminDailyTikets");
    //if(cachedAdminDailyTikets) return cachedAdminDailyTikets;
    return axios
        .get(BIELLETERIE_API + "/adminWeeklyBilletsByCity/"+city)
        .then(response => {
            const dailyTickets = response.data.buses;
            Cache.set("AdminDailyTikets", dailyTickets);
            return dailyTickets;
        });
}


async function findDailyTicketsByCity(city) {
    const cachedAdminDailyTikets = await Cache.get("AdminDailyTikets");
    //if(cachedAdminDailyTikets) return cachedAdminDailyTikets;
    return axios
        .get(BIELLETERIE_API + "/adminDailyBilletsByCity/"+city)
        .then(response => {
            const dailyTickets = response.data.buses;
            Cache.set("AdminDailyTikets", dailyTickets);
            return dailyTickets;
        });
}


async function findWeeklyTickets() {
    const cachedAdminDailyTikets = await Cache.get("AdminDailyTikets");
    //if(cachedAdminDailyTikets) return cachedAdminDailyTikets;
    return axios
        .get(BIELLETERIE_API + "/adminWeeklyBillets")
        .then(response => {
            const dailyTickets = response.data.buses;
            Cache.set("AdminDailyTikets", dailyTickets);
            return dailyTickets;
        });
}

async function findDailyTickets() {
    const cachedAdminDailyTikets = await Cache.get("AdminDailyTikets");
    //if(cachedAdminDailyTikets) return cachedAdminDailyTikets;
    return axios
        .get(BIELLETERIE_API + "/adminDailyBillets")
        .then(response => {
            const dailyTickets = response.data.buses;
            Cache.set("AdminDailyTikets", dailyTickets);
            return dailyTickets;
        });
}

async function findTotal() {
    const cachedAdminTotal = await Cache.get("AdminTotal");
    //if(cachedAdminTotal) return cachedAdminTotal;
    return axios
        .get(BIELLETERIE_API)
        .then(response => {
            const total = response.data.total;
            Cache.set("AdminTotal", total);
            return total;
        });
}

async function findDailyTotal() {
    const cachedDailyTotal = await Cache.get("AdminDailyTotal");
    //if(cachedDailyTotal) return cachedDailyTotal;
    return axios
        .get(BIELLETERIE_API + "/dailyBillets")
        .then(response => {
            const total = response.data.total;
            Cache.set("AdminDailyTotal", total);
            return total;
        });
}


async function find(id){

    const cachedTrajets = await Cache.get("AdminTrajets." + id);
    //if(cachedTrajets) return cachedTrajets;
    return axios.get(BIELLETERIE_API + "/" + id)
        .then(response => {
            const trajet = response.data.trajet;
            Cache.set("AdminTrajets." + id, trajet);
            return trajet;
        });
}

async function dailyBusSpentsByCity(city) {
    return axios
        .get(BIELLETERIE_API + "/dailyBusSpentsByCity/"+city)
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


async function dailyBusSpents() {
    return axios
        .get(BIELLETERIE_API + "/dailyBusSpents")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}



async function weeklySpentsByCity(city) {
    return axios
        .get(BIELLETERIE_API + "/weeklySpentsByCity/"+city)
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}

async function dailySpentsByCity(city) {
    return axios
        .get(BIELLETERIE_API + "/dailySpentsByCity/"+city)
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


async function weeklySpents() {
    return axios
        .get(BIELLETERIE_API + "/weeklySpents")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


async function dailySpents() {
    return axios
        .get(BIELLETERIE_API + "/dailySpents")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}

async function allBusSpentsByCity(city) {
    return axios
        .get(BIELLETERIE_API + "/allBusSpentsByCity/"+city)
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}

async function weeklyBusSpents() {
    return axios
        .get(BIELLETERIE_API + "/weeklyBusSpents")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


async function weeklyBusSpentsByCity(city) {
    return axios
        .get(BIELLETERIE_API + "/weeklyBusSpentsByCity/"+city)
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


async function allBusSpents() {
    return axios
        .get(BIELLETERIE_API + "/allBusSpents")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}

async function allSpents() {
    return axios
        .get(BIELLETERIE_API + "/allSpents")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}

async function allSpentsByCity(city) {
    return axios
        .get(BIELLETERIE_API + "/allSpentsByCity/"+city)
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


function deleteFret(id) {

    return axios
        .delete(BIELLETERIE_API + "/deleteFret/" + id)
        .then(async response => {
            return response;
        });
}


function reprintTicket(id){
    return axios
        .delete(BIELLETERIE_API + "/reprintTicket/" + id)
        .then(async response => {
            return response;
        });
}


function deleteInvoice(id) {
    return axios
        .delete(BIELLETERIE_API + "/" + id)
        .then(async response => {
            return response;
        });
}

function deleteSpent(id) {

    return axios
        .delete(BIELLETERIE_API + "/deleteSpent/" + id)
        .then(async response => {
            return response;
        });
}

function deleteBusSpent(id) {

    return axios
        .delete(BIELLETERIE_API + "/deleteBusSpent/" + id)
        .then(async response => {
            return response;
        });
}



// eslint-disable-next-line import/no-anonymous-default-export
export default {
    deleteInvoice,
    reprintTicket,
    deleteFret,
    deleteSpent,
    deleteBusSpent,
    findDailyTickets,
    findWeeklyTickets,
    findDailyTicketsByCity,
    findWeeklyTicketsByCity,
    findAllTickets,
    findAllTicketsByCity,
    find,
    findDailyTotal,
    findTotal,
    dailyBusSpents,
    dailyBusSpentsByCity,
    dailySpents,
    weeklySpents,
    dailySpentsByCity,
    weeklySpentsByCity,
    allSpents,
    allSpentsByCity,
    allBusSpents,
    weeklyBusSpents,
    weeklyBusSpentsByCity,
    allBusSpentsByCity,
};