import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./componentToPrint";
import VendeurAPI from "../../../apis/customerApi/VendeurAPI";
import {toast} from "react-toastify";

export default function PrintComponent({ setting, ticket, plaque, hour, pos }) {

    let componentRef = useRef(null);

    const handleAfterPrint = async (data) => {
        try{
            const response =  await VendeurAPI.printerTicket(data.id);
            toast.error(response);
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de l'impression !");
        }
    }

    function handlePrintError() {
        console.log("Error print")
    }

    return (
        <>
            <div>
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <button className="brn btn-sm btn-primary">
                        <i className="bi bi-printer"></i>
                    </button>}
                    content={() => componentRef}
                    onAfterPrint={() => handleAfterPrint(ticket)}
                    onPrintError={() => handlePrintError()}
                />

                {/* component to be printed */}
                <div style={{ display: "none" }}>
                    <ComponentToPrint param={setting} hour={hour} plaque={plaque} billet={ticket} ref={(el) => (componentRef = el)} pos={pos}/>
                </div>
            </div>

        </>
    );
}