import React, { useEffect, useState, useRef } from 'react';
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import DispatherAPI from "../../apis/dispatcherApi/dispatcherAPI";
import Moment from "moment/moment";
import TableLoader from "../loaders/TableLoader";

const AddAvailablity = () => {

    const [loading, setLoading] = useState(true);
    const [weeklyDispatcherBus, setWeeklyDispatcherBus] = useState([]);

    const fetchWeeklyDispatcherBuses = async () => {
        try{
            const data =  await DispatherAPI.findAllWeeklyDispatchers();
            setWeeklyDispatcherBus(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des affectations du jours !");
        }
    };

    useEffect(() => {
        fetchWeeklyDispatcherBuses();
    },[]);




    let count = 1;

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title">
                                Total bus programmé : <b className="text-primary">
                                {weeklyDispatcherBus ? weeklyDispatcherBus.length : 0} </b>
                            </h2>
                            <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Bus</th>
                                    <th scope="col">Priorité</th>
                                    <th scope="col">Origine</th>
                                    <th scope="col">Destination</th>
                                    <th scope="col">Driver</th>
                                    <th scope="col">Escorteur</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Affecté</th>
                                    <th scope="col"><i className="bi bi-gear-fill"></i></th>
                                </tr>
                                </thead>
                                {!loading && <tbody>
                                {weeklyDispatcherBus.map((dispatch) => (
                                    <tr key={dispatch.id}>
                                        <th scope="row">{count++}</th>
                                        <td><b>{dispatch.bus_name}</b></td>
                                        <td><b>{dispatch.priority}</b></td>
                                        <td className="text-danger">{dispatch.city_from}</td>
                                        <td className="text-success">{dispatch.city_to}</td>
                                        <td>{dispatch.driver_name}</td>
                                        <td>{dispatch.escort_name}</td>
                                        <td>{Moment(dispatch.day).format("DD-MM-YYYY")}</td>
                                        <td>{dispatch.programed ? (<span className="text-success">Oui</span>):(<span className="text-danger">Non</span>)}</td>
                                        <td>
                                            {!dispatch.programed ? <Link className="btn btn-sm btn-primary" to={`/dispatcher/dispatch/${dispatch.id}/edit`}>
                                                <i className="bi bi-pen-fill"></i>
                                                <span>Modif</span>
                                            </Link> : <></>}

                                        </td>
                                    </tr>
                                ))
                                }

                                </tbody>}
                            </table>

                            {loading && <TableLoader width={300} height={350} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAvailablity;
