import axios from "axios";
import {BIELLETERIE_API, MAINTAINER_API, SETTING_API} from "../../config";
import Cache from "../cache";

async function findBuses() {
    return axios
        .get(MAINTAINER_API + "/buses")
        .then(response => {
            return response.data.buses;
        });
}

async function findOneBus(id) {
    return axios
        .get(MAINTAINER_API + "/buses/" + id)
        .then(response => {
            return response.data.bus;
        });
}


function updateBus (id, bus){
    return axios.post(MAINTAINER_API + "/buses/updateBuses", {...bus, id: id})
        .then(async response => {
            return response;
        });
}


async function findBrands() {
    return axios
        .get(MAINTAINER_API + "/brands")
        .then(response => {
            return response.data.brands;
        });
}

async function findOneBrands(id) {
    return axios
        .get(MAINTAINER_API + "/brands/" + id)
        .then(response => {
            return response.data.brand;
        });
}

function updateBrands (id, brand){
    return axios.post(MAINTAINER_API + "/brands/storeBrands", {...brand, id: id})
        .then(async response => {
            return response;
        });
}

function createBrands(bus){
    return axios.post(MAINTAINER_API + "/brands/storeBrands", bus)
        .then(async response => {
            return response;
        });
}


async function findDrivers() {
    return axios
        .get(MAINTAINER_API + "/drivers")
        .then(response => {
            return response.data.drivers;
        });
}

async function findMechanics() {
    return axios
        .get(MAINTAINER_API + "/mechanics")
        .then(response => {
            return response.data.mechanics;
        });
}

async function findOneMechanic(id) {
    return axios
        .get(MAINTAINER_API + "/mechanics/" + id)
        .then(response => {
            return response.data.mechanic;
        });
}


function updateMechanics (id, mechanic){
    return axios.post(MAINTAINER_API + "/mechanics/storeMechanics", {...mechanic, id: id})
        .then(async response => {
            return response;
        });
}

function createMechanics(mechanic){
    return axios.post(MAINTAINER_API + "/mechanics/storeMechanics", mechanic)
        .then(async response => {
            return response;
        });
}



async function findMaintenancesByBus(budId){
    return axios
        .get(MAINTAINER_API + "/maintenances/byBus/"+ budId)
        .then(response => {
            return response.data;
        });
}

async function findOneMechanicTask(id) {
    return axios
        .get(MAINTAINER_API + "/mechanicsTasks/" + id)
        .then(response => {
            return response.data.mechanicsTask;
        });
}

function updateMechanicTasks (id, mechanicTask){
    return axios.post(MAINTAINER_API + "/mechanicsTasks/store", {...mechanicTask, id: id})
        .then(async response => {
            return response;
        });
}

function createMechanicTasks(mechanicTask){
    return axios.post(MAINTAINER_API + "/mechanicsTasks/store", mechanicTask)
        .then(async response => {
            return response;
        });
}

async function findMechanicTasks() {
    return axios
        .get(MAINTAINER_API + "/mechanicsTasks")
        .then(response => {
            return response.data.mechanicsTasks;
        });
}

async function findMechanicsTasksByBus(budId){
    return axios
        .get(MAINTAINER_API + "/mechanicsTasks/byBus/"+ budId)
        .then(response => {
            return response.data;
        });
}

async function findStocks() {
    return axios
        .get(MAINTAINER_API + "/stocks")
        .then(response => {
            return response.data.stocks;
        });
}

async function findStockMovements(){
    return axios
        .get(MAINTAINER_API + "/stocks/movements")
        .then(response => {
            return response.data;
        });
}

async function findOneStock(id) {
    return axios
        .get(MAINTAINER_API + "/stocks/" + id)
        .then(response => {
            return response.data.stock;
        });
}

function updateStock (id, stock){
    return axios.post(MAINTAINER_API + "/stocks/storeStocks", {...stock, id: id})
        .then(async response => {
            return response;
        });
}

function createStock(stock){
    return axios.post(MAINTAINER_API + "/stocks/storeStocks", stock)
        .then(async response => {
            return response;
        });
}

function receiveMovements (id){
    return axios.post(MAINTAINER_API + "/stocks/receiveMovements", {id: id})
        .then(async response => {
            return response;
        });
}

function createMaintenances (maintenance, busId){
    return axios.post(MAINTAINER_API + "/maintenances/store", {...maintenance,bus_id: busId})
        .then(async response => {
            return response;
        });
}

function updateMaintenances (id, maintenance){
    return axios.post(MAINTAINER_API + "/maintenances/store", {...maintenance,id: id})
        .then(async response => {
            return response;
        });
}

async function findMaintenances(){
    return axios
        .get(MAINTAINER_API + "/maintenances")
        .then(response => {
            return response.data.maintenances;
        });
}

async function findOneMaintenances(id){
    return axios
        .get(MAINTAINER_API + "/maintenances/"+ id)
        .then(response => {
            return response.data.maintenance;
        });
}

async function findSetting() {
    const cachedMaintainerSetting = await Cache.get("MaintainerSetting");
    if(cachedMaintainerSetting) return cachedMaintainerSetting;
    return axios
        .get(MAINTAINER_API + "/settings")
        .then(response => {
            const setting = response.data.setting;
            Cache.set("MaintainerSetting", setting);
            return setting;
        });
}



async function findOneSetting(id){

    const cachedSetting = await Cache.get("MaintainerSetting." + id);
    if(cachedSetting) return cachedSetting;
    return axios.get(MAINTAINER_API + "/settings/" + id)
        .then(response => {
            const setting = response.data.setting;
            Cache.set("MaintainerSetting." + id, setting);
            return setting;
        });
}

function addProductToMaintenance (maintenance, id){
    return axios.post(MAINTAINER_API + "/maintenances/addProductToMaintenance", {...maintenance,id:id})
        .then(async response => {
            return response;
        });
}

function addMechanicToMaintenance (maintenance, id){
    return axios.post(MAINTAINER_API + "/maintenances/addMechanicToMaintenance", {...maintenance,id:id})
        .then(async response => {
            return response;
        });
}


function addProductToMechanicTask (mechanicsTask, id){
    return axios.post(MAINTAINER_API + "/mechanicsTasks/addProductToMechanicTask", {...mechanicsTask,id:id})
        .then(async response => {
            return response;
        });
}

function addMechanicToMechanicTask (mechanicsTask, id){
    return axios.post(MAINTAINER_API + "/mechanicsTasks/addMechanicToMechanicTask", {...mechanicsTask,id:id})
        .then(async response => {
            return response;
        });
}

function addProductToRequest (requestMode, id){
    return axios.post(MAINTAINER_API + "/requests/addProductToRequest", {...requestMode,id:id})
        .then(async response => {
            return response;
        });
}



function createRequest (requestModel, busId){
    return axios.post(MAINTAINER_API + "/requests/store", {...requestModel,bus_id: busId})
        .then(async response => {
            return response;
        });
}

function updateRequest (requestModel, id){
    return axios.post(MAINTAINER_API + "/requests/store", {...requestModel,id: id})
        .then(async response => {
            return response;
        });
}

async function findRequests(){
    return axios
        .get(MAINTAINER_API + "/requests")
        .then(response => {
            return response.data.requests;
        });
}

async function findOneRequest(id){
    return axios
        .get(MAINTAINER_API + "/requests/"+ id)
        .then(response => {
            return response.data.request;
        });
}

async function findRequestsByBus(budId){
    return axios
        .get(MAINTAINER_API + "/requests/byBus/"+ budId)
        .then(response => {
            return response.data;
        });
}

function deleteRequestItem(id) {
    return axios
        .delete(MAINTAINER_API + "/requests/" + id)
        .then(async response => {
            return response;
        });
}


function deleteProductInTask(id) {
    return axios
        .delete(MAINTAINER_API + "/mechanicsTasks/deleteProduct/" + id)
        .then(async response => {
            return response;
        });
}

function deleteMechanicInTask(id) {
    return axios
        .delete(MAINTAINER_API +"/mechanicsTasks/deleteMechanic/" + id)
        .then(async response => {
            return response;
        });
}




export default {
    findDrivers,deleteRequestItem,deleteProductInTask,deleteMechanicInTask,
    findBuses, findOneBus, updateBus,
    findSetting, findOneSetting,
    findBrands, findOneBrands, createBrands, updateBrands,
    findMechanics, findOneMechanic, createMechanics, updateMechanics,
    findMechanicTasks, findOneMechanicTask, createMechanicTasks, updateMechanicTasks,findMechanicsTasksByBus,
    findMaintenances, findOneMaintenances, createMaintenances, updateMaintenances,findMaintenancesByBus,
    findStocks, findOneStock, createStock, updateStock,
    findStockMovements, receiveMovements,
    addMechanicToMaintenance, addProductToMaintenance,
    addMechanicToMechanicTask, addProductToMechanicTask,
    findRequests, findOneRequest, createRequest, updateRequest,findRequestsByBus,
    addProductToRequest,

};