import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import { toast } from "react-toastify";
import UnitAPI from "../../../apis/adminApi/UnitAPI";
import TableLoader from "../../loaders/TableLoader";
import Pagination from "../../assets/Pagination";

const AdminUnitInde = () => {

    const [units, setUnits] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    const fetchUnits = async () => {
        try{
            const data =  await UnitAPI.findAll();
            setUnits(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des unites !");
        }
    };



    //Au chargement du composant on va chercher les units
    useEffect(() => {
        fetchUnits();
    }, []);


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des units en fonction de la recherche

    const filteredUnits = units.filter(
        b =>
            b.unit_name.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedUnits = Pagination.getData(
        filteredUnits,
        currentPage,
        itemsPerPage
    );

    let numerotation = 1;


    return (
        <>
            <div className="pagetitle">
                <h1>Les unites</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les unites de mésure</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {units ? units.length : 0}</h5>
                                    </div>
                                    <div className="col-6">
                                        <Link className="btn btn-sm btn-primary mt-3 d-block" to="/admin/units/add">
                                            <i className="bi bi-plus-circle"></i> Ajouter une unité
                                        </Link>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                </div>
                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nom</th>
                                        <th scope="col">Symbole</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedUnits.map((unit) => (
                                            <tr key={unit.id}>
                                                <th scope="row">{numerotation++}</th>
                                                <td>{unit.unit_name}</td>
                                                <td>{unit.unit_sign}</td>
                                                <td>
                                                    {unit.id !== 1 ? <Link className="btn btn-sm btn-outline-primary mx-1" to={`/admin/units/edit/${unit.id}`}>
                                                        <i className="bi bi-pen-fill"></i>
                                                    </Link> : <Link className="disabled btn btn-sm btn-outline-primary mx-1" to={`/admin/units/edit/${unit.id}`}>
                                                        <i className="bi bi-pen-fill"></i>
                                                    </Link>}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredUnits.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredUnits.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
};

export default AdminUnitInde;
