import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import MechanicAPI from "../../apis/maintainerApi/MaintainerAPI";
import Field from "../forms/Field";
import MyButton from "../assets/MyButton";
import FormContentLoader from "../loaders/FormContentLoader";

const EditBrand = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {brandId} = useParams();
    const [showLoader, setShowLoader] = useState(false);

    const [brand, setBrand] = useState({
        brand_name : "", id : brandId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        brand_name : ""
    });

    const fetchBrand = async id => {
        try{
            const { brand_name} = await MechanicAPI.findOneBrands(id);
            setBrand({ brand_name });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le genre démandé !");
            navigate("/maintainer/brands");
        }
    };



    useEffect(() => {
        setEditing(true);
        fetchBrand(brandId);
    }, [brandId]);


    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setBrand({...brand, [name] : value})
    };

    let redirect = "brands";



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await MechanicAPI.updateBrands(brandId, brand);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Mecanicien modifié avec succès");
            navigate('/maintainer/brands')
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {error} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du genre !");
            }else if (error) {
                toast.error(error);
            }
        }
    };



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Modifier un genre de bus</h5>

                                <Field
                                    name="brand_name"
                                    error={errors.brand_name}
                                    value={brand.brand_name}
                                    onChange={handleChange}
                                    label="Nom Complet"
                                />


                                <MyButton
                                    text="Modifier"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to={`/maintainer/${redirect}`} className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </div>
                    </form> }
                </div>
            </div>
        </div>
    );
};

export default EditBrand;
