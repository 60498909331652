import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import MaintainerAPI from "../../apis/maintainerApi/MaintainerAPI";
import Pagination from "../assets/Pagination";
import TableLoader from "../loaders/TableLoader";

const MaintainerBuses = () => {


    const [buses, setBuses] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    const fetchBuses = async () => {
        try{
            const data =  await MaintainerAPI.findBuses();
            setBuses(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des bus !");
        }
    };

    useEffect(() => {
        fetchBuses();
    },[]);



    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des buses en fonction de la recherche

    const filteredBuses = buses.filter(
        b =>
            b.bus_code.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
            b.brand_name.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_seat_number.toString().includes(search.toLowerCase())

    );

    //Pagination des données
    const paginatedBuses = Pagination.getData(
        filteredBuses,
        currentPage,
        itemsPerPage
    );



    let count = 1;


    return (
        <>
            <div className="pagetitle">
                <h1>Les genres de bus</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/maintainer">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les genres de bus KKS</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {buses ? buses.length : 0}</h5>
                                    </div>
                                    <div className="col-6">
                                        <Link className="btn btn-sm btn-primary mt-3" to="/maintainer/buses/addBrand">
                                            <i className="bi bi-plus-circle"></i> Ajouter un genre de bus
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Numéro</th>
                                        <th scope="col">Plaque</th>
                                        <th scope="col">Nbre de siège</th>
                                        <th scope="col">Genre</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedBuses.map((bus) => (
                                        <tr key={bus.id}>
                                            <th scope="row">{count++}</th>
                                            <td>{bus.bus_code}</td>
                                            <td>{bus.bus_numberplate}</td>
                                            <td>{bus.bus_seat_number}</td>
                                            <td>{bus.brand_name}</td>
                                            <td>
                                                <div className="btn-group badge" role="group" aria-label="Basic example">
                                                    <Link className="btn btn-sm btn-success" to={`/maintainer/buses/edit/${bus.id}`}>
                                                        editer
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    }

                                    </tbody> }
                                </table>

                                {loading && <TableLoader width={500} height={550} />}

                                {itemsPerPage < filteredBuses.length &&
                                    <Pagination
                                        currentPage={currentPage}
                                        itemsPerPage={itemsPerPage}
                                        length={filteredBuses.length}
                                        onPageChange={handleChangeCurrentPage}
                                    />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MaintainerBuses;
