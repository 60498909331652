import React,{useState} from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import AuthAPI from "../../../Services/auth/authAPI";
import Maintainer_header from "../headers/Maintainer_header";
import MaintainerSideBar from "../sidebars/MaintainerSidebar";

export const ManagerLayout = ({ allowedRoles }) => {
    const location = useLocation();


    //console.log("jwt role is "+roles+" but allowed role is "+allowedRoles);
    return (AuthAPI.getUserRoles() ===
        allowedRoles[0]
    ) ? (
        <>
            <Maintainer_header />
            <MaintainerSideBar/>
            <main id="main" className="main">
                <Outlet />
            </main>

        </>
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default ManagerLayout;
