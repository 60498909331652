import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import MaintainerAPI from "../../apis/maintainerApi/MaintainerAPI";
import Pagination from "../assets/Pagination";
import TableLoader from "../loaders/TableLoader";

const StockIndex = () => {


    const [stocks, setStocks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    //Permet d'aller recuperer les invoices
    const fetchStocks = async () => {
        try{
            const data =  await MaintainerAPI.findStocks();
            setStocks(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des mecaniciens !");
        }
    };

    useEffect(() => {
        fetchStocks();
    },[]);



    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des buses en fonction de la recherche

    const filterdStocks = stocks.filter(
        v =>
            v.stock_name.toLowerCase().includes(search.toLowerCase()) ||
            v.unit_sign.toLowerCase().includes(search.toLowerCase()) ||
            v.quantity.toString().toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedStocks = Pagination.getData(
        filterdStocks,
        currentPage,
        itemsPerPage
    );



    let count = 1;


    return (
        <>
            <div className="pagetitle">
                <h1>Les pièces</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/maintainer">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les pièces garage KKS</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {stocks ? stocks.length : 0}</h5>
                                    </div>
                                    {/*<div className="col-6">*/}
                                    {/*    <Link className="btn btn-sm btn-primary mt-3" to="/maintainer/stocks/addStocks">*/}
                                    {/*        <i className="bi bi-plus-circle"></i> Ajouter une pièces*/}
                                    {/*    </Link>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nom</th>
                                        <th scope="col">Qantité</th>
                                        {/*<th scope="col">Options</th>*/}
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedStocks.map((stock) => (
                                        <tr key={stock.id}>
                                            <th scope="row">{count++}</th>
                                            <td>{stock.stock_name}</td>
                                            <td>{stock.quantity} {stock.unit_sign}</td>
                                            {/*<td>*/}
                                            {/*    <div className="btn-group badge" role="group" aria-label="Basic example">*/}
                                            {/*        <Link className="btn btn-sm btn-success" to={`/maintainer/stocks/edit/${stock.id}`}>*/}
                                            {/*            editer*/}
                                            {/*        </Link>*/}
                                            {/*    </div>*/}
                                            {/*</td>*/}
                                        </tr>
                                    ))
                                    }

                                    </tbody> }
                                </table>

                                {loading && <TableLoader width={500} height={550} />}

                                {itemsPerPage < filterdStocks.length &&
                                    <Pagination
                                        currentPage={currentPage}
                                        itemsPerPage={itemsPerPage}
                                        length={filterdStocks.length}
                                        onPageChange={handleChangeCurrentPage}
                                    />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default StockIndex;
