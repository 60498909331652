import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import DispatcherAPI from "../../apis/dispatcherApi/dispatcherAPI";
import Field from "../forms/Field";
import CheckBoxField from "../forms/CheckBoxField";
import Select from "../forms/Select";
import MyButton from "../assets/MyButton";

const AddSheduledAvailablity = () => {

    const { formState } = useForm();
    const { isSubmitting } = formState;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [showLoader, setShowLoader] = useState(false);

    const [buses, setBuses] = useState([]);

    const [checked, setChecked] = useState(false);

    const [status, setStatus] = useState({bus_id : "", pattern : "", available : 0, dateAva : ""});

    const [errors, setErrors] = useState({
        bus_id : "", pattern : "", available : "",
    });

    const handleChex = () => {
        setChecked(!checked);
        if (!checked === true) {
            setStatus({...status, available : 1});
        }else{
            setStatus({...status, available : 0});
        }
    };

    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setStatus({...status, [name] : value});
    };

    //Permet d'aller recuperer les invoices
    const fetchBuses = async () => {
        try{
            const data =  await DispatcherAPI.findAll();
            setBuses(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des buses !");
        }
    };


    useEffect(() => {
        fetchBuses();
    },[]);



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{
            setShowLoader(true);
            await DispatcherAPI.create(status);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Status ajouté avec succès");
            navigate("/dispatcher/buses");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de l'ajout du status !");
            }else{
                toast.error(response.data.message);
            }
        }
    };


    let counter = 1;


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-9">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h2 className="card-title text-danger">Programmer une status de disponibilité</h2>
                                <div className="row">
                                    <div className="col-md-6">

                                        <Select name="bus_id" label="Bus" value={status.bus_id} error={errors.bus_id} onChange={handleChange}>
                                            <option value="">Choisir un bus...</option>
                                            {buses.map(bus =>
                                                <option key={bus.id} value={bus.id}>
                                                    {bus.bus_code} - Plaque : {bus.bus_numberplate}
                                                </option>)
                                            }
                                        </Select>

                                        <CheckBoxField
                                            name="available"
                                            value={status.available}
                                            label="Disponible ?"
                                            onChange={handleChex}
                                            //checked={checked ? "checked" : ""}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            name="pattern"
                                            error={errors.pattern}
                                            value={status.pattern}
                                            onChange={handleChange}
                                            label="Motif"
                                            placeholder="Motif"
                                        />

                                        <Field
                                            name="dateAva"
                                            type="date"
                                            disabled={status.available === 0 ? "disabled" : ""}
                                            value={status.dateAva}
                                            onChange={handleChange}
                                            label="Date"
                                            placeholder="Date"
                                        />
                                    </div>
                                </div>

                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader || status.available === 0 || status.dateAva === ""} />
                                <Link to="/manager/buses" className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSheduledAvailablity;
