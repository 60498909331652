import React, { useEffect, useState } from 'react';
import PrintComponent from "./printer";
import {toast} from "react-toastify";
import FretAPI from "../../../apis/fretAgentApi/FretAPI";
import TableLoader from "../../loaders/TableLoader";
import Pagination from "../../assets/Pagination";
import Moment from "moment/moment";

const AllFrets = () => {
    const [total, setTotal] = useState("");
    const [allFrets, setAllFrets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [setting, setSetting] = useState([]);

    const fetchSetting = async () => {
        try{
            const data =  await FretAPI.findSetting();
            setSetting(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };

    const [vivo, setOutPutData] = useState([]);
    const [startAt, setStartAt] = useState("");
    const [endAt, setEndAt] = useState("");

    const getAllFrets = async () => {
        try{
            const data =  await FretAPI.allFrets();
            setAllFrets(data.frets);
            setOutPutData(data.frets);
            setTotal(data.total);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des frets !");
        }
    };

    useEffect(() => {
       getAllFrets();
        fetchSetting();
    },[]);

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 1500;

    //Filtrage des buses en fonction de la recherche

    const filteredFrets = vivo.filter(
        b =>
            b.customer.toLowerCase().includes(search.toLowerCase()) ||
            b.code.toLowerCase().includes(search.toLowerCase()) ||
            b.recipient.toLowerCase().includes(search.toLowerCase()) ||
            b.rec_phone.toLowerCase().includes(search.toLowerCase()) ||
            b.phone.toLowerCase().includes(search.toLowerCase()) ||
            b.city_to.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_plaque.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedFrets = Pagination.getData(
        filteredFrets,
        currentPage,
        itemsPerPage
    );


    let counter = 1;

    function calculTotal(data) {
        let tCDF = 0;
        data.map((toto) => toto.tickets.map((ticket) => tCDF += ticket.amount));
        return tCDF
    }

    function calculSubTotal(data) {
        let tCDF = 0;
        data.map((ticket) => tCDF += ticket.total_amount);
        return tCDF
    }

    const handleStartedDate = ({currentTarget}) => {
        setStartAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleEndedDate = ({currentTarget}) => {
        setEndAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleBtns = () => {

        if(startAt !== "" && endAt === "") {
            const filtered = allFrets.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") === Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        } else if (startAt !== "" && endAt !== "") {
            const filtered = allFrets.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD")
                    && Moment(item.created_at).format("YYYY/MM/DD") <= Moment(endAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        }else{
            const filtered = allFrets.filter(item=>
                Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered)
        }

    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title">
                                Tous mes Frets - Total : <b className="text-primary">{paginatedFrets ? paginatedFrets.length : 0} </b>
                                billets | {total ? (<><b className="text-success">{calculSubTotal(paginatedFrets)}</b> CDF</>):(<>0 CDF</>)}
                            </h2>

                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Recherche par expediteur ou destinataire
                                        </label>
                                        <input type="text"
                                               onChange={handleSearch}
                                               value={search}
                                               name="ByAll"
                                               id="ByAll"
                                               className="form-control"
                                               placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Date debut
                                        </label>
                                        <input type="date" onChange={handleStartedDate} value={startAt}
                                               name="startDate" id="startDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mt-3">
                                        <label htmlFor="endDate" className="text-dark">
                                            Date fin
                                        </label>
                                        <input type="date" onChange={handleEndedDate} value={endAt}
                                               name="endDate" id="endDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-3">
                                        <label htmlFor="filter" className="text-dark">.</label>
                                        <input type="button" onClick={handleBtns} value="Filtrer"
                                               name="filter" id="filter" className="form-control btn btn-dark" placeholder="Filtrer ..."
                                        />
                                    </div>
                                </div>
                            </div>

                            <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">N°</th>
                                    <th scope="col">Exp</th>
                                    <th scope="col">Code</th>
                                    <th scope="col">Destination</th>
                                    <th scope="col">Montant</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Dest</th>
                                    <th scope="col">Colis</th>
                                    <th scope="col">Options</th>
                                </tr>
                                </thead>
                                {!loading && <tbody>
                                {paginatedFrets.map((fret) => (
                                    <tr key={fret.id}>
                                        <th scope="row">{counter++}</th>
                                        <td>{fret.customer}, <br/>{fret.phone}</td>
                                        <td><b>{fret.code}</b></td>
                                        <td>{fret.city_to}</td>
                                        <td><b>{fret.total_amount}</b> CDF</td>
                                        <td className="text-danger">{Moment(fret.created_at).format("DD/MM/YYYY")}</td>
                                        <td>{fret.recipient}, <br/>{fret.rec_phone}</td>
                                        <td><ol style={{listStyle : "decimal"}}>
                                            {
                                                fret.fret_items.map((p) => (
                                                    <li key={p.id}>
                                                        <b>{p.reference}</b> {p.name} - ({p.weight})
                                                    </li>
                                                ))
                                            }
                                        </ol></td>
                                        <td>
                                            <div className="btn-group">
                                                <button className="btn btn-primary">
                                                    <PrintComponent setting={setting} fret={fret} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }

                                </tbody>}
                            </table>
                            {loading && <TableLoader width={300} height={350} />}

                            {itemsPerPage < filteredFrets.length &&
                            <Pagination
                                currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                length={filteredFrets.length}
                                onPageChange={handleChangeCurrentPage}
                            />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllFrets;
