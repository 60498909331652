import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import DispatcherAPI from "../../apis/dispatcherApi/dispatcherAPI";
import Select from "../forms/Select";
import CheckBoxField from "../forms/CheckBoxField";
import Field from "../forms/Field";
import FormContentLoader from "../loaders/FormContentLoader";
import MyButton from "../assets/MyButton";
import DispatherAPI from "../../apis/dispatcherApi/dispatcherAPI";

const EditAvailablity = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {dispatchId} = useParams();
    const [showLoader, setShowLoader] = useState(false);

    const [dispatch, setDispatcher] = useState({
        bus_id : "", escort_id : "", driver_id : "", destination : "", day: "", priority: "", id : dispatchId
    });

    const [editing, setEditing] = useState(false);
    const [checked, setChecked] = useState(false);
    const [buses, setBuses] = useState([]);
    const [trajets, setTrajets] = useState([]);
    const [bus_managers, setBusManagers] = useState([]);
    const [drivers, setDrivers] = useState([]);

    const [errors, setErrors] = useState({
        bus_id : "", escort_id : "", driver_id : "", destination : "", day: "", priority: "",
    });


    const fetchDispatcher = async id => {
        try{
            const { bus_id, escort_id, driver_id, destination, day, priority } = await DispatcherAPI.findOneDispatch(id);
            setDispatcher({ bus_id, escort_id, driver_id, destination, day, priority });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le status démandé !");
            navigate("/dispatcher/dailyBuses");
        }
    };

    const fetchTrajets = async () => {
        try{
            const data =  await DispatherAPI.findTrajects();
            setTrajets(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des trajets !");
        }
    };

    const fetchAvailableDailyBus = async () => {
        try{
            const data =  await DispatherAPI.findAll();
            setBuses(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des bus !");
        }
    };

    const fetchExcorteurs = async () => {
        try{
            const data =  await DispatherAPI.findBusEscortors();
            setBusManagers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des excorteurs !");
        }
    };

    const fetchDrivers = async () => {
        try{
            const data =  await DispatherAPI.findDrivers();
            setDrivers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des chauffeurs !");
        }
    };


    useEffect(() => {
        fetchTrajets();
        fetchAvailableDailyBus();
        fetchExcorteurs();
        fetchDrivers();
    },[]);

    useEffect(() => {
        setEditing(true);
        fetchDispatcher(dispatchId);
    },[dispatchId]);

    const handleChex = () => {
        setChecked(!checked);
        if (!checked === true) {
            setDispatcher({...dispatch, available : 1});
        }else{
            setDispatcher({...dispatch, available : 0});
        }

    };

    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setDispatcher({...dispatch, [name] : value});
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{
             setShowLoader(true);
             const response = await DispatcherAPI.updateDispatcher(dispatchId, dispatch);
             setTimeout(() => setShowLoader(false), 1000);
             toast.success(response.data.message);
             navigate("/dispatcher/buses/dailyBuses");
        }catch({response}){
            if (response) {
                setTimeout(() => setShowLoader(false), 1000);
                const {violations} = response.data;

                if(violations){
                    const apiErrors = {};
                    Object.keys(violations).forEach(key => {
                        apiErrors[key] = violations[key]
                    });

                    setErrors(apiErrors);
                    toast.error("Une erreur est servenue dans le formulaire de la modification du status !");
                }else{
                    toast.error(response.data.message);
                }
            }
        }
    };





    return (
        <div className="container">
            <div className="row">
                    <div className="col-md-10">
                        <div className="card">
                        {loading && <FormContentLoader />}
                        {!loading &&
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <h2 className="card-title">Ajouter ou programmer un bus</h2>
                                    <div className="row">
                                        <div className="col-md-4">

                                            <Select name="bus_id" label="Bus disponible" value={dispatch.bus_id}
                                                    error={errors.bus_id} onChange={handleChange}>
                                                <option value="">Choisir</option>
                                                {buses.map(bus =>
                                                    <option key={bus.id} value={bus.id}>
                                                        {bus.bus_numberplate} - {bus.bus_code}
                                                    </option>)
                                                }
                                            </Select>

                                            <Select name="destination" label="Destination" value={dispatch.destination}
                                                    error={errors.destination} onChange={handleChange}>
                                                <option value="">Choisir</option>
                                                {trajets.map(trajet =>
                                                    <option key={trajet.id} value={trajet.id}>{trajet.from} - {trajet.to}</option>)
                                                }
                                            </Select>
                                        </div>
                                        <div className="col-md-4">

                                            <Select name="busDriver" label="Chauffeur" value={dispatch.driver_id}
                                                    error={errors.driver_id} onChange={handleChange}>
                                                <option value="">Choisir</option>
                                                {drivers.map(driver =>
                                                    <option key={driver.id} value={driver.id}>{driver.name}</option>)
                                                }
                                            </Select>

                                            <Field
                                                name="priority"
                                                type="number"
                                                error={errors.priority}
                                                value={dispatch.priority}
                                                onChange={handleChange}
                                                label="Priorité"
                                                placeholder="Ex: 1"
                                            />


                                        </div>
                                        <div className="col-md-4">

                                            <Select name="busManager" label="Excorteur" value={dispatch.escort_id}
                                                    error={errors.escort_id} onChange={handleChange}>
                                                <option value="">Choisir</option>
                                                {bus_managers.map(gerant =>
                                                    <option key={gerant.id} value={gerant.id}>{gerant.name}</option>)
                                                }
                                            </Select>

                                            <Field
                                                name="day"
                                                type="date"
                                                error={errors.day}
                                                value={dispatch.day}
                                                onChange={handleChange}
                                                label="Date"
                                                placeholder="Date"
                                            />

                                        </div>
                                    </div>

                                    <MyButton
                                        text="Modifier"
                                        onSubmit={handleSubmit}
                                        loading={showLoader}
                                        disabled={showLoader}
                                    />
                                    <Link to="/dispatcher/buses/dailyBuses" className="btn btn-danger mx-2">Annuler</Link>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAvailablity;
