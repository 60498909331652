import axios from "axios";
import {MAINTAINER_API} from "../../config";
import Cache from "../cache";



async function findSetting() {
    const cachedMaintainerSetting = await Cache.get("MaintainerSetting");
    if(cachedMaintainerSetting) return cachedMaintainerSetting;
    return axios
        .get(MAINTAINER_API + "/settings")
        .then(response => {
            const setting = response.data.setting;
            Cache.set("MaintainerSetting", setting);
            return setting;
        });
}



async function find(id){

    const cachedSetting = await Cache.get("MaintainerSetting." + id);
    if(cachedSetting) return cachedSetting;
    return axios.get(MAINTAINER_API + "/settings" + id)
        .then(response => {
            const setting = response.data.setting;
            Cache.set("MaintainerSetting." + id, setting);
            return setting;
        });
}






export default {
    find,
    findSetting,
};