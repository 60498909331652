import axios from "axios";
import {BRAND_API} from "../../config";

async function findAll() {
    return axios
        .get(BRAND_API + "")
        .then(response => {
            return response.data.brands;
        });
}

async function find(id) {
    return axios
        .get(BRAND_API + "/" + id)
        .then(response => {
            return response.data.brand;
        });
}

function updateBrand (id, brand){
    return axios.post(BRAND_API + "/store", {...brand, id: id})
        .then(async response => {
            return response;
        });
}

function create(bus){
    return axios.post(BRAND_API + "/store", bus)
        .then(async response => {
            return response;
        });
}


export default {
    findAll,
    find,
    create,
    updateBrand,
};