import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import { toast } from "react-toastify";
import BrandAPI from "../../../apis/adminApi/BrandAPI";
import TableLoader from "../../loaders/TableLoader";
import Pagination from "../../assets/Pagination";

const AdminBrandInde = () => {

    const [brands, setBrands] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    const fetchBrands = async () => {
        try{
            const data =  await BrandAPI.findAll();
            setBrands(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des brandes !");
        }
    };



    //Au chargement du composant on va chercher les brands
    useEffect(() => {
        fetchBrands();
    }, []);


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des brands en fonction de la recherche

    const filteredBrands = brands.filter(
        b =>
            b.brand_name.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedBrands = Pagination.getData(
        filteredBrands,
        currentPage,
        itemsPerPage
    );

    let numerotation = 1;


    return (
        <>
            <div className="pagetitle">
                <h1>Les brands</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les marques de vehicule</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {brands ? brands.length : 0}</h5>
                                    </div>
                                    <div className="col-6">
                                        <Link className="btn btn-sm btn-primary mt-3 d-block" to="/admin/brands/add">
                                            <i className="bi bi-plus-circle"></i> Ajouter une marque de vehicule
                                        </Link>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                </div>
                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nom</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedBrands.map((brand) => (
                                            <tr key={brand.id}>
                                                <th scope="row">{numerotation++}</th>
                                                <td>{brand.brand_name}</td>
                                                <td>
                                                    {brand.id !== 1 ? <Link className="btn btn-sm btn-outline-primary mx-1" to={`/admin/brands/edit/${brand.id}`}>
                                                        <i className="bi bi-pen-fill"></i>
                                                    </Link> : <Link className="disabled btn btn-sm btn-outline-primary mx-1" to={`/admin/brands/edit/${brand.id}`}>
                                                    <i className="bi bi-pen-fill"></i>
                                                </Link>}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredBrands.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredBrands.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
};

export default AdminBrandInde;
