import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import { toast } from "react-toastify";
import MaintainerAPI from "../../apis/maintainerApi/MaintainerAPI";
import TableLoader from "../loaders/TableLoader";
import Pagination from "../assets/Pagination";
import Moment from "moment";
import {counter} from "@fortawesome/fontawesome-svg-core";
import PrintComponent from "./printer";
import SettingAPI from "../../apis/maintainerApi/settingAPI";

const MaintainerStoreMovementsIndex = () => {

    const [movements, setMovements] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [setting, setSetting] = useState([]);

    //Permet d'aller recuperer les invoices
    const fetchMovements = async () => {
        try{
            const data =  await MaintainerAPI.findStockMovements();
            setMovements(data.movements);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des mouvements !");
        }
    };

    const fetchSetting = async () => {
        try{
            const data =  await SettingAPI.findSetting();
            setSetting(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };



    //Au chargement du composant on va chercher les movements
    useEffect(() => {
        fetchMovements();
        fetchSetting();
    }, []);

    const tooltip = (data) => (
        <Tooltip className="in" id="tooltip">
            <p>{data}</p>
        </Tooltip>
    );

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des movements en fonction de la recherche

    const filteredMovements = movements.filter(
        b =>
            b.destination.toLowerCase().includes(search.toLowerCase()) ||
            b.number.toLowerCase().includes(search.toLowerCase()) ||
            b.receiver_name.toLowerCase().includes(search.toLowerCase()) ||
            b.user_name.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedMovements = Pagination.getData(
        filteredMovements,
        currentPage,
        itemsPerPage
    );

    let counter = 1;

    const receiveMovement = async id => {

        try{
            await MaintainerAPI.receiveMovements(id);
            toast.success("Le mouvement pris en charge");
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de la mise à jour!");
        }
    };

    return (
        <>
            <div className="pagetitle">
                <h1>Les produits</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les produits de garages</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-11">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {movements ? movements.length : 0}</h5>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-group mt-2">
                                            <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                        </div>
                                    </div>
                                </div>

                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Numero</th>
                                        {/*<th scope="col">Ville</th>*/}
                                        <th scope="col">Créateur</th>
                                        <th scope="col">Destinataire</th>
                                        <th scope="col">Produits</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedMovements.map((store) => (
                                            <tr key={store.id}>
                                                <th scope="row">{counter++}</th>
                                                <td>{store.number}</td>
                                                {/*<td>{store.destination}</td>*/}
                                                <td>{store.user_name}</td>
                                                <td>{store.receiver_name}</td>
                                                <td>Le {Moment(store.created_at).format("DD/MM/YYYY")}</td>
                                                <td>
                                                    <ul>
                                                        {store.stock_mvt_items.map((item) =>
                                                            <li>{item.store_name} - <b>{item.quantity}</b> {item.store_unit_sign}</li>
                                                        )}
                                                    </ul>
                                                </td>
                                                <td>

                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                receiveMovement(store.id)
                                                            }
                                                        }}
                                                        className={store.status === 2 ? "disabled btn btn-sm btn-primary mx-1" : "btn btn-sm btn-primary mx-1"}>
                                                        <i className="bi bi-check-circle-fill"></i> Recevoir
                                                    </button>


                                                    <div className="btn-group">
                                                        <button className="btn btn-primary">
                                                            <PrintComponent setting={setting} store={store} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredMovements.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredMovements.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MaintainerStoreMovementsIndex;
