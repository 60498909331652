import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import { toast } from "react-toastify";
import MaintainerAPI from "../../../apis/maintainerApi/MaintainerAPI";
import TableLoader from "../../loaders/TableLoader";
import Pagination from "../../assets/Pagination";
import Moment from "moment";
import PrintComponent from "./printer";

const AllBusesMaintenances = () => {

    const [maintenances, setMaintenances] = useState([]);
    const [bus, setBus] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [setting, setSetting] = useState([]);

    //Permet d'aller recuperer les invoices
    const fetchMaintenances = async () => {
        try{
            const data =  await MaintainerAPI.findMaintenances();
            setMaintenances(data);
            setBus([]);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des mouvements !");
        }
    };

    const fetchSetting = async () => {
        try{
            const data =  await MaintainerAPI.findSetting();
            setSetting(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };



    //Au chargement du composant on va chercher les maintenances
    useEffect(() => {
        fetchSetting();
        fetchMaintenances();
    }, []);


    const tooltip = (data) => (
        <Tooltip className="in" id="tooltip">
            <p>{data}</p>
        </Tooltip>
    );

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    const filteredMaintenances = maintenances.filter(
        b =>
            b.city.toLowerCase().includes(search.toLowerCase()) ||
            b.driver_name.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
            b.old_mileage.toString().toLowerCase().includes(search.toLowerCase()) ||
            b.observation.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedMaintenances = Pagination.getData(
        filteredMaintenances,
        currentPage,
        itemsPerPage
    );

    let counter = 1;


    return (
        <>
            <div className="pagetitle">
                <h1>Les entretiens</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/maintainer">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Entrerien de bus</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {maintenances ? maintenances.length : 0}</h5>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mt-3">
                                            <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                        </div>
                                    </div>
                                </div>

                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Chauffeur</th>
                                        <th scope="col">Bus</th>
                                        <th scope="col">Date</th>
                                        <th scope="col" className="text-center">KM/ANCIEN</th>
                                        <th scope="col" className="text-center">KM/ACTUEL</th>
                                        <th scope="col" className="text-center">KM/PROCHAIN</th>
                                        <th scope="col">PIECES</th>
                                        <th scope="col">MECANICIEN</th>
                                        <th scope="col">OBSERVATION</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedMaintenances.map((maintenance) => (
                                            <tr key={maintenance.id}>
                                                <th scope="row">{counter++}</th>
                                                <td>{maintenance.driver_name}</td>
                                                <td>{maintenance.bus_numberplate}</td>
                                                <td>Le {Moment(maintenance.input_date).format("DD/MM/YYYY")}</td>
                                                <td className="text-center">{maintenance.old_mileage}</td>
                                                <td className="text-center">{maintenance.actual_mileage}</td>
                                                <td className="text-center">{maintenance.next_mileage}</td>
                                                <td>
                                                    <ul>
                                                        {maintenance.products_in_tasks.map((item) =>
                                                            <li>{item.product_name} : {item.task_id} {item.task_name}</li>
                                                        )}
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        {maintenance.mechanic_in_tasks.map((item) =>
                                                            <li>{item.mechanic_name}</li>
                                                        )}
                                                    </ul>
                                                </td>
                                                <td>{maintenance.observation}</td>
                                                <td></td>
                                                <td>
                                                    <div className="btn-group">
                                                        <Link title="ajouter une piece" className="btn btn-sm btn-dark" to={`/maintainer/maintenances/${maintenance.id}/addProduct`}>
                                                            <i className="bi bi-boxes"></i> <i className="bi bi-plus-lg"></i>
                                                        </Link>
                                                        <Link title="ajouter un mecanicier" className="btn btn-sm btn-success" to={`/maintainer/maintenances/${maintenance.id}/addMechanic`}>
                                                            <i className="bi bi-person-gear"></i> <i className="bi bi-plus-lg"></i>
                                                        </Link>
                                                        <Link title="modifier" className="btn btn-sm btn-danger" to={`/maintainer/maintenances/${maintenance.id}/edit`}>
                                                            <i className="bi bi-pencil-square"></i>
                                                        </Link>
                                                        <button className="btn btn-primary">
                                                            <PrintComponent bus={bus} setting={setting} maintenances={maintenances} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredMaintenances.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredMaintenances.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AllBusesMaintenances;
