import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import MechanicAPI from "../../../apis/maintainerApi/MaintainerAPI";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";
import FormContentLoader from "../../loaders/FormContentLoader";
import MaintainerAPI from "../../../apis/maintainerApi/MaintainerAPI";
import Textarea from "../../forms/Textarea";
import BielletterieAPI from "../../../apis/adminApi/bielleterieAPI";

const EditMechanicTask = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {mechanicTasksId} = useParams();
    const [showLoader, setShowLoader] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [bus, setBus] = useState([]);

    const [mecahicTask, setMaintenance] = useState({
        mechanic_id : "", id : mechanicTasksId, observation: "", driver_id: "" ,complaint :"", bus_id : ""
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        mechanic_id : "", driver_id: "", complaint: ""
    });



    const fetchDrivers = async () => {
        try{
            const data =  await MaintainerAPI.findDrivers();
            setDrivers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des chauffeurs !");
        }
    };


    const fetchMechanicTask = async id => {
        try{
            const { products_in_tasks,mechanics_in_task,
                driver_name,driver_id, complaint, observation,
                bus_id,bus_numberplate, id} = await MechanicAPI.findOneMechanicTask(mechanicTasksId);

            setMaintenance({ products_in_tasks,mechanics_in_task,
                bus_id,complaint,driver_id, driver_name,observation,bus_numberplate, id });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le mecahicTask démandé !");
            //navigate("/maintainer/mecahicTasks");
        }
    };



    useEffect(() => {
        setEditing(true);
        fetchMechanicTask(mechanicTasksId);
    }, [mechanicTasksId]);

    useEffect(() => {
        fetchDrivers();
    },[]);


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setMaintenance({...mecahicTask, [name] : value})
    };

    let redirect = "mechanicTasks/allBus";



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await MaintainerAPI.updateMechanicTasks(mechanicTasksId, mecahicTask);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Tache modifiée avec succès");
            navigate('/maintainer/mechanicTasks/allBus');
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {error} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire!");
            }else if (error) {
                toast.error(error);
            }
        }
    };


    const handleDelete = async id => {

        try{
            await MaintainerAPI.deleteProductInTask(id);
            toast.success("La piece a bien été supprimée !");
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de la suppression du tickets !");
        }
    };

    const handleDeleteMechanic = async id => {
        try{
            await MaintainerAPI.deleteMechanicInTask(id);
            toast.success("Le mecanicien a bien été supprimée !");
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de la suppression du tickets !");
        }
    }



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Ajouter une demande d'achat</h5>
                                <div className="row">
                                    <div className="col-4">
                                        <Field
                                            name="bus_id"
                                            disabled="disabled"
                                            value={"Bus : "+mecahicTask.bus_numberplate}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <h4>Les pieces</h4>
                                        <ul>
                                            {mecahicTask.products_in_tasks && mecahicTask.products_in_tasks.map((item) =>
                                                <li>
                                                    {item.product_name} {item.task_id} {item.task_name}
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                handleDelete(item.id)
                                                            }
                                                        }}
                                                        className="btn btn-sm btn-danger">
                                                          <i className="bi bi-trash"></i>
                                                    </button>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="col-4">
                                        <h4>Les mecaniciens</h4>
                                        <ul>
                                            {mecahicTask.mechanics_in_task && mecahicTask.mechanics_in_task.map((item) =>
                                                <li>
                                                    {item.mechanic_name}
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                handleDeleteMechanic(item.id)
                                                            }
                                                        }}
                                                        className="btn btn-sm btn-danger">
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="driver_id">Chauffeur</label>
                                        <select
                                            className={"form-control " + ( errors.driver_id && "is-invalid")}
                                            //size={(buses.length)}
                                            name="driver_id"
                                            id="driver_id"
                                            value={mecahicTask.driver_id}
                                            onChange={handleChange}
                                        >

                                            <option value="" selected>Choisir...</option>
                                            {drivers && drivers.map((driver) => (
                                                <option style={{fontWeight:"bold"}} key={driver.id} value={driver.id}>
                                                    {driver.name} </option>
                                            ))
                                            }
                                        </select>
                                        {errors.driver_id && <div className="invalid-feedback mb-4">{errors.driver_id}</div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-10">
                                        <Field
                                            name="complaint"
                                            error={errors.complaint}
                                            value={mecahicTask.complaint}
                                            onChange={handleChange}
                                            label="Plainte"
                                            placeholder="plainte"
                                        />
                                    </div>

                                    <div className="col-10">
                                        <Field
                                            name="observation"
                                            error={errors.observation}
                                            value={mecahicTask.observation}
                                            onChange={handleChange}
                                            label="Observation"
                                            placeholder="Observation"
                                        />
                                    </div>

                                    <Field
                                        name="bus_id"
                                        type="hidden"
                                        value={bus.id}
                                    />
                                </div>


                                <MyButton
                                    text="Modifier"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to={`/maintainer/${redirect}`} className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </div>
                    </form> }
                </div>
            </div>
        </div>
    );
};

export default EditMechanicTask;
