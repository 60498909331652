import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import MechanicAPI from "../../../apis/maintainerApi/MaintainerAPI";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";
import FormContentLoader from "../../loaders/FormContentLoader";
import MaintainerAPI from "../../../apis/maintainerApi/MaintainerAPI";
import Textarea from "../../forms/Textarea";

const AddProductToMechanicTask = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    let {mechanicTasksId} = useParams();
    const [showLoader, setShowLoader] = useState(false);
    const [mechanics, steMechanics] = useState([]);
    const [products, setProducts] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [maint, setMain] = useState([]);

    const [maintenance, setMaintenance] = useState({
        product_id : "", id : mechanicTasksId, quantity : ""
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        product_id : "", quantity : ""
    });

    const fetchMechanics = async () => {
        try{
            const data =  await MechanicAPI.findMechanics();
            steMechanics(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des mechaniciens !");
        }
    };


    const fetchProducts = async () => {
        try{
            const data =  await MaintainerAPI.findStocks();
            setProducts(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des mecaniciens !");
        }
    };

    const fetchMaintenance = async mechanicTasksId => {
        try{
            const {bus_numberplate, id} = await MechanicAPI.findOneMechanicTask(mechanicTasksId);
            setMaintenance({ bus_numberplate,id });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le maintenance démandé !");
            //navigate("/maintainer/maintenances");
        }
    };



    useEffect(() => {
        setEditing(true);
        fetchMaintenance(mechanicTasksId);
    }, [mechanicTasksId]);

    useEffect(() => {
        fetchMechanics();
        fetchProducts();
    },[]);


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setMaintenance({...maintenance, [name] : value})
    };

    let redirect = "mechanicTasks/allBus";



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await MaintainerAPI.addProductToMechanicTask(maintenance, mechanicTasksId);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Mecanicien ajouté avec succès");
            navigate('/maintainer/mechanicTasks/allBus')
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {error} = response.data;
            const {message} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire!, Le mechanicien est obligatoire");
            }else if (error) {
                toast.error(error);
            }else if (message) {
                toast.error(message);
            }
        }
    };



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Ajouter une piece à l'entretien de bus <b>{maintenance && maintenance.bus_numberplate}</b></h5>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="mb-3">
                                            <label>Choisir une piece</label>
                                            {products && products.map(product =>
                                                <div className="form-check" key={product.id}>
                                                    <input onChange={handleChange} value={product.id}
                                                           className="form-check-input" type="radio"
                                                           name="product_id" id={product.id}/>
                                                    <label className="form-check-label" htmlFor={product.id}>
                                                        <b>{product.stock_name}</b> -> {product.quantity} {product.unit_sign}
                                                    </label>
                                                </div>
                                            )}
                                            {errors.product_id && <div className="invalid-feedback mb-4">{errors.product_id}</div>}
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <Field
                                            name="quantity"
                                            type="number"
                                            error={errors.quantity}
                                            value={maintenance.quantity}
                                            onChange={handleChange}
                                            label="Quantité"
                                        />
                                    </div>
                                </div>

                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to={`/maintainer/${redirect}`} className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </div>
                    </form> }
                </div>
            </div>
        </div>
    );
};

export default AddProductToMechanicTask;
