import axios from "axios";
import {ADMIN_API, BUS_BUS_DOC_API, BUS_DOC_API, MAINTAINER_API} from "../../config";

async function findDocuments() {
    return axios
        .get(BUS_DOC_API)
        .then(response => {
            return response.data.documents;
        });
}


async function findBusDocuments() {
    return axios
        .get(BUS_BUS_DOC_API)
        .then(response => {
            return response.data.bus_documents;
        });
}



async function findOneDocument(id){
    return axios.get(BUS_DOC_API + "/" + id)
        .then(response => {
            return response.data.document;
        });
}

async function findOneBusDocument(id){
    return axios.get(BUS_BUS_DOC_API + "/" + id)
        .then(response => {
            console.log(response.data);
            return response.data.bus_document;
        });
}


function updateDocument(id, document){

    return axios.post(BUS_DOC_API + "/storeDocument", {...document, id: id})
        .then(async response => {
            return response;
        });
}


function updateBusDocument(bus_document, id){

    return axios.post(BUS_DOC_API + "/storeBusDocument", {...bus_document, id: id})
        .then(async response => {
            return response;
        });
}

function createDocument(doc){

    return axios.post(BUS_DOC_API + "/storeDocument", doc)
        .then(async response => {
            return response;
        });
}

function createBusDocument(bus_doc){

    return axios.post(BUS_DOC_API + "/storeBusDocument", bus_doc)
        .then(async response => {
            return response;
        });
}

async function findMechanicTasks() {
    return axios
        .get(ADMIN_API + "/mechanicsTasks/allBus")
        .then(response => {
            return response.data.mechanicsTasks;
        });
}

async function findMaintenances(){
    return axios
        .get(ADMIN_API + "/maintenances/allBus")
        .then(response => {
            return response.data.maintenances;
        });
}



export default {
    findDocuments,findBusDocuments,findOneDocument,findOneBusDocument,
    createDocument, createBusDocument, updateBusDocument, updateDocument,
    findMechanicTasks,findMaintenances
};