import React,{useState} from 'react';
import AuthService from "../../../Services/auth/authAPI";
import {Link, useNavigate, useParams} from "react-router-dom";

const ManagerSideBar = ({pathname}) => {

    const id = useParams();

    const navigate = useNavigate();
    const [me, setMe] = useState(AuthService.getAuthenticatedUser());

    const handleLogout = () => {
        AuthService.logout();
        navigate("/login")
    };

    return (
        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <Link className={pathname === '/manager' ? 'nav-link ' : 'nav-link collapsed'} to="/manager">
                        <i className="bi bi-grid"></i>
                        <span>Tableau de bord</span>
                    </Link>
                </li>

                <li className="nav-heading">AFFECTATIONS DES BUS</li>

                <li className="nav-item">
                    <Link className={pathname === '/manager/new_assignment' ? 'nav-link ' : 'nav-link collapsed'} to="/manager/new_assignment">
                        <i className="bx bxs-car"></i>
                        <span>Bus direct</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/manager/new_transite_assignment' ? 'nav-link ' : 'nav-link collapsed'} to="/manager/new_transite_assignment">
                        <i className="bx bxs-car-wash"></i>
                        <span>Bus de transite</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/manager/all_assignments' ? 'nav-link ' : 'nav-link collapsed'} to="/manager/all_assignments">
                        <i className="bi bi-bus-front"></i>
                        <span>Toutes les affectations</span>
                    </Link>
                </li>


            </ul>

        </aside>
    );
};

export default ManagerSideBar;