import React, { useEffect, useState } from 'react';
import {useUser} from "../../../Services/userProvider";
import PrintComponent from "../../operateur_fret/frets/printer";
import {toast} from "react-toastify";
import ManifestAPI from "../../../apis/manifestApi/manifestAPI";
import Pagination from "../../assets/Pagination";
import TableLoader from "../../loaders/TableLoader";
import Moment from "moment/moment";
import MyButton from "../../assets/MyButton";

const EmbarquerFret = () => {
    const user = useUser();

    const [total, setTotal] = useState("");
    const [dailyFrets, setDailyFrets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [setting, setSetting] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const [vivo, setOutPutData] = useState([]);
    const [startAt, setStartAt] = useState("");
    const [endAt, setEndAt] = useState("");

    const [dailyFretsBuses, setDailyBusesForFret] = useState([]);

    const dailyBusesForFret = async () => {
        try{
            const data =  await ManifestAPI.dailyDirectSalesForEmbarquement();
            setDailyBusesForFret(data.buses);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des bus !");
        }
    };

    const fetchSetting = async () => {
        try{
            const data =  await ManifestAPI.findSetting();
            setSetting(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };

    const getDailyFrets = async () => {
        try{
            const data =  await ManifestAPI.dailyFretsForAmbarquement();
            setDailyFrets(data.frets);
            setOutPutData(data.frets);
            setTotal(data.total);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des frets du jours !");
        }
    };

    useEffect(() => {
        getDailyFrets();
        fetchSetting();
        dailyBusesForFret();
    },[]);

    const [busId, setBusId] = useState("");
    const [quantity, setQuantity] = useState("");
    const [fretItem, setfretItem] = useState({});

    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setBusId(value);
        setfretItem({...fretItem, busItem: busId});
    };

    const handleChangeNumber = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setQuantity(value);
        setfretItem({...fretItem, quantity: quantity});
    };

    const  handleAddToBus = async (itemId, leBus = busId, quant = quantity) => {

        try{

            setShowLoader(true);
            await ManifestAPI.addFretToBus(itemId, leBus, quant);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Le bus a été cloturé avec succès");
            window.location.reload();
        }catch({response}){
            //console.log(response.data.error);
            setTimeout(() => setShowLoader(false), 1000);
            const {message} = response.data;
            toast.error(response.data.error);
        }
    };


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 750;

    //Filtrage des buses en fonction de la recherche
    const filteredFrets = vivo.filter(
        b =>
            b.name.toLowerCase().includes(search.toLowerCase()) ||
            b.fret.customer.toLowerCase().includes(search.toLowerCase()) ||
            b.fret.code.toLowerCase().includes(search.toLowerCase()) ||
            b.fret.recipient.toLowerCase().includes(search.toLowerCase()) ||
            b.fret.city_to.toLowerCase().includes(search.toLowerCase()) ||
            b.fret.city_from.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedFrets = Pagination.getData(
        filteredFrets,
        currentPage,
        itemsPerPage
    );


    let counter = 1;

    function calculTotalFret(data) {
        let tCDF = 0;
        data.map((toto) => toto.fret_items.map((f) => tCDF += f.amount));
        return tCDF
    }

    function calculSubTotal(data) {
        let tCDF = 0;
        data.map((ticket) => tCDF += ticket.total_amount);
        return tCDF
    }

    const handleStartedDate = ({currentTarget}) => {
        setStartAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleEndedDate = ({currentTarget}) => {
        setEndAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleBtns = () => {

        if(startAt !== "" && endAt === "") {
            const filtered = dailyFrets.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") === Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        } else if (startAt !== "" && endAt !== "") {
            const filtered = dailyFrets.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD")
                    && Moment(item.created_at).format("YYYY/MM/DD") <= Moment(endAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        }else{
            const filtered = dailyFrets.filter(item=>
                Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered)
        }

    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title">
                                Les Frets du jour - Total : <b className="text-primary">{paginatedFrets ? paginatedFrets.length : 0} </b>
                                billets | {total ? (<><b className="text-success">{calculSubTotal(paginatedFrets)}</b> CDF</>):(<>0 CDF</>)}
                                Fret | {total ? (<><b className="text-success">{calculTotalFret(paginatedFrets)}</b> CDF</>):(<>0 CDF</>)}
                            </h2>

                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Recherche par expediteur ou destinataire
                                        </label>
                                        <input type="text"
                                               onChange={handleSearch}
                                               autoComplete="off"
                                               value={search}
                                               name="ByAll"
                                               id="ByAll"
                                               className="form-control"
                                               placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Date debut
                                        </label>
                                        <input type="date" onChange={handleStartedDate} value={startAt}
                                               name="startDate" id="startDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mt-3">
                                        <label htmlFor="endDate" className="text-dark">
                                            Date fin
                                        </label>
                                        <input type="date" onChange={handleEndedDate} value={endAt}
                                               name="endDate" id="endDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-3">
                                        <label htmlFor="filter" className="text-dark">.</label>
                                        <input type="button" onClick={handleBtns} value="Filtrer"
                                               name="filter" id="filter" className="form-control btn btn-dark" placeholder="Filtrer ..."
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">N°</th>
                                        <th scope="col">Colis</th>
                                        <th scope="col">Code</th>
                                        <th scope="col">Nbre</th>
                                        <th scope="col">Embar</th>
                                        <th scope="col">Reste</th>
                                        <th scope="col">Exp</th>
                                        <th scope="col">Vers</th>
                                        <th scope="col">Dest</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedFrets.map((item) => (
                                        <tr key={item.id}>
                                            <td>{counter++}</td>
                                            <td className="overflow-auto">{item.name}</td>
                                            <td><b>{item.fret.code}</b></td>
                                            <td className="text-center"><b className="text-primary text-center">{item.weight}</b></td>
                                            <td className="text-center"><b className="text-danger text-center">{(item.weight) - (item.code)}</b></td>
                                            <td className="text-center"><b className="text-success text-center">{item.code}</b></td>
                                            <td>{item.fret.customer}, <br/>{item.fret.phone}</td>
                                            <td>{item.fret.city_to}</td>
                                            <td>{item.fret.recipient}, <br/>{item.fret.rec_phone}</td>
                                            <td>{Moment(item.fret.created_at).format("DD/MM/YYYY")}</td>
                                            <td>
                                                <div className="input-group">
                                                    <select
                                                        //value={busId}
                                                        name="busId"
                                                        onChange={handleChange}
                                                        className="form-select" id="busId"
                                                        aria-label="Selectionner le bus">
                                                        <option value="">Choisir...</option>
                                                        {dailyFretsBuses && dailyFretsBuses.map((bus) =>
                                                            (<option value={bus.id}>
                                                                {bus.bus_numberplate} - {bus.agency_name} - {bus.bus_hour}
                                                            </option>))
                                                        }

                                                    </select>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="quantity"
                                                        id="quantity"
                                                        min="0"
                                                        placeholder="Nombre"
                                                        onChange={handleChangeNumber}
                                                    />

                                                    <button
                                                        onClick={() => {
                                                            if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                handleAddToBus(item.id)
                                                            }
                                                        }}
                                                        disabled={(quantity === "" || quantity > item.code || busId === "") ? "disabled" : ""}
                                                        className="btn btn-sm btn-success">
                                                        Ajouter
                                                    </button>

                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    }

                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredFrets.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredFrets.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmbarquerFret;
