import React, { useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import DOCAPI from "../../../apis/adminApi/DocumentAPI";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";

const AddDocument = () => {
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState(false);

    const [document, setDocument] = useState({
        doc_name : "", doc_description : ""
    });

    const [errors, setErrors] = useState({
        doc_name : "", doc_description : ""
    });

    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setDocument({...document, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await DOCAPI.createDocument(document);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Document ajouté avec succès");
            navigate("/admin/documents");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {message} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du document !");
            }else if (message){
                toast.error(message);
            }
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h5 className="card-title">Ajouter un document de bord</h5>
                                <Field
                                    name="doc_name"
                                    error={errors.doc_name}
                                    value={document.doc_name}
                                    onChange={handleChange}
                                    label="Nom du document"
                                    placeholder="Nom"
                                />

                                <Field
                                    name="doc_description"
                                    error={errors.doc_description}
                                    value={document.doc_description}
                                    onChange={handleChange}
                                    label="Description"
                                    placeholder="Description"
                                />

                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to="/admin/documents" className="btn btn-info mx-2">Revenir aux document</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddDocument;
