import React,{useState} from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import AuthAPI from "../../../Services/auth/authAPI";
import Dispatcher_header from "../headers/Dispatcher_header";
import DispatcherSideBar from "../sidebars/DispatcherSidebar";

export const DispatcherLayout = ({ allowedRoles }) => {
    const location = useLocation();


    //console.log("jwt role is "+roles+" but allowed role is "+allowedRoles);
    return (AuthAPI.getUserRoles() ===
        allowedRoles[0]
    ) ? (
        <>
            <Dispatcher_header />
            <DispatcherSideBar/>
            <main id="main" className="main">
                <Outlet />
            </main>

        </>
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default DispatcherLayout;
