import React, { useEffect, useState, useRef } from 'react';
import './printer.css';
import logo from './logo.png';
import Moment from "moment";

class ComponentToPrint extends React.Component {

    render() {

        const store = this.props.store;
        const param = this.props.param;
        let counter = 1;

        return (
            <div className="ms-2">
                {store && <div className="cssTicket">
                        <center><img src={logo} alt="logo" /></center>
                    <p className="centered"><b>{param && param.company_address}</b></p>
                    <hr/>
                    <table className="ms-3">
                        {store.type === 1 ?
                            (<thead>
                                <tr>
                                    <th style={{fontSize:"19px"}}>Bon d'entrée stock</th>
                                </tr>
                                <tr>
                                    <th style={{fontSize:"19px"}}>N° : {store.number}</th>
                                </tr>
                                <tr>
                                    <th style={{fontSize:"19px"}}>Destination : {store.destination}</th>
                                </tr>
                                <tr>
                                    <th style={{fontSize:"19px"}}>Reception : {store.user_name}</th>
                                </tr>
                                <tr>
                                    <th style={{fontSize:"19px"}}>Date : {Moment(store.created_at).format("DD/MM/YYYY")}</th>
                                </tr>

                            </thead>)

                        :

                            (<thead>
                                <tr>
                                    <th style={{fontSize:"19px"}}>Bon de sortie stock</th>
                                </tr>
                                <tr>
                                    <th style={{fontSize:"19px"}}>N° : {store.number}</th>
                                </tr>
                                <tr>
                                    <th style={{fontSize:"19px"}}>Envoi : {store.user_name}</th>
                                </tr>
                                <tr>
                                    <th style={{fontSize:"19px"}}>Reception : {store.receiver_name}</th>
                                </tr>
                                <tr>
                                    <th style={{fontSize:"19px"}}>Destination : {store.destination}</th>
                                </tr>

                                <tr>
                                    <th style={{fontSize:"19px"}}>Date : {Moment(store.created_at).format("DD/MM/YYYY")}</th>
                                </tr>

                            </thead>)
                        }
                    </table>
                    <hr/>
                        <table className="ms-3 table table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nom</th>
                                    <th>Quantité</th>
                                    <th>Unité</th>
                                </tr>
                            </thead>
                            <tbody>

                            {store.stock_mvt_items.map((item) =>
                                <tr key={item.id}>
                                    <td>{counter++} </td>
                                    <td>{item.store_name} </td>
                                    <td>{item.quantity}</td>
                                    <td>{item.store_unit_sign}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                   
                        <p className="centered">{param && param.company_idnat}<br />
                        {param && param.company_rccm}<br />
                            {param && param.company_impot} </p>
                        <p className="centered">Merci pour votre confiance!</p>
                    </div>}
            </div>
        );
    }
};
export default ComponentToPrint;
