import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./componentToPrint";
import {Link} from "react-router-dom";

export default function PrintComponent({ fret, setting }) {

    let componentRef = useRef();


    return (
        <>
            <div>
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <Link className="btn btn-sm btn-primary"><i className="bi bi-printer"></i></Link>}
                    content={() => componentRef}
                />

                {/* component to be printed */}
                <div style={{ display: "none" }}>
                    <ComponentToPrint param={setting} fret={fret} ref={(el) => (componentRef = el)} />
                </div>
            </div>
        </>
    );
}