import React, {useEffect, useState} from "react";
import storeAPI from "../../../apis/adminApi/StoreAPI";
import {toast} from "react-toastify";

function AdminTableRows({rowsData, deleteAdminTableRows, handleChange}) {

    const [stores, setStores] = useState([]);
    const fetchStores = async () => {
        try{
            const data =  await storeAPI.findAll();
            setStores(data);
        }catch(error){
            toast.error("Erreur lors du chargement des produits !");
        }
    };

    useEffect(() => {
        fetchStores();
    }, []);


    return(

        rowsData.map((data, index)=>{
            const {store_id, quantity} = data;
            return(

                <tr key={index}>
                    <td>

                        <select
                            className="form-control form-select"
                            name="store_id"
                            value={store_id}
                            onChange={(evnt)=>(handleChange(index, evnt))}
                            aria-label="Nom du produit">
                            <option value="">Choisir</option>
                            {
                                stores ? (stores.map((store) => (
                                    <option key={store.id} value={store.id}>{store.store_name} - {store.store_quantity} {store.unit_name}</option>
                                ))) : (<></>)
                            }
                        </select>
                    </td>

                    <td>
                        <input
                            placeholder="00"
                            type="number"
                            value={quantity}
                            onChange={(evnt) => (handleChange(index, evnt))}
                            name="quantity"
                            className="form-control"
                        />
                    </td>

                    <td><a className="btn btn-danger" onClick={()=>(deleteAdminTableRows(index))}>
                        <i className="bi bi-trash-fill"></i>
                    </a></td>
                </tr>

            )
        })

    )

}

export default AdminTableRows;