import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import { toast } from "react-toastify";
import StoreAPI from "../../../apis/adminApi/StoreAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import Field from "../../forms/Field";
import {useForm} from "react-hook-form";
import MyButton from "../../assets/MyButton";
import UnitAPI from "../../../apis/adminApi/UnitAPI";

const AdminStoreEdit = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {storeId} = useParams();

    const [showLoader, setShowLoader] = useState(false);
    const [units, setUnits] = useState([]);

    const [store, setStore] = useState({
        store_name : "", unit_id: "", store_quantity : "", min_store : "", max_store:"", id : storeId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        store_name : "", unit_id: "", store_quantity : "", min_store : "", max_store:""
    });


    //Recuperation d'une facture
    const fetchBus = async id => {
        try{
            const { store_name, unit_id, store_quantity, max_store, min_store } = await StoreAPI.find(id);
            setStore({ store_name, unit_id, store_quantity, max_store, min_store });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le produit démandée !");
            navigate("/storees");
        }
    };

    const fetchUnits = async () => {
        try{
            const data =  await UnitAPI.findAll();
            setUnits(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des unites !");
        }
    };

    useEffect(() => {
        fetchUnits();
    },[]);


    useEffect(() => {
        setEditing(true);
        fetchBus(storeId);
    }, [storeId]);



    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setStore({...store, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await StoreAPI.updateStore(storeId, store);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Produit modifié avec succès");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du store !");
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}

                    {!loading && <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier un store</h5>

                                        <Field
                                            name="store_name"
                                            error={errors.store_name}
                                            value={store.store_name}
                                            onChange={handleChange}
                                            label="Nom du produit"
                                            placeholder="Nom"
                                        />

                                        <Field
                                            name="store_quantity"
                                            type="number"
                                            label="Quantité"
                                            error={errors.store_quantity}
                                            value={store.store_quantity}
                                            onChange={handleChange}
                                            placeholder="0"
                                        />

                                        <Field
                                            name="min_store"
                                            type="number"
                                            error={errors.min_store}
                                            value={store.min_store}
                                            onChange={handleChange}
                                            label="Stock minimale"
                                            placeholder="0"
                                        />

                                        <Field
                                            name="max_store"
                                            type="number"
                                            error={errors.max_store}
                                            value={store.max_store}
                                            onChange={handleChange}
                                            label="Stock maximale"
                                            placeholder="0"
                                        />

                                        <label htmlFor="unit_id" className="form-label">Unité de mésure</label>
                                        <select
                                            className={"form-select mb-3 " + ( errors.unit_id && "is-invalid")}
                                            //className={"form-select-lg mb-1 form-control " + ( errors.unit_id && "is-invalid")}
                                            size={units.length}
                                            name="unit_id"
                                            id="unit_id"
                                            value={store.unit_id}
                                            onChange={handleChange}
                                        >
                                            <option value="" selected>Choisir...</option>
                                            {units && units.map(unit =>
                                                <option
                                                    style={{fontWeight:"bold"}}
                                                    key={unit.id} value={unit.id}>{unit.unit_name}</option>)
                                            }
                                        </select>
                                        {errors.unit_id && <div className="invalid-feedback mb-4">{errors.unit_id}</div>}

                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to="/admin/stores" className="btn btn-info mx-2">Revenir au stock</Link>
                                    </div>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    );
};

export default AdminStoreEdit;
