import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import DispatherAPI from "../../apis/dispatcherApi/dispatcherAPI";
import Field from "../forms/Field";
import Select from "../forms/Select";
import MyButton from "../assets/MyButton";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import Moment from "moment/moment";
import TableLoader from "../loaders/TableLoader";

const AddDispatcherBus = () => {
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);

    const [loading, setLoading] = useState(true);
    const [buses, setBuses] = useState([]);
    const [dailyDispatchers, setDailyDispatchers] = useState([]);
    const [trajets, setTrajets] = useState([]);
    const [bus_managers, setBusManagers] = useState([]);
    const [drivers, setDrivers] = useState([]);

    const [dispatch, setAssignment] = useState({
        bus_id : "", busManager : "", busDriver : "", destination : "", day: "", priority: ""
    });

    const [errors, setErrors] = useState({
        bus_id : "", busManager : "", busDriver : "", destination : "", day: "", priority: ""
    });

    const fetchDailyAssignments = async () => {
        try{
            const data =  await DispatherAPI.findAllDailyDispatchers();
            setDailyDispatchers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des affectations du jours !");
        }
    };

    const fetchTrajets = async () => {
        try{
            const data =  await DispatherAPI.findTrajects();
            setTrajets(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des trajets !");
        }
    };

    const fetchAvailableDailyBus = async () => {
        try{
            const data =  await DispatherAPI.findAll();
            setBuses(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des bus !");
        }
    };

    const fetchExcorteurs = async () => {
        try{
            const data =  await DispatherAPI.findBusEscortors();
            setBusManagers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des excorteurs !");
        }
    };

    const fetchDrivers = async () => {
        try{
            const data =  await DispatherAPI.findDrivers();
            setDrivers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des chauffeurs !");
        }
    };


    useEffect(() => {
        fetchTrajets();
        fetchDailyAssignments();
        fetchAvailableDailyBus();
        fetchExcorteurs();
        fetchDrivers();
    },[]);



    const tooltip = (chaffeuer, escorteur) => (
        <Tooltip className="" id="tooltip">
            <ul className="list-group"><li>Chauffeur: {chaffeuer}</li><li>Escort: {escorteur}</li></ul>
        </Tooltip>
    );


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setAssignment({...dispatch, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{
            setShowLoader(true);
            await DispatherAPI.addDispatch(dispatch);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Bus ajouté avec succès");
            navigate('/dispatcher/buses/dailyBuses');
        }catch({response}){
            const {violations} = response.data;
            setTimeout(() => setShowLoader(false), 100);

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire d'ajout !");
            }

            if (response.data.status === 400){
                toast.error(response.data.message);
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-10">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h2 className="card-title">Ajouter ou programmer un bus</h2>
                                <div className="row">
                                    <div className="col-md-4">

                                        <Select name="bus_id" label="Bus disponible" value={dispatch.bus_id}
                                                error={errors.bus_id} onChange={handleChange}>
                                            <option value="">Choisir</option>
                                            {buses.map(bus =>
                                                <option key={bus.id} value={bus.id}>
                                                    {bus.bus_numberplate} - {bus.bus_code}
                                                </option>)
                                            }
                                        </Select>

                                        <Select name="destination" label="Destination" value={dispatch.destination}
                                                error={errors.destination} onChange={handleChange}>
                                            <option value="">Choisir</option>
                                            {trajets.map(trajet =>
                                                <option key={trajet.id} value={trajet.id}>{trajet.from} - {trajet.to}</option>)
                                            }
                                        </Select>
                                    </div>
                                    <div className="col-md-4">

                                        <Select name="busDriver" label="Chauffeur" value={dispatch.busDriver}
                                                error={errors.busDriver} onChange={handleChange}>
                                            <option value="">Choisir</option>
                                            {drivers.map(driver =>
                                                <option key={driver.id} value={driver.id}>{driver.name}</option>)
                                            }
                                        </Select>

                                        <Field
                                            name="priority"
                                            type="number"
                                            error={errors.priority}
                                            value={dispatch.priority}
                                            onChange={handleChange}
                                            label="Priorité"
                                            placeholder="Ex: 1"
                                        />


                                    </div>
                                    <div className="col-md-4">

                                        <Select name="busManager" label="Excorteur" value={dispatch.busManager}
                                                error={errors.busManager} onChange={handleChange}>
                                            <option value="">Choisir</option>
                                            {bus_managers.map(gerant =>
                                                <option key={gerant.id} value={gerant.id}>{gerant.name}</option>)
                                            }
                                        </Select>

                                        <Field
                                            name="day"
                                            type="date"
                                            error={errors.day}
                                            value={dispatch.day}
                                            onChange={handleChange}
                                            label="Date"
                                            placeholder="Date"
                                        />

                                    </div>
                                </div>

                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to="/dispatcher/buses/dailyBuses" className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddDispatcherBus;
