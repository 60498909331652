import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import { toast } from "react-toastify";
import DocAPI from "../../../apis/adminApi/DocumentAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import Field from "../../forms/Field";
import {useForm} from "react-hook-form";
import MyButton from "../../assets/MyButton";
import UnitAPI from "../../../apis/adminApi/UnitAPI";

const EditDocument = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {docId} = useParams();
    const [showLoader, setShowLoader] = useState(false);

    const [document, setDocument] = useState({
        doc_name : "", doc_description: "", id : docId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        doc_name : "", doc_description: ""
    });


    //Recuperation d'une facture
    const fetchDocument = async id => {
        try{
            const { doc_name, doc_description } = await DocAPI.findOneDocument(id);
            setDocument({ doc_name, doc_description });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le document démandé !");
            navigate("/documents");
        }
    };



    useEffect(() => {
        setEditing(true);
        fetchDocument(docId);
    }, [docId]);



    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setDocument({...document, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await DocAPI.updateDocument(docId, document);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Document modifié avec succès");
            navigate("/admin/documents")
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {message} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire!");
            }else if (message){
                toast.error(message);
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}

                    {!loading && <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier un document</h5>


                                        <Field
                                            name="doc_name"
                                            error={errors.doc_name}
                                            value={document.doc_name}
                                            onChange={handleChange}
                                            label="Nom du document"
                                            placeholder="Nom"
                                        />

                                        <Field
                                            name="doc_description"
                                            error={errors.doc_description}
                                            value={document.doc_description}
                                            onChange={handleChange}
                                            label="Description"
                                            placeholder="Description"
                                        />

                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to="/admin/documents" className="btn btn-info mx-2">Revenir aux documents</Link>
                                    </div>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    );
};

export default EditDocument;
