import React, { useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import MechanicAPI from "../../apis/maintainerApi/MaintainerAPI";
import Field from "../forms/Field";
import MyButton from "../assets/MyButton";

const AddMechanic = () => {

    const { formState } = useForm();
    const { isSubmitting } = formState;
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);

    const [user, setUser] = useState({
        name : "", email : "mechanic@kks.com", phone : "", password: "123123123",
        password_confirmation : "123123123", role: "ROLE_MECHANIC", city : ""
    });

    const [errors, setErrors] = useState({
        name : "", phone : "", city : ""
    });

    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setUser({...user, [name] : value})
    };



    let redirect = "mechanics";



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await MechanicAPI.createMechanics(user);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Mecanicien modifié avec succès");
            navigate('/maintainer/mechanics')
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {error} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du mecanicien !");
            }else if (error) {
                toast.error(error);
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-10">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h5 className="card-title">Ajouter un mecanicien</h5>
                                <Field
                                    name="name"
                                    error={errors.name}
                                    value={user.name}
                                    onChange={handleChange}
                                    label="Nom Complet"
                                />


                                <Field
                                    name="phone"
                                    error={errors.phone}
                                    value={user.phone}
                                    onChange={handleChange}
                                    label="Telephone"
                                />


                                <Field
                                    name="city"
                                    error={errors.city}
                                    value={user.city}
                                    onChange={handleChange}
                                    label="Ville"
                                />

                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to={`/maintainer/${redirect}`} className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddMechanic;
