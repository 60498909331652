import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./components/admin/Dashboard";
import LoginPage from "./components/Auth/Login";
import AdminLayout from "./components/includes/layouts/adminLayout";
import CustomerDashboard from "./components/customer/dashboard";
import CustomerLayout from "./components/includes/layouts/customerLayout";
import WebLayout from "./components/includes/layouts/web_layout";

import TrajetIndex from "./components/admin/trajets/index";
import AddTrajet from "./components/admin/trajets/add_trajet";
import EditTrajet from "./components/admin/trajets/edit_trajet";

import BusIndex from "./components/admin/bus/index";
import AddBus from "./components/admin/bus/add_bus";
import EditBus from "./components/admin/bus/edit_bus";

import VendeurIndex from "./components/admin/users/index";
import AffectVendeur from "./components/admin/users/affecte_vendeur";
import ComptableIndex from "./components/admin/users/indexComptable";
import AddUser from "./components/admin/users/add_user";
import EditUser from "./components/admin/users/edit_user";

import PvsIndex from "./components/admin/pvs/index";
import AddPv from "./components/admin/pvs/add_pv";
import EditPv from "./components/admin/pvs/edit_pv";


import SettingIndex from "./components/admin/settings";
import AddSetting from "./components/admin/settings/add_setting";
import EditSetting from "./components/admin/settings/edit_setting";

import RegisterPage from "./components/Auth/Register";
import ValidateUserPage from "./components/Auth/Register/validate_user";
import ForgotPasswordPage from "./components/Auth/Login/forgot_password";
import ResetPasswordPage from "./components/Auth/Login/reset_password";

import NewVente from "./components/customer/ventes/newVente";
import PrintComponent from "./components/customer/ventes/printer";
import EditTicket from "./components/customer/ventes/edit_ticket";
import DailyVentes from "./components/customer/ventes/dailyVentes";
import AllVentes from "./components/customer/ventes/allVentes";
import ComponentToPrintFret from "./components/operateur_fret/frets/componentToPrint";
import EditFret from "./components/operateur_fret/frets/edit_Fret";
import AllFrets from "./components/operateur_fret/frets/allFrets";
import DailyFrets from "./components/operateur_fret/frets/dailyFrets";
import NewFret from "./components/operateur_fret/frets/newFret";
import RapportBilletterie from "./components/admin/rapports/billetterie";
import RapportFret from "./components/admin/rapports/frets";
import ManagerIndex from "./components/admin/users/indexManager";
import BusManagerIndex from "./components/admin/users/indexBusManager";
import DriversIndex from "./components/admin/users/indexDriver";
import FretOperatorIndex from "./components/admin/users/indexFrets";
import ManagerLayout from "./components/includes/layouts/managerLayout";
import ManagerDashboard from "./components/manager/dashboard/index";
import ManagerBus from "./components/dispatcher/bus";
import AddAvailablity from "./components/dispatcher/availablity";
import Allavalablities from "./components/dispatcher/Allavailablities";
import ManagerDriversIndex from "./components/dispatcher/indexDrivers";
import ManagerBusManagerIndex from "./components/dispatcher/indexBusManager";
import EditAvailablity from "./components/dispatcher/edit_availablity";
import AddAssigngment from "./components/manager/addAssignmet";
import AllAssignments from "./components/manager/AllAssigngment";
import BusHourIndex from "./components/admin/busHour";
import AddBusHour from "./components/admin/busHour/add_busHour";
import EditBusHour from "./components/admin/busHour/edit_busHour";
import NewVenteReservation from "./components/customer/ventes/newVenteReservation";
import NewVenteTransite from "./components/customer/ventes/newVenteTransite";
import AddTransiteAssigngment from "./components/manager/addTransiteAssignment";
import AddReservationAssigngment from "./components/manager/addReservationAssignment";
import FretCatalogueIndex from "./components/admin/fretCatalogues";
import EditFretCatalogue from "./components/admin/fretCatalogues/edit_cat";
import AddFretCatalogue from "./components/admin/fretCatalogues/add_cat";
import FretLayout from "./components/includes/layouts/FretLayout";
import FretDashboard from "./components/operateur_fret/dashboard";
import EditAssignment from "./components/manager/editAssignment";
import AuthAPI from "./Services/auth/authAPI";
import RapportDailyBilletterie from "./components/admin/rapports/dailyBilletterie";
import RapportDailyFret from "./components/admin/rapports/dailyfrets";
import DailyBusSpents from "./components/customer/ventes/dailyBusSpents";
import AllBusSpents from "./components/customer/ventes/allBusSpents";
import NewBusSpent from "./components/customer/ventes/newBusSpent";
import DailyAdminBusSpents from "./components/admin/rapports/dailyAdminBusSpents";
import AllAdminBusSpents from "./components/admin/rapports/allAdminBusSpents";
import NewSpent from "./components/customer/ventes/newSpent";
import DailySpents from "./components/customer/ventes/dailySpents";
import AllSpents from "./components/customer/ventes/allSpents";
import AllAdminSpents from "./components/admin/rapports/allAdminSpents";
import DailyAdminSpents from "./components/admin/rapports/dailyAdminSpents";
import ManifestLayout from "./components/includes/layouts/manifestLayout";
import AddSheduledAvailablity from "./components/dispatcher/addSheduledAvailabilities";
import AddSheduledAssigngment from "./components/manager/addSheduledAssignmet";
import DailyFretsByBus from "./components/manifest/pages/byBusdaily";
import EmbarquerFret from "./components/manifest/pages/embarquerFret";
import ManifestDashboard from "./components/manifest/dashboard";
import ManifestIndex from "./components/admin/users/indexManifest";
import DailyReceivedFrets from "./components/manifest/pages/dailyReceivedFrets";
import ReceivedFret from "./components/manifest/pages/receivedFret";
import FretSDelivereds from "./components/manifest/pages/deliverdsFret";
import DailySumary from "./components/admin/rapports/dailySamary";
import AllBusManifest from "./components/manifest/pages/allBusManifest";
import SentFrets from "./components/manifest/pages/sentFret";
import DailySumaryByCity from "./components/admin/rapports/dailySamaryByCity";
import RapportBilletterieByCity from "./components/admin/rapports/billetterieByCity";
import RapportDailyBilletterieByCity from "./components/admin/rapports/dailyBilletterieByCity";
import RapportDailyFretByCity from "./components/admin/rapports/dailyfretsByCity";
import RapportFretByCity from "./components/admin/rapports/fretsByCity";
import DailyAdminBusSpentsByCity from "./components/admin/rapports/dailyAdminBusSpentsByCity";
import EditAgency from "./components/admin/agency/edit_Agency";
import AgencyIndex from "./components/admin/agency";
import AddAgency from "./components/admin/agency/add_Agency";
import UpdateUserPassword from "./components/admin/users/updateUserPassword";
import RapportWeeklyFretByCity from "./components/admin/rapports/weeklyfretsByCity";
import RapportWeeklyFret from "./components/admin/rapports/weeklyFrets";
import ShareBus from "./components/customer/ventes/shareBus";
import AllAdminBusSpentsByCity from "./components/admin/rapports/allAdminBusSpentsByCity";
import WeeklyAdminBusSpents from "./components/admin/rapports/weeklyAdminBusSpents";
import WeeklyAdminBusSpentsByCity from "./components/admin/rapports/weeklyAdminBusSpentsByCity";
import PassengersIndex from "./components/admin/users/indexPassengers";
import OneWeeklyVentes from "./components/customer/ventes/oneWeekyVentes";
import AllWeeklyBusSpents from "./components/customer/ventes/allWeeklyBusSpents";
import AllWeeklyFrets from "./components/operateur_fret/frets/allWeeklyFrets";
import AllWeeklyBusManifest from "./components/manifest/pages/allWeeklyBusManifest";
import DispatcherLayout from "./components/includes/layouts/dispatcherLayout";
import MaintainerLayout from "./components/includes/layouts/maintainerLayout";
import DispatcherDashboard from "./components/dispatcher/dashboard/dispatcher_dash";
import MaintainerDashboard from "./components/maintainer/dashboard/maintainer_dash";
import DispatchersIndex from "./components/admin/users/indexDispatchers";
import MaintainersIndex from "./components/admin/users/indexMaintainers";
import AllDailyAvalablities from "./components/dispatcher/AllDailyavailablities";
import AddDispatcherBus from "./components/dispatcher/addDispatcherBus";
import MechanicsIndex from "./components/maintainer/mechanics";
import AddMechanic from "./components/maintainer/addMechanic";
import EditMechanic from "./components/maintainer/editMechanic";
import DriversMaintainer from "./components/maintainer/drivers";
import StockIndex from "./components/maintainer/stocks";
import BrandIndex from "./components/maintainer/brand";
import AddBrand from "./components/maintainer/addBrand";
import EditBrand from "./components/maintainer/editBrand";
import MaintainerBuses from "./components/maintainer/buses";
import MaintainerEditBus from "./components/maintainer/editBus";
import AddStockMaintainer from "./components/maintainer/addStock";
import EditStockMaintainer from "./components/maintainer/editStock";
import AdminUnitInde from "./components/admin/Unit";
import AdminAddUnit from "./components/admin/Unit/add";
import AdminEditUnit from "./components/admin/Unit/edit";
import AdminBrandInde from "./components/admin/Brand";
import AdminAddBrand from "./components/admin/Brand/add";
import AdminEditBrand from "./components/admin/Brand/edit";
import AdminStoreIndex from "./components/admin/store";
import AdminStoreAdd from "./components/admin/store/add";
import AdminStoreEdit from "./components/admin/store/edit";
import AdminAddStoreMovement from "./components/admin/store/addMovement";
import AdminStoreMovementsIndex from "./components/admin/store/movements";
import AdminStoreMovementsOutIndex from "./components/admin/store/movementsOut";
import MaintainerStoreMovementsIndex from "./components/maintainer/movements";
import MaintenanceIndex from "./components/maintainer/maintenance/maintenanceIndex";
import AddBusMaintenance from "./components/maintainer/maintenance/AddEntretienBus";
import MaintenanceByBus from "./components/maintainer/maintenance/maintenancesByBus";
import AllBusesMaintenances from "./components/maintainer/maintenance/allMaintenances";
import AddMechanicToMaintenance from "./components/maintainer/maintenance/AddMechanicToMaintenance";
import AddProductToMaintenance from "./components/maintainer/maintenance/AddProductToMaintenance";
import MechanicTaskIndex from "./components/maintainer/mechanicTasks/mecanicTaskIndex";
import AddMechanicToMechanicTask from "./components/maintainer/mechanicTasks/AddMechanicToMechanicTask";
import AddProductToMechanicTask from "./components/maintainer/mechanicTasks/AddProductToMechanicTask";
import AllBusMechanicTask from "./components/maintainer/mechanicTasks/allMechanicTasks";
import MechanicTasksByBus from "./components/maintainer/mechanicTasks/mechanicTasksByBus";
import MaintainerAddMechanicTask from "./components/maintainer/mechanicTasks/AddMechanicTaskBus";
import AddProductToRequest from "./components/maintainer/requestModel/AddProductToRequest";
import AddRequestBus from "./components/maintainer/requestModel/AddRequestBus";
import RequestIndex from "./components/maintainer/requestModel/requestIndex";
import AllBusRequests from "./components/maintainer/requestModel/allRequests";
import RequestsByBus from "./components/maintainer/requestModel/RequestByBus";
import AddBusDocument from "./components/admin/documents/addBusDocument";
import AddDocument from "./components/admin/documents/addDocument";
import AdminDocumentIndex from "./components/admin/documents";
import AdminBusDocumentIndex from "./components/admin/documents/indexBusDcoments";
import EditDocument from "./components/admin/documents/edit";
import EditBusDocument from "./components/admin/documents/editBusDoc";
import AdminAddStoreMovementOut from "./components/admin/store/addMovementOut";
import AdminRequestIndex from "./components/admin/store/requestIndex";
import EditRequestBus from "./components/maintainer/requestModel/EditRequestBus";
import EditMechanicTask from "./components/maintainer/mechanicTasks/EditMechanicTask";
import EditMaintenance from "./components/maintainer/maintenance/EditMaintenaice";
import AdminAllBusMechanicTask from "./components/admin/rapports/MechanicTasks";
import AdminAllBusesMaintenances from "./components/admin/rapports/adminMaintenance";

AuthAPI.setup();

function App() {

    return (
    <>
        <HashRouter>
            <Routes>

                <Route path="/" element={<WebLayout />}>

                    <Route index element={<LoginPage />} />
                    <Route path="/" element={<LoginPage />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="register" element={<RegisterPage />} />
                    <Route path="forgot-password" element={<ForgotPasswordPage />} />
                    <Route path="reset-password/:username" element={<ResetPasswordPage />} />
                    <Route path="validate-account/:username" element={<ValidateUserPage />} />

                </Route>

                <Route path="/admin/" element={<AdminLayout allowedRoles={["ROLE_ADMIN"]} />}>

                    <Route index element={<Dashboard />}/>
                    {/*<Route path="dashboard" element={<Dashboard />}/>*/}

                    <Route path="buses" element={<BusIndex />} />
                    <Route path="buses/add" element={<AddBus />} />
                    <Route path="buses/edit/:busId" element={<EditBus />} />

                    <Route path="documents" element={<AdminDocumentIndex />} />
                    <Route path="documents/add" element={<AddDocument />} />
                    <Route path="documents/edit/:docId" element={<EditDocument />} />
                    <Route path="documents/bus_documents" element={<AdminBusDocumentIndex />} />
                    <Route path="documents/bus_documents/add" element={<AddBusDocument />} />
                    <Route path="documents/bus_documents/edit/:budDocId" element={<EditBusDocument />} />

                    <Route path="trajets" element={<TrajetIndex />} />
                    <Route path="trajets/add" element={<AddTrajet />} />
                    <Route path="trajets/edit/:trajetId" element={<EditTrajet />} />



                    <Route path="busesHours" element={<BusHourIndex />} />
                    <Route path="busesHours/add" element={<AddBusHour />} />
                    <Route path="busesHours/edit/:busHourId" element={<EditBusHour />} />

                    <Route path="agencies" element={<AgencyIndex />} />
                    <Route path="agencies/add" element={<AddAgency />} />
                    <Route path="agencies/edit/:agencyId" element={<EditAgency />} />

                    <Route path="pvs" element={<PvsIndex />} />
                    <Route path="pvs/add" element={<AddPv />} />
                    <Route path="pvs/edit/:pvId" element={<EditPv />} />


                    <Route path="fret_catalogues" element={<FretCatalogueIndex />} />
                    <Route path="fret_catalogues/add" element={<AddFretCatalogue />} />
                    <Route path="fret_catalogues/edit/:catId" element={<EditFretCatalogue />} />

                    <Route path="settings" element={<SettingIndex />} />
                    <Route path="settings/add" element={<AddSetting />} />
                    <Route path="settings/edit/:settingId" element={<EditSetting />} />

                    <Route path="vendeurs" element={<VendeurIndex />} />
                    <Route path="fretOperateurs" element={<FretOperatorIndex />} />
                    <Route path="manifests" element={<ManifestIndex />} />
                    <Route path="vendeurs/affecteToPv/:vendeurId" element={<AffectVendeur />} />
                    <Route path="admins" element={<ComptableIndex />} />
                    <Route path="managers" element={<ManagerIndex />} />
                    <Route path="dispatchers" element={<DispatchersIndex />} />
                    <Route path="maintainers" element={<MaintainersIndex />} />
                    <Route path="bus_managers" element={<BusManagerIndex />} />
                    <Route path="drivers" element={<DriversIndex />} />
                    <Route path="users/add" element={<AddUser />} />
                    <Route path="users/edit/:userId" element={<EditUser />} />
                    <Route path="users/updateUserPassword/:userId" element={<UpdateUserPassword />} />

                    <Route path="rapports/dailybilletterie" element={<RapportDailyBilletterie />} />
                    <Route path="rapports/dailybilletterieByCity/:city" element={<RapportDailyBilletterieByCity />} />
                    <Route path="rapports/dailySumary" element={<DailySumary />} />
                    <Route path="rapports/dailySumaryByCity/:city" element={<DailySumaryByCity />} />
                    <Route path="rapports/billetterie" element={<RapportBilletterie />} />
                    <Route path="rapports/billetterieByCity/:city" element={<RapportBilletterieByCity />} />


                    <Route path="rapports/dailyfrets" element={<RapportDailyFret />} />
                    <Route path="rapports/dailyfretsByCity/:city" element={<RapportDailyFretByCity />} />
                    <Route path="rapports/fretsByCity/:city" element={<RapportFretByCity />} />
                    <Route path="rapports/weeklyFretsByCity/:city" element={<RapportWeeklyFretByCity />} />
                    <Route path="rapports/frets" element={<RapportFret />} />
                    <Route path="rapports/weeklyFrets" element={<RapportWeeklyFret />} />


                    <Route path="rapports/dailyBusSpents" element={<DailyAdminBusSpents />} />
                    <Route path="rapports/dailyBusSpentsByCity/:city" element={<DailyAdminBusSpentsByCity />} />

                    <Route path="rapports/allBusSpentsByCity/:city" element={<AllAdminBusSpentsByCity />} />
                    <Route path="rapports/allBusSpents" element={<AllAdminBusSpents />} />
                    <Route path="rapports/weeklyBusSpents" element={<WeeklyAdminBusSpents />} />
                    <Route path="rapports/weeklyBusSpentsByCity/:city" element={<WeeklyAdminBusSpentsByCity />} />


                    <Route path="rapports/allSpents" element={<AllAdminSpents />} />
                    <Route path="rapports/dailySpents" element={<DailyAdminSpents />} />

                    <Route path="passengers" element={<PassengersIndex />} />


                    <Route path="brands" element={<AdminBrandInde />} />
                    <Route path="brands/add" element={<AdminAddBrand />} />
                    <Route path="brands/edit/:brandId" element={<AdminEditBrand />} />

                    <Route path="units" element={<AdminUnitInde />} />
                    <Route path="units/add" element={<AdminAddUnit />} />
                    <Route path="units/edit/:unitId" element={<AdminEditUnit />} />

                    <Route path="stores" element={<AdminStoreIndex />} />
                    <Route path="stores/movements/add" element={<AdminAddStoreMovement />} />
                    <Route path="stores/movements/addOut" element={<AdminAddStoreMovementOut />} />
                    <Route path="stores/movements" element={<AdminStoreMovementsIndex />} />
                    <Route path="stores/movements/out" element={<AdminStoreMovementsOutIndex />} />
                    <Route path="stores/add" element={<AdminStoreAdd />} />
                    <Route path="stores/edit/:storeId" element={<AdminStoreEdit />} />

                    <Route path="requests" element={<AdminRequestIndex />}/>
                    <Route path="requests/allBus" element={<AllBusRequests />}/>
                    <Route path="adminMechanicTasks/allBus" element={<AdminAllBusMechanicTask />}/>
                    <Route path="adminMaintenances/allBus" element={<AdminAllBusesMaintenances />}/>


                </Route>

                <Route path="/customer/" element={<CustomerLayout allowedRoles={["ROLE_VENDOR"]} />}>

                    <Route index element={<CustomerDashboard />}/>
                    <Route path="dashboard" element={<CustomerDashboard />}/>

                    <Route path="newVente" element={<NewVente />}/>
                    <Route path="newVenteReservation" element={<NewVenteReservation />}/>
                    <Route path="newVenteTransite" element={<NewVenteTransite />}/>

                    <Route path="dailyVentes" element={<DailyVentes />}/>
                    <Route path="allVentes" element={<AllVentes />}/>
                    <Route path="OneWeeklyVentes" element={<OneWeeklyVentes />}/>
                    <Route path="tickets/printer/:ticketId" element={<PrintComponent />}/>
                    <Route path="tickets/edit/:ticketId" element={<EditTicket />}/>


                    <Route path="newBusSpent/:busSpentId" element={<NewBusSpent />}/>
                    <Route path="dailyBusSpents" element={<DailyBusSpents />}/>
                    <Route path="allBusSpents" element={<AllBusSpents />}/>
                    <Route path="allWeeklyBusSpents" element={<AllWeeklyBusSpents />}/>


                    <Route path="newSpent" element={<NewSpent />}/>
                    <Route path="dailySpents" element={<DailySpents />}/>
                    <Route path="allSpents" element={<AllSpents />}/>

                    <Route path="share/bus/:assigmentId" element={<ShareBus />}/>

                </Route>

                <Route path="/manifest/" element={<ManifestLayout allowedRoles={["ROLE_MANIFEST"]} />}>

                    <Route index element={<ManifestDashboard />}/>
                    <Route path="dashboard" element={<ManifestDashboard />}/>

                    <Route path="embarquerFret" element={<EmbarquerFret />}/>
                    <Route path="sentFrets" element={<SentFrets />}/>
                    <Route path="receivedFret" element={<ReceivedFret />}/>
                    <Route path="fretByBus" element={<DailyFretsByBus />}/>
                    <Route path="allFretByBus" element={<AllBusManifest />}/>
                    <Route path="allWeeklyFretByBus" element={<AllWeeklyBusManifest />}/>
                    <Route path="fretReceivedByBus" element={<DailyReceivedFrets />}/>
                    <Route path="fretSDelivereds" element={<FretSDelivereds />}/>

                </Route>

                <Route path="/fret/" element={<FretLayout allowedRoles={["ROLE_FRET"]} />}>

                    <Route index element={<FretDashboard />}/>
                    <Route path="dashboard" element={<FretDashboard />}/>

                    <Route path="newFret" element={<NewFret />}/>
                    <Route path="dailyFrets" element={<DailyFrets />}/>
                    <Route path="allFrets" element={<AllFrets />}/>
                    <Route path="allWeeklyFrets" element={<AllWeeklyFrets />}/>
                    <Route path="frets/printer/:fretId" element={<ComponentToPrintFret />}/>
                    <Route path="frets/edit/:fretId" element={<EditFret />}/>

                </Route>

                <Route path="/dispatcher/" element={<DispatcherLayout allowedRoles={["ROLE_DISPATCHER"]} />}>

                    <Route index element={<DispatcherDashboard />}/>
                    <Route path="dashboard" element={<DispatcherDashboard />}/>

                    <Route path="buses" element={<ManagerBus />}/>
                    <Route path="buses/add_availablity" element={<AddAvailablity />}/>
                    <Route path="buses/add-Sheduled-availablity" element={<AddSheduledAvailablity />}/>
                    <Route path="buses/availablities" element={<Allavalablities />}/>
                    <Route path="buses/addDispatcherBus" element={<AddDispatcherBus />}/>
                    <Route path="buses/dailyBuses" element={<AllDailyAvalablities />}/>
                    <Route path="buses/sheduledBuses" element={<AddAvailablity />}/>
                    <Route path="dispatch/:dispatchId/edit" element={<EditAvailablity />}/>

                    <Route path="drivers" element={<ManagerDriversIndex />}/>
                    <Route path="gerants" element={<ManagerBusManagerIndex />}/>

                </Route>

                <Route path="/maintainer/" element={<MaintainerLayout allowedRoles={["ROLE_MAINTAINER"]} />}>

                    <Route index element={<MaintainerDashboard />}/>
                    <Route path="dashboard" element={<FretDashboard />}/>

                    <Route path="mechanics" element={<MechanicsIndex />}/>
                    <Route path="drivers" element={<DriversMaintainer />}/>
                    <Route path="mechanics/addMechanic" element={<AddMechanic />}/>
                    <Route path="mechanics/edit/:mechanicId" element={<EditMechanic />}/>

                    <Route path="brands" element={<BrandIndex />}/>
                    <Route path="brands/addBrand" element={<AddBrand />}/>
                    <Route path="brands/edit/:brandId" element={<EditBrand />}/>

                    <Route path="buses" element={<MaintainerBuses />}/>
                    <Route path="buses/addBus" element={<AddBrand />}/>
                    <Route path="buses/edit/:busId" element={<MaintainerEditBus />}/>

                    <Route path="stocks" element={<StockIndex />}/>
                    <Route path="stocks/addStocks" element={<AddStockMaintainer />}/>
                    <Route path="stocks/edit/:stockId" element={<EditStockMaintainer />}/>

                    <Route path="stocks/stock-movements" element={<MaintainerStoreMovementsIndex />}/>

                    <Route path="maintenances" element={<MaintenanceIndex />}/>
                    <Route path="maintenances/allBus" element={<AllBusesMaintenances />}/>
                    <Route path="buses/:busId/maintenances/add" element={<AddBusMaintenance />}/>
                    <Route path="buses/:busId/maintenances" element={<MaintenanceByBus />}/>
                    <Route path="maintenances/:maintenanceId/addProduct" element={<AddProductToMaintenance />}/>
                    <Route path="maintenances/:maintenanceId/addMechanic" element={<AddMechanicToMaintenance />}/>
                    <Route path="maintenances/:maintenanceId/edit" element={<EditMaintenance />}/>

                    <Route path="mechanicTasks" element={<MechanicTaskIndex />}/>
                    <Route path="mechanicTasks/allBus" element={<AllBusMechanicTask />}/>
                    <Route path="buses/:busId/mechanicTasks/add" element={<MaintainerAddMechanicTask />}/>
                    <Route path="buses/:busId/mechanicTasks" element={<MechanicTasksByBus />}/>
                    <Route path="mechanicTasks/:mechanicTasksId/addProduct" element={<AddProductToMechanicTask />}/>
                    <Route path="mechanicTasks/:mechanicTasksId/addMechanic" element={<AddMechanicToMechanicTask />}/>
                    <Route path="mechanicTasks/:mechanicTasksId/edit" element={<EditMechanicTask />}/>

                    <Route path="requests" element={<RequestIndex />}/>
                    <Route path="requests/allBus" element={<AllBusRequests />}/>
                    <Route path="buses/:busId/requests/add" element={<AddRequestBus />}/>
                    <Route path="requests/:requestId/edit" element={<EditRequestBus />}/>
                    <Route path="buses/:busId/requests" element={<RequestsByBus />}/>
                    <Route path="requests/:requestId/addProduct" element={<AddProductToRequest />}/>

                </Route>

                <Route path="/manager/" element={<ManagerLayout allowedRoles={["ROLE_MANAGER"]} />}>

                    <Route index element={<ManagerDashboard />}/>
                    {/*<Route path="dashboard" element={<ManagerDashboard />}/>*/}

                    <Route path="buses" element={<ManagerBus />}/>
                    <Route path="buses/add_availablity" element={<AddAvailablity />}/>
                    <Route path="buses/availablities" element={<Allavalablities />}/>
                    <Route path="buses/availablity/:available_id/edit" element={<EditAvailablity />}/>

                    <Route path="drivers" element={<ManagerDriversIndex />}/>
                    <Route path="gerants" element={<ManagerBusManagerIndex />}/>

                    <Route path="new_assignment" element={<AddAssigngment />}/>
                    <Route path="new-sheduled-assignment" element={<AddSheduledAssigngment />}/>
                    <Route path="assignment/:assiId/edit" element={<EditAssignment />}/>
                    <Route path="new_transite_assignment" element={<AddTransiteAssigngment />}/>
                    <Route path="new_reservation_assignment" element={<AddReservationAssigngment />}/>
                    <Route path="all_assignments" element={<AllAssignments />}/>

                </Route>
            </Routes>
            <ToastContainer position={toast.POSITION.BOTTOM_LEFT} theme="colored"  />
        </HashRouter>
    </>
  );
}

export default App;

// const rootElement = document.querySelector("#app");
// const root = ReactDOM.createRoot(document.querySelector("#app"));
// root.render(<App />);
