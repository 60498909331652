import axios from "axios";
import {UNITS_API} from "../../config";
import Cache from "../cache";

async function findAll() {
    //const cachedUnits = await Cache.get("AdminUnits");
    //if(cachedUnits) return cachedUnits;
    return axios
        .get(UNITS_API)
        .then(response => {
            //const units = response.data.units;
            //Cache.set("AdminUnits", units);
            return response.data.units;
        });
}

async function find(id){

    //const cachedUnits = await Cache.get("AdminUnits." + id);
    //if(cachedUnits) return cachedUnits;
    return axios.get(UNITS_API + "/" + id)
        .then(response => {
            return response.data.unit;
        });
}


function deleteUnit(id) {

    return axios
        .delete(UNITS_API + "/" + id)
        .then(async response => {
            const cachedUnits = await Cache.get("units");

            if(cachedUnits){
                Cache.set("AdminUnits", cachedUnits.filter(i => i.id != id));
            }

            return response;
        });
}

function updateUnit(id, unit){

    return axios.post(UNITS_API + "/store", {...unit, id: id})
        .then(async response => {
            return response;
        });
}

function create(unit){

    return axios.post(UNITS_API + "/store", unit)
        .then(async response => {
            const cachedUnits = await Cache.get("AdminUnits");

            if(cachedUnits){
                Cache.set("AdminUnits", [...cachedUnits, response.data.unit]);
            }

            return response;
        });
}


export default {
    findAll,
    find,
    create,
    updateUnit,
    delete : deleteUnit,
};