import React from 'react';


const Textarea = ({name, label, defaultValue, onChange, placeholder = "", error = "" }) =>
(<div className="mb-3">
    <label htmlFor={name} className="form-label">{label}</label>
    <textarea
        onChange={onChange}
        defaultValue={defaultValue}
        name={name}
        placeholder={placeholder || label}
        id={name}
        className={"form-control " + ( error && "is-invalid")} rows="4"></textarea>
    {error && <div className="invalid-feedback">{error}</div>}
</div> 
);
 
export default Textarea;