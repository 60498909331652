import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import MechanicAPI from "../../apis/maintainerApi/MaintainerAPI";
import Field from "../forms/Field";
import MyButton from "../assets/MyButton";
import FormContentLoader from "../loaders/FormContentLoader";
import MaintainerAPI from "../../apis/maintainerApi/MaintainerAPI";

const MaintainerEditBus = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {busId} = useParams();
    const [showLoader, setShowLoader] = useState(false);
    const [brands, setBrands] = useState([]);

    const [bus, setBus] = useState({
        bus_numberplate : "", brand_id : "", id : busId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        bus_numberplate : "",  brand_id : ""
    });

    const fetchBrands = async () => {
        try{
            const data =  await MaintainerAPI.findBrands();
            setBrands(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des genres !");
        }
    };

    const fetchBus = async id => {
        try{
            const { bus_numberplate, brand_id} = await MechanicAPI.findOneBus(id);
            setBus({ bus_numberplate, brand_id });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le bus démandé !");
            navigate("/maintainer/buses");
        }
    };



    useEffect(() => {
        setEditing(true);
        fetchBus(busId);
    }, [busId]);

    useEffect(() => {
        fetchBrands();
    },[]);


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setBus({...bus, [name] : value})
    };

    let redirect = "buses";



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await MechanicAPI.updateBus(busId, bus);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Bus modifié avec succès");
            navigate('/maintainer/buses')
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {error} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du bus !");
            }else if (error) {
                toast.error(error);
            }
        }
    };



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Modifier un buss</h5>

                                <Field
                                    name="bus_name"
                                    disabled="disabled"
                                    error={errors.bus_numberplate}
                                    value={bus.bus_numberplate}
                                    onChange={handleChange}
                                    label="Nom Complet"
                                />

                                <div className="mb-3">
                                    <h5 className="text-primary">Choisir un genre</h5>
                                    {brands && brands.map(brand =>
                                        <div className="form-check" key={brand.id}>
                                            <input onChange={handleChange} value={brand.id}
                                                   className="form-check-input" type="radio"
                                                   name="brand_id" id={brand.to} />
                                            <label className="form-check-label" htmlFor={brand.to}>
                                                <b>{brand.brand_name}</b>
                                            </label>
                                        </div>
                                    )}
                                </div>


                                <MyButton
                                    text="Modifier"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to={`/maintainer/${redirect}`} className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </div>
                    </form> }
                </div>
            </div>
        </div>
    );
};

export default MaintainerEditBus;
