import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import MechanicAPI from "../../../apis/maintainerApi/MaintainerAPI";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";
import FormContentLoader from "../../loaders/FormContentLoader";
import MaintainerAPI from "../../../apis/maintainerApi/MaintainerAPI";
import Textarea from "../../forms/Textarea";
import BielletterieAPI from "../../../apis/adminApi/bielleterieAPI";

const EditMaintenance = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {maintenanceId} = useParams();
    const [showLoader, setShowLoader] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [bus, setBus] = useState([]);

    const [maintenance, setMaintenance] = useState({
        bus_id: "", id : maintenanceId, old_mileage: "", actual_mileage: "", observation: "", next_mileage: "", driver_id: ""
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        old_mileage: "", actual_mileage: "", observation: "", next_mileage: "", driver_id: ""
    });



    const fetchDrivers = async () => {
        try{
            const data =  await MaintainerAPI.findDrivers();
            setDrivers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des chauffeurs !");
        }
    };


    const fetchRequest = async id => {
        try{
            const {products_in_tasks,mechanic_in_tasks, old_mileage, actual_mileage,next_mileage,driver_id, observation, bus_id,bus_numberplate, id} = await MechanicAPI.findOneMaintenances(maintenanceId);
            setMaintenance({products_in_tasks,mechanic_in_tasks, old_mileage, actual_mileage,next_mileage,bus_id, driver_id,observation,bus_numberplate, id });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le maintenance démandé !");
            //navigate("/maintainer/maintenances");
        }
    };



    useEffect(() => {
        setEditing(true);
        fetchRequest(maintenanceId);
    }, [maintenanceId]);

    useEffect(() => {
        fetchDrivers();
    },[]);


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setMaintenance({...maintenance, [name] : value})
    };

    let redirect = "maintenances/allBus";



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await MaintainerAPI.updateMaintenances(maintenanceId, maintenance);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Demande modifiée avec succès");
            navigate('/maintainer/maintenances/allBus');
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {error} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire!");
            }else if (error) {
                toast.error(error);
            }
        }
    };

    const handleDelete = async id => {

        try{
            await MaintainerAPI.deleteProductInTask(id);
            toast.success("La piece a bien été supprimée !");
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de la suppression du tickets !");
        }
    };

    const handleDeleteMechanic = async id => {
        try{
            await MaintainerAPI.deleteMechanicInTask(id);
            toast.success("Le mecanicien a bien été supprimée !");
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de la suppression du tickets !");
        }
    }



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Ajouter une demande d'achat</h5>
                                <div className="row">
                                    <div className="col-4">
                                        <Field
                                            name="bus_id"
                                            disabled="disabled"
                                            value={"Bus : "+maintenance.bus_numberplate}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <h4>Les pieces</h4>
                                        <ul>
                                            {maintenance.products_in_tasks && maintenance.products_in_tasks.map((item) =>
                                                <li>
                                                    {item.product_name} {item.task_id} {item.task_name}
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                handleDelete(item.id)
                                                            }
                                                        }}
                                                        className="btn btn-sm btn-danger">
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="col-4">
                                        <h4>Les mecaniciens</h4>
                                        <ul>
                                            {maintenance.mechanic_in_tasks && maintenance.mechanic_in_tasks.map((item) =>
                                                <li>
                                                    {item.mechanic_name}
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                handleDeleteMechanic(item.id)
                                                            }
                                                        }}
                                                        className="btn btn-sm btn-danger">
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <Field
                                            name="old_mileage"
                                            type="number"
                                            error={errors.old_mileage}
                                            value={maintenance.old_mileage}
                                            onChange={handleChange}
                                            label="Encien kilometrage"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <Field
                                            name="actual_mileage"
                                            type="number"
                                            error={errors.actual_mileage}
                                            value={maintenance.actual_mileage}
                                            onChange={handleChange}
                                            label="Kilometrage actuel"
                                        />
                                    </div>

                                    <div className="col-6">
                                        <Field
                                            name="next_mileage"
                                            type="number"
                                            error={errors.next_mileage}
                                            value={maintenance.next_mileage}
                                            onChange={handleChange}
                                            label="Prochain Kilometrage"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="driver_id">Chauffeur</label>
                                        <select
                                            className={"form-control " + ( errors.driver_id && "is-invalid")}
                                            //size={(buses.length)}
                                            name="driver_id"
                                            id="driver_id"
                                            value={maintenance.driver_id}
                                            onChange={handleChange}
                                        >

                                            <option value="" selected>Choisir...</option>
                                            {drivers && drivers.map((driver) => (
                                                <option style={{fontWeight:"bold"}} key={driver.id} value={driver.id}>
                                                    {driver.name} </option>
                                            ))
                                            }
                                        </select>
                                        {errors.driver_id && <div className="invalid-feedback mb-4">{errors.driver_id}</div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-10">
                                        <Field
                                            name="observation"
                                            error={errors.observation}
                                            value={maintenance.observation}
                                            onChange={handleChange}
                                            label="Observation"
                                            placeholder="Observation"
                                        />
                                    </div>

                                    <Field
                                        name="bus_id"
                                        type="hidden"
                                        value={bus.id}
                                    />
                                </div>


                                <MyButton
                                    text="Modifier"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to={`/maintainer/${redirect}`} className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </div>
                    </form> }
                </div>
            </div>
        </div>
    );
};

export default EditMaintenance;
