import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import { toast } from "react-toastify";
import BrandAPI from "../../../apis/adminApi/BrandAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import Field from "../../forms/Field";
import {useForm} from "react-hook-form";
import MyButton from "../../assets/MyButton";

const AdminEditBrand = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {brandId} = useParams();

    const [showLoader, setShowLoader] = useState(false);

    const [brand, setBrand] = useState({
        brand_name : "", id : brandId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        brand_name : "",
    });


    //Recuperation d'une facture
    const fetchBus = async id => {
        try{
            const { brand_name, brand_sign } = await BrandAPI.find(id);
            setBrand({ brand_name, brand_sign });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger l'brande démandée !");
            navigate("/brands");
        }
    };


    useEffect(() => {
        setEditing(true);
        fetchBus(brandId);
    }, [brandId]);


    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setBrand({...brand, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await BrandAPI.updateBrand(brandId, brand);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Brande modifié avec succès");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification !");
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-7">
                    {loading && <FormContentLoader />}

                    {!loading && <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier une brandé</h5>
                                        <div className="row">
                                            <div className="col-6">
                                                <Field
                                                    name="brand_name"
                                                    error={errors.brand_name}
                                                    value={brand.brand_name}
                                                    onChange={handleChange}
                                                    label="Nom de la marque"
                                                    placeholder="Nom"
                                                />
                                            </div>
                                        </div>

                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to="/admin/brands" className="btn btn-info mx-2">Revenir aux brandés</Link>
                                    </div>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    );
};

export default AdminEditBrand;
