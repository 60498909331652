import React, { useRef,useState,useEffect } from "react";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./componentToPrint";
import {toast} from "react-toastify";
import VendeurAPI from "../../../apis/adminApi/StoreAPI";

export default function PrintComponent({ setting, store }) {

    let componentRef = useRef(null);

    const handleAfterPrint = async (data) => {
        try{
            const response =  await VendeurAPI.printerTicket(data.id);
            toast.error(response);
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de l'impression !");
        }
    }


    return (
        <>
            <div>
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <button className="brn btn-sm btn-primary">
                        <i className="bi bi-printer"></i>
                    </button>}
                    content={() => componentRef}
                />

                {/* component to be printed */}
                <div style={{ display: "none" }}>
                    <ComponentToPrint param={setting} store={store} ref={(el) => (componentRef = el)} />
                </div>
            </div>

        </>
    );
}