import React,{useState} from 'react';
import AuthService from "../../../Services/auth/authAPI";
import {Link, useNavigate, useParams} from "react-router-dom";

const FretSideBar = ({pathname}) => {

    const id = useParams();
    const navigate = useNavigate();
    const [me, setMe] = useState(AuthService.getAuthenticatedUser());

    const handleLogout = () => {
        AuthService.logout();
        navigate("/login")
    };

    return (
        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <Link className={pathname === '/fret/dashboard' ? 'nav-link ' : 'nav-link collapsed'} to="/fret/dashboard">
                        <i className="bi bi-grid"></i>
                        <span>Tableau de bord</span>
                    </Link>
                </li>

                <li className="nav-heading">VENTE FRET</li>

                <li className="nav-item">
                    <Link className={pathname === '/fret/newFret' ? 'nav-link ' : 'nav-link collapsed'} to="/fret/newFret">
                        <i className="bi bi-plus"></i>
                        <span>Nouveau</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/fret/dailyFrets' ? 'nav-link ' : 'nav-link collapsed'} to="/fret/dailyFrets">
                        <i className="bi bi-bus-front"></i>
                        <span>Fret du jour</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/fret/allWeeklyFrets' ? 'nav-link ' : 'nav-link collapsed'} to="/fret/allWeeklyFrets">
                        <i className="bi bi-boxes"></i>
                        <span>Frets 7 derniers jours</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/fret/allFrets' ? 'nav-link ' : 'nav-link collapsed'} to="/fret/allFrets">
                        <i className="bi bi-boxes"></i>
                        <span>Tous mes Fret</span>
                    </Link>
                </li>

                {/*<li className="nav-heading">EMBARQUEMENT</li>*/}

                {/*<li className="nav-item">*/}
                    {/*<Link className={pathname === '/fret/embarquerFret' ? 'nav-link ' : 'nav-link collapsed'} to="/fret/embarquerFret">*/}
                        {/*<i className="bi bi-bus-front"></i>*/}
                        {/*<span>Embarquer</span>*/}
                    {/*</Link>*/}
                {/*</li>*/}

                {/*<li className="nav-item">*/}
                    {/*<Link className={pathname === '/fret/fretByBus' ? 'nav-link ' : 'nav-link collapsed'} to="/fret/fretByBus">*/}
                        {/*<i className="bi bi-bus-front"></i>*/}
                        {/*<span>Par bus</span>*/}
                    {/*</Link>*/}
                {/*</li>*/}

                {/*<li className="nav-heading">RECEPTION</li>*/}
            </ul>

            <div className="fixed-bottom mx-4 my-3">
                <button className="btn btn-danger" onClick={handleLogout}>
                    <i className="bi bi-box-arrow-left"></i>
                    <span> Se deconnecter</span>
                </button>
            </div>

        </aside>
    );
};

export default FretSideBar;