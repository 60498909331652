import React, { useEffect, useState, useRef } from 'react';
import './printer.css';
import logo from './../logo.png';
import Moment from "moment";
import {Link} from "react-router-dom";
import PrintComponent from "./printer";

class ComponentToPrint extends React.Component {

    render() {
        const param = this.props.param;
        const mechanicsTasks = this.props.mechanicsTasks;
        const bus = this.props.bus;
        let counter = 1;

        return (
            <div className="ms-2">
                {mechanicsTasks && <div className="">
                        <center><img src={logo} alt="logo" /></center>
                    <p className="centered"><b>{param && param.company_address}</b></p>
                    <hr/>
                    <table className="ms-3">
                        <thead>
                        <tr>
                            <th style={{fontSize:"19px"}}>TACHE DE  MECANICIEN</th>
                        </tr>
                        {bus.id && <tr>
                            <th style={{fontSize:"19px"}}>Bus : {bus.bus_numberplate}</th>
                        </tr>}

                        </thead>
                    </table>
                    <hr/>
                    <table className="table table-hover table-striped table-responsive">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">MECANICIEN</th>
                            <th scope="col">Bus</th>
                            <th scope="col">Chauffeur</th>
                            <th scope="col">Date</th>
                            <th scope="col">PLAINTE</th>
                            <th scope="col">PIECE DE RECHANGE</th>
                            <th scope="col">OBSERVATION</th>
                        </tr>
                        </thead>
                        <tbody>
                        {mechanicsTasks.map((mechanicTask) => (
                            <tr key={mechanicTask.id}>
                                <th scope="row">{counter++}</th>
                                <td>
                                    <ul>
                                        {mechanicTask.mechanics_in_task.map((item) =>
                                            <li>{item.mechanic_name}</li>
                                        )}
                                    </ul>
                                </td>
                                <td>{mechanicTask.bus_brand}<br/>{mechanicTask.bus_numberplate} </td>
                                <td>{mechanicTask.bus_numberplate}</td>
                                <td>{mechanicTask.driver_name}</td>
                                <td>{Moment(mechanicTask.input_date).format("DD/MM/YYYY")}</td>
                                <td>{mechanicTask.complaint}</td>
                                <td>
                                    <ul>
                                        {mechanicTask.products_in_tasks.map((item) =>
                                            <li>{item.product_name} : {item.task_id} {item.task_name}</li>
                                        )}
                                    </ul>
                                </td>
                                <td>{mechanicTask.observation}</td>
                            </tr>
                        ))
                        }
                        </tbody>
                    </table>
                   
                        <p className="centered">{param && param.company_idnat}<br />
                        {param && param.company_rccm}<br />
                            {param && param.company_impot} </p>

                    </div>}
            </div>
        );
    }
};
export default ComponentToPrint;
