import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import { toast } from "react-toastify";
import StoreAPI from "../../../apis/adminApi/StoreAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import Field from "../../forms/Field";
import {useForm} from "react-hook-form";
import MyButton from "../../assets/MyButton";
import BusApi from "../../../apis/adminApi/busAPI";
import DocumentApi from "../../../apis/adminApi/DocumentAPI";

const EditBusDocument = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {budDocId} = useParams();

    const [showLoader, setShowLoader] = useState(false);
    const [units, setUnits] = useState([]);
    const [buses, setBus] = useState([]);
    const [docs, setDocs] = useState([]);

    const [busDocument, setStore] = useState({
        document_id : "", bus_id: "", edition : "", alerted_at : "", expired_at:"", id : budDocId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        document_id : "", bus_id: "", edition : "", alerted_at : "", expired_at:""
    });


    //Recuperation d'une facture
    const fetchOneBusDocment = async id => {
        try{
            const { document_id, bus_id, edition, alerted_at, expired_at, id } = await DocumentApi.findOneBusDocument(budDocId);
            setStore({ document_id, bus_id, edition, alerted_at, expired_at, id });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le produit démandée !");
            //navigate("/admin/documents/bus_documents");
        }
    };

    const fetchBuses = async () => {
        try{
            const data =  await BusApi.findAll();
            setBus(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des bus !");
        }
    };

    const fetchDocuments = async () => {
        try{
            const data =  await DocumentApi.findDocuments();
            setDocs(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des documents !");
        }
    };

    useEffect(() => {
        fetchBuses();
        fetchDocuments();
    },[]);


    useEffect(() => {
        setEditing(true);
        fetchOneBusDocment(budDocId);
    }, [budDocId]);


    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setStore({...busDocument, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await DocumentApi.updateBusDocument(busDocument, budDocId);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Document de bord modifié avec succès");
            navigate("/admin/documents/bus_documents");
            window.location.reload();
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du busDocument !");
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}

                    {!loading && <form onSubmit={handleSubmit}>
                        <div className="card-body">
                            <h5 className="card-title">Modifier un document à un bus</h5>

                            <label htmlFor="document_id" className="form-label">Document</label>
                            <select
                                className={"form-control mb-3 " + ( errors.document_id && "is-invalid")}
                                name="document_id"
                                id="document_id"
                                value={busDocument.document_id}
                                onChange={handleChange}
                            >
                                <option value="" selected>Choisir...</option>
                                {docs && docs.map(doc =>
                                    <option
                                        style={{fontWeight:"bold"}}
                                        key={doc.id} value={doc.id}>{doc.doc_name}</option>)
                                }
                            </select>
                            {errors.document_id && <div className="invalid-feedback mb-4">{errors.document_id}</div>}

                            <Field
                                name="edition"
                                type="text"
                                error={errors.edition}
                                value={busDocument.edition}
                                onChange={handleChange}
                                label="Année d'edition"
                                placeholder="Ex:2023"
                            />


                            <Field
                                name="expired_at"
                                type="date"
                                error={errors.expired_at}
                                value={busDocument.expired_at}
                                onChange={handleChange}
                                label="Date d'expiration"
                            />

                            {/*<Field*/}
                            {/*    name="alerted_at"*/}
                            {/*    type="date"*/}
                            {/*    error={errors.alerted_at}*/}
                            {/*    value={busDocument.alerted_at}*/}
                            {/*    onChange={handleChange}*/}
                            {/*    label="Date d'alerte"*/}
                            {/*/>*/}

                            <label htmlFor="bus_id" className="form-label">Bus</label>
                            <select
                                className={"form-control mb-2" + ( errors.bus_id && "is-invalid")}
                                //className={"form-select-lg mb-1 form-control " + ( errors.bus_id && "is-invalid")}
                                name="bus_id"
                                disabled="disabled"
                                id="bus_id"
                                value={busDocument.bus_id}
                                onChange={handleChange}
                            >
                                <option value="" selected>Choisir...</option>
                                {buses && buses.map(bus =>
                                    <option
                                        style={{fontWeight:"bold"}}
                                        key={bus.id} value={bus.id}>{bus.bus_numberplate}</option>)
                                }
                            </select>
                            {errors.bus_id && <div className="invalid-feedback mb-4">{errors.bus_id}</div>}
                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to="/admin/documents/bus_documents" className="btn btn-info mx-2">Revenir au document</Link>
                                    </div>

                            </form>
                    }
                </div>
            </div>
        </div>
    );
};

export default EditBusDocument;
