import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import { toast } from "react-toastify";
import UnitAPI from "../../../apis/adminApi/UnitAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import Field from "../../forms/Field";
import {useForm} from "react-hook-form";
import MyButton from "../../assets/MyButton";

const AdminEditUnit = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {unitId} = useParams();

    const [showLoader, setShowLoader] = useState(false);

    const [unit, setUnit] = useState({
        unit_name : "", unit_sign : "", id : unitId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        unit_name : "", unit_sign : ""
    });


    //Recuperation d'une facture
    const fetchBus = async id => {
        try{
            const { unit_name, unit_sign } = await UnitAPI.find(id);
            setUnit({ unit_name, unit_sign });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger l'unite démandée !");
            navigate("/units");
        }
    };


    useEffect(() => {
        setEditing(true);
        fetchBus(unitId);
    }, [unitId]);


    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setUnit({...unit, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await UnitAPI.updateUnit(unitId, unit);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Unite modifié avec succès");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification !");
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-7">
                    {loading && <FormContentLoader />}

                    {!loading && <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier une unité</h5>
                                        <div className="row">
                                            <div className="col-6">
                                                <Field
                                                    name="unit_name"
                                                    error={errors.unit_name}
                                                    value={unit.unit_name}
                                                    onChange={handleChange}
                                                    label="Nom de l'unité"
                                                    placeholder="Nom"
                                                />
                                            </div>
                                            <div className="col-6">
                                                <Field
                                                    name="unit_sign"
                                                    error={errors.unit_sign}
                                                    value={unit.unit_sign}
                                                    onChange={handleChange}
                                                    label="Symbole de l'unité"
                                                    placeholder="Symbole"
                                                />
                                            </div>
                                        </div>

                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to="/admin/units" className="btn btn-info mx-2">Revenir aux unités</Link>
                                    </div>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    );
};

export default AdminEditUnit;
