import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Moment from "moment";
import {toast} from "react-toastify";
import Pagination from "../assets/Pagination";
import TableLoader from "../loaders/TableLoader";
import DispatherAPI from "../../apis/dispatcherApi/dispatcherAPI";

const Allavalablities = () => {

    const [loading, setLoading] = useState(true);
    const [allDispatcherBus, setAllDispatcherBus] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [vivo, setOutPutData] = useState([]);
    const [startAt, setStartAt] = useState("");
    const [endAt, setEndAt] = useState("");


    const fetchWeeklyDispatcherBuses = async () => {
        try{
            const data =  await DispatherAPI.findAllDispatchers();
            setAllDispatcherBus(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des affectations du jours !");
        }
    };

    useEffect(() => {
        fetchWeeklyDispatcherBuses();
    },[]);

    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    const itemsPerPage = 300;

    const filteredDispatchers = allDispatcherBus.filter(

        b =>
            b.bus_name.toLowerCase().includes(search.toLowerCase()) ||
            b.driver_name.toLowerCase().includes(search.toLowerCase()) ||
            b.escort_name.toLowerCase().includes(search.toLowerCase()) ||
            b.city_from.toLowerCase().includes(search.toLowerCase()) ||
            b.city_to.toLowerCase().includes(search.toLowerCase())
    );

    const paginatedDispatchers = Pagination.getData(
        filteredDispatchers,
        currentPage,
        itemsPerPage
    );

    const handleStartedDate = ({currentTarget}) => {
        setStartAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleEndedDate = ({currentTarget}) => {
        setEndAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleBtns = () => {

        if(startAt !== "" && endAt === "") {
            const filtered = allDispatcherBus.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") === Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        } else if (startAt !== "" && endAt !== "") {
            const filtered = allDispatcherBus.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD")
                    && Moment(item.created_at).format("YYYY/MM/DD") <= Moment(endAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        }else{
            const filtered = allDispatcherBus.filter(item=>
                Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered)
        }

    };


    let count = 1;

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">

                        <div className="card-body">
                            <div className="row">
                                <div className="col-5">
                                    <h5 className="card-title">Total {allDispatcherBus ? allDispatcherBus.length : 0}</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-group mt-2">
                                        <input type="text" onChange={handleSearch}
                                               value={search} className="form-control"
                                               placeholder="Rechercher tout ..." />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <Link to="/dispatcher/buses/addDispatcherBus" className="mt-2 btn btn-sm btn-primary">
                                        Programmer un bus <i className="bi bi-bus-front"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Date debut
                                        </label>
                                        <input type="date" onChange={handleStartedDate} value={startAt}
                                               name="startDate" id="startDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mt-3">
                                        <label htmlFor="endDate" className="text-dark">
                                            Date fin
                                        </label>
                                        <input type="date" onChange={handleEndedDate} value={endAt}
                                               name="endDate" id="endDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-3">
                                        <label htmlFor="filter" className="text-dark">.</label>
                                        <input type="button" onClick={handleBtns} value="Filtrer"
                                               name="filter" id="filter" className="form-control btn btn-dark" placeholder="Filtrer ..."
                                        />
                                    </div>
                                </div>
                            </div>
                            <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Bus</th>
                                    <th scope="col">Priorité</th>
                                    <th scope="col">Origine</th>
                                    <th scope="col">Destination</th>
                                    <th scope="col">Driver</th>
                                    <th scope="col">Escorteur</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Affecté</th>
                                    <th scope="col"><i className="bi bi-gear-fill"></i></th>
                                </tr>
                                </thead>
                                {!loading && <tbody>
                                {paginatedDispatchers.map((dispatch) => (
                                    <tr key={dispatch.id}>
                                        <th scope="row">{count++}</th>
                                        <td><b>{dispatch.bus_name}</b></td>
                                        <td><b>{dispatch.priority}</b></td>
                                        <td className="text-danger">{dispatch.city_from}</td>
                                        <td className="text-success">{dispatch.city_to}</td>
                                        <td>{dispatch.driver_name}</td>
                                        <td>{dispatch.escort_name}</td>
                                        <td>{Moment(dispatch.day).format("DD-MM-YYYY")}</td>
                                        <td>{dispatch.programed ? (<span className="text-success">Oui</span>):(<span className="text-danger">Non</span>)}</td>
                                        <td>
                                            {!dispatch.programed ? <Link className="btn btn-sm btn-primary" to={`/dispatcher/dispatch/${dispatch.id}/edit`}>
                                                <i className="bi bi-pen-fill"></i>
                                                <span>Modif</span>
                                            </Link> : <></>}
                                        </td>
                                    </tr>
                                ))
                                }

                                </tbody>}
                            </table>

                            {loading && <TableLoader width={300} height={350} />}

                            {itemsPerPage < filteredDispatchers.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredDispatchers.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Allavalablities;
