import axios from "axios";
import {USERS_API, SETTING_API} from "../../config";
import Cache from "../cache";


async function findAllManagers() {
    const cachedManagers = await Cache.get("AdminManagers");
    //if(cachedManagers) return cachedManagers;
    return axios
        .get(USERS_API + "/managers")
        .then(response => {
            const users = response.data.managers;
            Cache.set("AdminManagers", users);
            return users;
        });
}

async function findAllMaintainers() {
    const cachedMaintainers = await Cache.get("AdminMaintainers");
    //if(cachedManagers) return cachedManagers;
    return axios
        .get(USERS_API + "/maintainers")
        .then(response => {
            const users = response.data.maintainers;
            Cache.set("AdminMaintainers", users);
            return users;
        });
}

async function findAllDispatchers() {
    const cachedDispatchers = await Cache.get("AdminDispatchers");
    //if(cachedManagers) return cachedManagers;
    return axios
        .get(USERS_API + "/dispatchers")
        .then(response => {
            const users = response.data.dispatchers;
            Cache.set("AdminDispatchers", users);
            return users;
        });
}

async function findAllVendeurs() {
    const cachedVendeurs = await Cache.get("AdminVendeurs");
    //if(cachedVendeurs) return cachedVendeurs;
    return axios
        .get(USERS_API + "/vendeurs")
        .then(response => {
            const users = response.data.vendeurs;
            Cache.set("AdminVendeurs", users);
            return users;
        });
}

async function findAllManifest() {
    return axios
        .get(USERS_API + "/getAllManifests")
        .then(response => {
            return response.data.vendeurs;
        });

}

async function findAllFretVendeurs() {
    const cachedFretVendeurs = await Cache.get("AdminFretVendeurs");
    //if(cachedFretVendeurs) return cachedFretVendeurs;
    return axios
        .get(USERS_API + "/fret_operators")
        .then(response => {
            const users = response.data.vendeurs;
            Cache.set("AdminFretVendeurs", users);
            return users;
        });
}

async function findPassengers() {
    const cachedPassengers = await Cache.get("AdminPassengers");
    if(cachedPassengers) return cachedPassengers;
    return axios
        .get(SETTING_API + "/passengers")
        .then(response => {
            const passengers = response.data.passengers;
            Cache.set("AdminPassengers", passengers);
            return passengers;
        });
}


async function findAllDrivers() {
    const cachedDrivers = await Cache.get("AdminDrivers");
    //if(cachedDrivers) return cachedDrivers;
    return axios
        .get(USERS_API + "/drivers")
        .then(response => {
            const users = response.data.drivers;
            Cache.set("AdminDrivers", users);
            return users;
        });
}

async function findABusManagers() {
    const cachedBusManagers = await Cache.get("AdminBusManagers");
    //if(cachedBusManagers) return cachedBusManagers;
    return axios
        .get(USERS_API + "/bus_managers")
        .then(response => {
            const users = response.data.bus_managers;
            Cache.set("AdminBusManagers", users);
            return users;
        });
}

async function findComptables() {
    const cachedComptables = await Cache.get("AdminComptables");
    //if(cachedComptables) return cachedComptables;
    return axios
        .get(USERS_API + "/comptables")
        .then(response => {
            const users = response.data.comptables;
            Cache.set("AdminComptables", users);
            return users;
        });
}

async function findAll() {
    const cachedUsers = await Cache.get("AdminUsers");
    //if(cachedUsers) return cachedUsers;
    return axios
        .get(USERS_API)
        .then(response => {
            const users = response.data.users;
            Cache.set("AdminUsers", users);
            return users;
        });
}

async function find(id){

    const cachedUsers = await Cache.get("AdminUsers." + id);
    //if(cachedUsers) return cachedUsers;
    return axios.get(USERS_API + "/" + id)
        .then(response => {
            const user = response.data.user;
            Cache.set("AdminUsers." + id, user);
            return user;
        });
}

async function findVendor(id) {
    const cachedUsers = await Cache.get("AdminUsers." + id);
    //if(cachedUsers) return cachedUsers;
    return axios.get(USERS_API + "/vendeur/" + id)
        .then(response => {
            const user = response.data.user;
            Cache.set("AdminUsers." + id, user);
            return user;
        });
}


function deleteUser(id) {

    return axios
        .delete(USERS_API + "/" + id)
        .then(async response => {
            const cachedUsers = await Cache.get("users");

            if(cachedUsers){
                Cache.set("AdminUsers", cachedUsers.filter(i => i.id != id));
            }

            return response;
        });
}

function update(id, user){

    return axios.post(USERS_API + "/store", {...user, id: id})
        .then(async response => {
            return response;
        });
}

function updatePassword(id, user) {
    return axios.post(USERS_API + "/updatePassword", {...user, id: id})
        .then(async response => {
            return response;
        });
}

function affectToPv(vendeur) {
    return axios.post(USERS_API + "/affetToPoV", {...vendeur})
        .then(async response => {
            const cachedVendeurs = await Cache.get("AdminVendeurs");

            if(cachedVendeurs){
                const index = cachedVendeurs.findIndex(b => b.id === +vendeur.id);
                cachedVendeurs[index] = response.data.user;
            }

            return response;
        });
}

function create(user){

    return axios.post(USERS_API + "/store", user)
        .then(async response => {
            const cachedUsers = await Cache.get("AdminUsers");

            if(cachedUsers){
                Cache.set("AdminUsers", [...cachedUsers, response.data.user]);
            }

            return response;
        });
}

export default {
    findAllVendeurs,
    findAllFretVendeurs,
    findAllManifest,
    findAllManagers,
    findAllDispatchers,
    findAllMaintainers,
    affectToPv,
    findAll,
    find,
    findVendor,
    create,
    update,
    updatePassword,
    delete : deleteUser,
    findAllDrivers,
    findPassengers,
    findABusManagers,
    findComptables
};