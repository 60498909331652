import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import { toast } from "react-toastify";
import DocumentAPI from "../../../apis/adminApi/DocumentAPI";
import TableLoader from "../../loaders/TableLoader";
import Pagination from "../../assets/Pagination";

const AdminDocumentIndex = () => {

    const [documents, setDocuments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    //Permet d'aller recuperer les invoices
    const fetchDocuments = async () => {
        try{
            const data =  await DocumentAPI.findDocuments();
            setDocuments(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des documents !");
        }
    };



    //Au chargement du composant on va chercher les documents
    useEffect(() => {
        fetchDocuments();
    }, []);

    const tooltip = (data) => (
        <Tooltip className="in" id="tooltip">
            <p>{data}</p>
        </Tooltip>
    );

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des documents en fonction de la recherche

    const filteredDocuments = documents.filter(
        b =>
            b.doc_name.toLowerCase().includes(search.toLowerCase()) ||
            b.doc_description.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedDocuments = Pagination.getData(
        filteredDocuments,
        currentPage,
        itemsPerPage
    );

    let numerotation = 1;


    return (
        <>
            <div className="pagetitle">
                <h1>Les documents</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les documents de bord</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {documents ? documents.length : 0}</h5>
                                    </div>
                                    <div className="col-3">
                                        <Link className="btn btn-sm btn-primary mt-3 d-block" to="/admin/documents/add">
                                            <i className="bi bi-plus-circle"></i> Ajouter un document
                                        </Link>
                                    </div>
                                    <div className="col-3">
                                        <Link className="btn btn-sm btn-success mt-3 d-block" to="/admin/documents/bus_documents/add">
                                            <i className="bi bi-plus-circle"></i> Lier un document à un bus
                                        </Link>
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                </div>
                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nom</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedDocuments.map((document) => (
                                            <tr key={document.id}>
                                                <th scope="row">{document.id}</th>
                                                <td>{document.doc_name}</td>
                                                <td>{document.doc_description}</td>
                                                <td>
                                                    <Link className="btn btn-sm btn-outline-primary mx-1" to={`/admin/documents/edit/${document.id}`}>
                                                        <i className="bi bi-pen-fill"></i>
                                                    </Link>
                                                    {/*<Link className="btn btn-sm btn-danger">*/}
                                                        {/*<i className="bi bi-basket"></i>*/}
                                                    {/*</Link>*/}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredDocuments.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredDocuments.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AdminDocumentIndex;
