import axios from "axios";
import {BUSES_API} from "../../config";
import Cache from "../cache";

async function findAll() {
    //const cachedBuses = await Cache.get("AdminBuses");
    //if(cachedBuses) return cachedBuses;
    return axios
        .get(BUSES_API)
        .then(response => {
            //const buses = response.data.buses;
            //Cache.set("AdminBuses", buses);
            return response.data.buses;
        });
}

async function findDailyAvailabilities() {
    const cachedBuses = await Cache.get("AdminBuses");
    if(cachedBuses) return cachedBuses;
    return axios
        .get(BUSES_API + "/availablitiesDaily")
        .then(response => {
            const dailyavailablities = response.data;
            Cache.set("dailyavailablities", dailyavailablities);
            return dailyavailablities;
        });
}

async function find(id){

    const cachedBuses = await Cache.get("AdminBuses." + id);
    if(cachedBuses) return cachedBuses;
    return axios.get(BUSES_API + "/" + id)
        .then(response => {
            const bus = response.data.bus;
            Cache.set("AdminBuses." + id, bus);
            return bus;
        });
}


function deleteBus(id) {

    return axios
        .delete(BUSES_API + "/" + id)
        .then(async response => {
            const cachedBuses = await Cache.get("buses");

            if(cachedBuses){
                Cache.set("AdminBuses", cachedBuses.filter(i => i.id != id));
            }

            return response;
        });
}

function updateBus(id, bus){

    return axios.post(BUSES_API + "/store", {...bus, id: id})
        .then(async response => {
            const cachedBuses = await Cache.get("AdminBuses");

            const cachedBuse = await Cache.get("AdminBuses." + id);

            if(cachedBuse){
                Cache.set("AdminBuses." + id, response.data.bus);
            }

            if(cachedBuses){
                const index = cachedBuses.findIndex(b => b.id === +id);
                cachedBuses[index] = response.data.bus;
            }


            return response;
        });
}

function create(bus){

    return axios.post(BUSES_API + "/store", bus)
        .then(async response => {
            const cachedBuses = await Cache.get("AdminBuses");

            if(cachedBuses){
                Cache.set("AdminBuses", [...cachedBuses, response.data.bus]);
            }

            return response;
        });
}


export default {
    findAll,
    findDailyAvailabilities,
    find,
    create,
    updateBus,
    delete : deleteBus,
};