import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import MechanicAPI from "../../../apis/maintainerApi/MaintainerAPI";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";
import FormContentLoader from "../../loaders/FormContentLoader";
import MaintainerAPI from "../../../apis/maintainerApi/MaintainerAPI";
import Textarea from "../../forms/Textarea";
import BielletterieAPI from "../../../apis/adminApi/bielleterieAPI";

const EditRequestBus = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {requestId} = useParams();
    const [showLoader, setShowLoader] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [bus, setBus] = useState([]);

    const [maintenance, setMaintenance] = useState({
        mechanic_id : "", id : requestId, observation: "", driver_id: ""
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        mechanic_id : "", driver_id: ""
    });



    const fetchDrivers = async () => {
        try{
            const data =  await MaintainerAPI.findDrivers();
            setDrivers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des chauffeurs !");
        }
    };


    const fetchRequest = async id => {
        try{
            const { driver_id, request_items, total_amount, observation, bus_id,bus_numberplate, id} = await MechanicAPI.findOneRequest(requestId);
            setMaintenance({ bus_id,request_items, total_amount, driver_id,observation,bus_numberplate, id });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le maintenance démandé !");
            //navigate("/maintainer/maintenances");
        }
    };



    useEffect(() => {
        setEditing(true);
        fetchRequest(requestId);
    }, [requestId]);

    useEffect(() => {
        fetchDrivers();
    },[]);


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setMaintenance({...maintenance, [name] : value})
    };

    let redirect = "requests/allBus";



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await MaintainerAPI.updateRequest(maintenance, requestId);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Demande modifiée avec succès");
            navigate('/maintainer/requests/allBus');
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {error} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire!");
            }else if (error) {
                toast.error(error);
            }
        }
    };


    const handleDelete = async id => {

        try{
            await MaintainerAPI.deleteRequestItem(id);
            toast.success("Le ticket a bien été supprimée !");
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de la suppression du tickets !");
        }
    };



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Ajouter une demande d'achat</h5>
                                <div className="row">
                                    <div className="col-6">
                                        <Field
                                            name="bus_id"
                                            disabled="disabled"
                                            value={"Bus : "+maintenance.bus_numberplate+" ,Total " + maintenance.total_amount +" CDF"}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <h4>Les pieces</h4>
                                        <ul>
                                            {maintenance.request_items && maintenance.request_items.map((item) =>
                                                <li>
                                                    {item.requested_quantity} {item.product_name} , Prix : {item.unit_price} CDF
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                handleDelete(item.id)
                                                            }
                                                        }}
                                                        className="btn btn-sm btn-danger">
                                                          <i className="bi bi-trash"></i>
                                                    </button>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="driver_id">Chauffeur</label>
                                        <select
                                            className={"form-control " + ( errors.driver_id && "is-invalid")}
                                            //size={(buses.length)}
                                            name="driver_id"
                                            id="driver_id"
                                            value={maintenance.driver_id}
                                            onChange={handleChange}
                                        >

                                            <option value="" selected>Choisir...</option>
                                            {drivers && drivers.map((driver) => (
                                                <option style={{fontWeight:"bold"}} key={driver.id} value={driver.id}>
                                                    {driver.name} </option>
                                            ))
                                            }
                                        </select>
                                        {errors.driver_id && <div className="invalid-feedback mb-4">{errors.driver_id}</div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <Field
                                            name="observation"
                                            error={errors.observation}
                                            value={maintenance.observation}
                                            onChange={handleChange}
                                            label="Observation"
                                            placeholder="Observation"
                                        />
                                    </div>

                                    <Field
                                        name="bus_id"
                                        type="hidden"
                                        value={bus.id}
                                    />
                                </div>


                                <MyButton
                                    text="Modifier"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to={`/maintainer/${redirect}`} className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </div>
                    </form> }
                </div>
            </div>
        </div>
    );
};

export default EditRequestBus;
