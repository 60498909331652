import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import MechanicAPI from "../../apis/maintainerApi/MaintainerAPI";
import Field from "../forms/Field";
import MyButton from "../assets/MyButton";
import FormContentLoader from "../loaders/FormContentLoader";

const EditMechanic = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {mechanicId} = useParams();
    const [showLoader, setShowLoader] = useState(false);
    const [checked, setChecked] = useState(false);

    const [utilisateur, setUser] = useState({
        name : "",  phone : "", city : "", id : mechanicId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        name : "", phone : "", city : ""
    });

    const fetchUser = async id => {
        try{
            const { name, phone, city} = await MechanicAPI.findOneMechanic(id);
            setUser({ name, phone, city });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le mecanicien démandé !");
            navigate("/maintainer/mechanics");
        }
    };



    useEffect(() => {
        setEditing(true);
        fetchUser(mechanicId);
    }, [mechanicId]);


    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setUser({...utilisateur, [name] : value})
    };

    let redirect = "mechanics";



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await MechanicAPI.updateMechanics(mechanicId, utilisateur);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Mecanicien modifié avec succès");
            navigate('/maintainer/mechanics')
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {error} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du mecanicien !");
            }else if (error) {
                toast.error(error);
            }
        }
    };



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Modifier un mecanicien</h5>

                                <Field
                                    name="name"
                                    error={errors.name}
                                    value={utilisateur.name}
                                    onChange={handleChange}
                                    label="Nom Complet"
                                />

                                <Field
                                    name="phone"
                                    error={errors.phone}
                                    value={utilisateur.phone}
                                    onChange={handleChange}
                                    label="Telephone"
                                />


                                <Field
                                    name="city"
                                    error={errors.city}
                                    value={utilisateur.city}
                                    onChange={handleChange}
                                    label="Ville"
                                />


                                <MyButton
                                    text="Modifier"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to={`/maintainer/${redirect}`} className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </div>
                    </form> }
                </div>
            </div>
        </div>
    );
};

export default EditMechanic;
