import React, { useEffect, useState } from 'react';
import {useForm} from "react-hook-form";
import TableRows from "./AdmintableRows";
import {toast} from "react-toastify";
import StoreAPI from "../../../apis/adminApi/StoreAPI";
import UserAPI from "../../../apis/adminApi/userAPI";
import Select from "../../forms/Select";
import {useNavigate} from "react-router-dom";
import MyButton from "../../assets/MyButton";
import AdminTableRows from "./AdmintableRows";

const AdminAddStoreMovement = () => {

    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);

    const { formState } = useForm();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    const [stores, setStores] = useState([]);
    const [receivers, setReceivers] = useState([]);

    const [errors, setErrors] = useState({
        type : ""
    });

    const [rowsData, setRowsData] = useState([]);


    const getStore = async () => {
        try{
            const data =  await StoreAPI.findAll();
            setStores(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des produits !");
        }
    };

    const getReceivers = async () => {
        try{
            const data =  await UserAPI.findAllMaintainers();
            setReceivers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des maintenanciens !");
        }
    };

    useEffect(() => {
        getStore();
        getReceivers();
    },[]);


    const addTableRows = ()=>{

        const rowsInput={
            store_id:'', quantity: ''
        };
        setRowsData([...rowsData, rowsInput])

    };
    const deleteAdminTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    };

    const [movement, setMovement] = useState({
        type : 1,receiver_id : "" ,items : rowsData
    });

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        setMovement({...movement, [name] : value});
    };

    const handleForChange = (index, event) => {
        const { name, value } = event.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
        setMovement({...movement, items : rowsData});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await StoreAPI.addMouvement(movement);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Mouvement ajouté avec succès");
            navigate('/admin/stores')
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error(response.data.error);
            }else{
                toast.error(response.data.message);
            }
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-11">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <h4 className="card-title">Entré de produit en stock</h4>
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>Nom du produit</th>
                                                <th>Quantité</th>
                                                <th>
                                                    <a className="btn btn-success" onClick={addTableRows} >
                                                        <i className="bi bi-plus-circle"></i> Ajouter un produit
                                                    </a>
                                                </th>
                                            </tr>

                                            </thead>
                                            <tbody>

                                            <TableRows rowsData={rowsData} deleteAdminTableRows={deleteAdminTableRows} handleChange={handleForChange} />

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-4">

                                    </div>
                                </div>

                                {(rowsData[0] && rowsData[0].store_id !== "" && rowsData[0].quantity !== "" && movement.type !== "")
                                    && (<MyButton
                                        text="Enregistrer"
                                        onSubmit={handleSubmit}
                                        loading={showLoader}
                                        disabled={showLoader}
                                    />)}

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminAddStoreMovement;
