import React, { useEffect, useState } from 'react';

import {Link, useNavigate} from "react-router-dom";
import {useUser} from "../../../Services/userProvider";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import PvsAPI from "../../../apis/adminApi/posAPI";
import UserAPI from "../../../apis/adminApi/userAPI";
import CheckBoxField from "../../forms/CheckBoxField";
import Select from "../../forms/Select";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";

const AddUser = () => {

    const { formState } = useForm();
    const { isSubmitting } = formState;
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);

    const [pvs, setPvs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);

    const [user, setUser] = useState({
        name : "", email : "", phone : "", password: "123123123",
        password_confirmation : "123123123", role: "", pvId : "1", city : ""
    });

    const [errors, setErrors] = useState({
        name : "", email : "", phone : "", password: "",
        password_confirmation : "", role: "",  city : ""
    });

    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setUser({...user, [name] : value})
    };

    const fetchPvs = async () => {
        try{
            const data =  await PvsAPI.findAll();
            setPvs(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des pvs !");
        }
    };

    useEffect(() => {
        setEditing(true);
        fetchPvs();
    }, []);

    let redirect = "";
    if (user.role === "ROLE_VENDOR") {
        redirect = "vendeurs";
    }else if (user.role === "ROLE_MANAGER") {
        redirect = "managers";
    }else if (user.role === "ROLE_BUS_DRIVER") {
        redirect = "drivers";
    }else if (user.role === "ROLE_BUS_MANAGER") {
        redirect = "bus_managers";
    }else if (user.role === "ROLE_FRET") {
        redirect = "fretOperateurs";
    }else if (user.role === "ROLE_MANIFEST") {
        redirect = "manifests";
    }else if (user.role === "ROLE_DISPATCHER") {
        redirect = "dispatchers";
    }else if (user.role === "ROLE_MAINTAINER") {
        redirect = "maintainers";
    }



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await UserAPI.create(user);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Utilisateur modifié avec succès");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {error} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification de l'utilisateur !");
            }else if (error) {
                toast.error(error);
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-10">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h5 className="card-title">Ajouter un utilisateur</h5>
                                <Field
                                    name="name"
                                    error={errors.name}
                                    value={user.name}
                                    onChange={handleChange}
                                    label="Nom Complet"
                                />

                                <Field
                                    name="email"
                                    type="email"
                                    error={errors.email}
                                    value={user.email}
                                    onChange={handleChange}
                                    label="Email"
                                />


                                <Field
                                    name="phone"
                                    error={errors.phone}
                                    value={user.phone}
                                    onChange={handleChange}
                                    label="Telephone"
                                />


                                <Field
                                    name="city"
                                    error={errors.city}
                                    value={user.city}
                                    onChange={handleChange}
                                    label="Ville"
                                />


                                <Select name="role" label="Role" value={user.role} error={errors.role} onChange={handleChange}>
                                    <option value="ROLE_MANAGER">Gestionnaire bus</option>
                                    <option value="ROLE_VENDOR">Vendeur</option>
                                    <option value="ROLE_FRET">Operateur Fret</option>
                                    <option value="ROLE_BUS_DRIVER">Chauffeur</option>
                                    <option value="ROLE_BUS_MANAGER">Escorteur</option>
                                    <option value="ROLE_ACCOUNTING">Comptable</option>
                                    <option value="ROLE_MANIFEST">Manifeste</option>
                                    <option value="ROLE_DISPATCHER">Dispatcher</option>
                                    <option value="ROLE_MAINTAINER">Mantenancien</option>
                                </Select>

                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to={`/admin/${redirect}`} className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddUser;
