import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import { toast } from "react-toastify";
import MaintainerAPI from "../../../apis/adminApi/DocumentAPI";
import SettingApi from "../../../apis/adminApi/settingAPI";
import TableLoader from "../../loaders/TableLoader";
import Pagination from "../../assets/Pagination";
import Moment from "moment";
import PrintComponent from "./printer";

const AdminAllBusMechanicTask = () => {

    const [mechanicsTasks, setMechanicTasks] = useState([]);
    const [bus, setBus] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [setting, setSetting] = useState([]);

    //Permet d'aller recuperer les invoices
    const fetchMechanicTasks = async () => {
        try{
            const data =  await MaintainerAPI.findMechanicTasks();
            setMechanicTasks(data);
            setBus([]);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des taches !");
        }
    };

    const fetchSetting = async () => {
        try{
            const data =  await SettingApi.findSetting();
            setSetting(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };



    //Au chargement du composant on va chercher les mechanicsTasks
    useEffect(() => {
        fetchSetting();
        fetchMechanicTasks();
    }, []);


    const tooltip = (data) => (
        <Tooltip className="in" id="tooltip">
            <p>{data}</p>
        </Tooltip>
    );

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 500;
    const filteredMechanicTasks = mechanicsTasks.filter(
        b =>
            b.city.toLowerCase().includes(search.toLowerCase()) ||
            b.driver_name.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
            b.complaint.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedMechanicTasks = Pagination.getData(
        filteredMechanicTasks,
        currentPage,
        itemsPerPage
    );

    let counter = 1;


    return (
        <>
            <div className="pagetitle">
                <h1>Les taches de mecaniciens</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Tache de mecaniciens</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {mechanicsTasks ? mechanicsTasks.length : 0}</h5>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mt-3">
                                            <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                        </div>
                                    </div>
                                </div>

                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Ville</th>
                                        <th scope="col">MECANICIEN</th>
                                        <th scope="col">Bus</th>
                                        <th scope="col">Chauffeur</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">PLAINTE</th>
                                        <th scope="col">PIECE</th>
                                        <th scope="col">OBSERVATION</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedMechanicTasks.map((mechanicTask) => (
                                        <tr key={mechanicTask.id}>
                                            <th scope="row">{counter++}</th>
                                            <td>{mechanicTask.city}</td>
                                            <td>
                                                <ul>
                                                    {mechanicTask.mechanics_in_task.map((item) =>
                                                        <li>{item.mechanic_name}</li>
                                                    )}
                                                </ul>
                                            </td>
                                            <td>{mechanicTask.bus_numberplate} </td>
                                            <td>{mechanicTask.driver_name}</td>
                                            <td>Le {Moment(mechanicTask.input_date).format("DD/MM/YYYY")}</td>
                                            <td>{mechanicTask.complaint}</td>
                                            <td>
                                                <ul>
                                                    {mechanicTask.products_in_tasks.map((item) =>
                                                        <li>{item.product_name} : {item.task_id} {item.task_name}</li>
                                                    )}
                                                </ul>
                                            </td>
                                            <td>{mechanicTask.observation}</td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredMechanicTasks.length &&
                                    <Pagination
                                        currentPage={currentPage}
                                        itemsPerPage={itemsPerPage}
                                        length={filteredMechanicTasks.length}
                                        onPageChange={handleChangeCurrentPage}
                                    />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AdminAllBusMechanicTask;
