import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import {useForm} from "react-hook-form";
import BrandAPI from "../../../apis/adminApi/BrandAPI";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";

const AdminAddBrand = () => {
    const { formState } = useForm();
    const { isSubmitting } = formState;
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState(false);

    const [brand, setBrand] = useState({
        brand_name : ""
    });

    const [errors, setErrors] = useState({
        brand_name : ""
    });

    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setBrand({...brand, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await BrandAPI.create(brand);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Brand ajouté avec succès");
            navigate("/admin/brands");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire !");
            }
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h5 className="card-title">Ajouter une marque</h5>
                                <div className="row">
                                    <div className="col-10">
                                        <Field
                                            name="brand_name"
                                            error={errors.brand_name}
                                            value={brand.brand_name}
                                            onChange={handleChange}
                                            label="Nom de la marque"
                                            placeholder="Nom"
                                        />
                                    </div>
                                </div>


                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to="/admin/brands" className="btn btn-info mx-2">Revenir aux marques</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminAddBrand;
