import React, { useEffect, useState, useRef } from 'react';
import './printer.css';
import logo from './logo.png';
import Moment from "moment";

class ComponentToPrintFret extends React.Component {

    render() {
        const bus = this.props.bus;
        const param = this.props.param;
        const keyCity = this.props.keyCity;
        let counter1 = 1;
        let counter2 = 1;

        const mystyle = {
            color: "black",
            padding: "20px 20px",
            fontSize: "15px",
        };

        const ticketsByDestination = (data) => {
            const destinations = data.tickets.map((key, value) => key = key.city_to);
            let data_table = [];
            destinations.forEach((val) => {
                if (data_table[val]) {
                    data_table[val] += 1;
                } else {
                    data_table[val] = 1;
                }
            });
            return data_table;
        };

        return (
            <>
                {bus ? (<div style={mystyle}>
                        <center><img  src={logo} alt="logo" /></center>
                        <p>{param ? param.company_address : ""} <br/>
                            Date : Le {Moment(bus.created_at).format("DD-MM-YYYY")} <br/>
                            Bus : <b>{bus.bus_numberplate}</b> <br/>
                            Provenance : <b>{bus.from}</b>, Destination : <b>{bus.to}</b>, Depart : <b>{bus.bus_hour}</b> <br/>
                            Chauffeur : <b>{bus.driver_name}</b>, Escorteur : <b>{bus.manager_name}</b>
                        </p>

                        <hr/>
                        {bus.colis.length > 0 ? (
                            <>
                                <h3><b>MANIFEST COLIS - <span className="text-primary">{keyCity}</span></b></h3>

                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">N°</th>
                                        <th scope="col">Exp</th>
                                        <th scope="col">Tél</th>
                                        <th scope="col">Code</th>
                                        <th scope="col">Designation</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Destination</th>
                                        <th scope="col">Destinataire</th>
                                        <th scope="col">Tél</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {bus.colis.map((fret) => (
                                        keyCity === fret.city_to ? (<tr key={fret.id}>
                                            <td>{counter2++}</td>
                                            <td>{fret.expediteur}</td>
                                            <td>{fret.expediteur_phone}</td>
                                            <td><b>{fret.reference}</b></td>
                                            <td>{fret.name}</td>
                                            <td className="text-center"><b>{fret.code}</b></td>
                                            <td className="text-center"><b>{fret.city_to}</b></td>
                                            <td>{fret.recipient}</td>
                                            <td>{fret.recipient_phone}</td>

                                        </tr>) : (<></>)

                                    ))}
                                    </tbody>
                                </table>
                            </>
                        ) : (<></>)}
                        <hr/>
                    </div>):
                    (<></>)}
            </>
        );
    }
};
export default ComponentToPrintFret;
