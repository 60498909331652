import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import MechanicAPI from "../../../apis/maintainerApi/MaintainerAPI";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";
import FormContentLoader from "../../loaders/FormContentLoader";
import MaintainerAPI from "../../../apis/maintainerApi/MaintainerAPI";
import Textarea from "../../forms/Textarea";

const MaintainerAddMechanicTask = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {busId} = useParams();
    const [showLoader, setShowLoader] = useState(false);
    const [mechanics, steMechanics] = useState([]);
    const [products, setProducts] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [bus, setBus] = useState([]);

    const [mechanicTask, setMechanicTask] = useState({
        mechanic_id : "", bus_id : busId, complaint: "",  observation: "", driver_id: ""
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        complaint: "",  observation: "", driver_id: "", mechanic_id:""
    });

    const fetchMechanics = async () => {
        try{
            const data =  await MechanicAPI.findMechanics();
            steMechanics(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des mechaniciens !");
        }
    };

    const fetchDrivers = async () => {
        try{
            const data =  await MaintainerAPI.findDrivers();
            setDrivers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des chauffeurs !");
        }
    };

    const fetchProducts = async () => {
        try{
            const data =  await MaintainerAPI.findStocks();
            setProducts(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des mecaniciens !");
        }
    };

    const fetchBus = async id => {
        try{
            const { bus_numberplate, id} = await MechanicAPI.findOneBus(busId);
            setBus({ bus_numberplate, id });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le mechanicTask démandé !");
            //navigate("/maintainer/mechanicTasks");
        }
    };



    useEffect(() => {
        setEditing(true);
        fetchBus(busId);
    }, [busId]);

    useEffect(() => {
        fetchMechanics();
        fetchProducts();
        fetchDrivers();
    },[]);


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setMechanicTask({...mechanicTask, [name] : value})
    };

    let redirect = "mechanicsTasks/allBus";



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await MaintainerAPI.createMechanicTasks(mechanicTask, busId);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Entretien ajouté avec succès");
            navigate('/maintainer/mechanicsTasks/allBus')
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {error} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire!, Le mechanicien est obligatoire");
            }else if (error) {
                toast.error(error);
            }
        }
    };



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Ajouter un entretien de bus</h5>
                                <div className="row">
                                    <div className="col-6">
                                        <Field
                                            name="bus_id"
                                            disabled="disabled"
                                            value={"Bus " + bus.bus_numberplate}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <div className="mb-3">
                                            <label>Choisir un mechanicien</label>
                                            {mechanics && mechanics.map(mechanic =>
                                                <div className="form-check" key={mechanic.id}>
                                                    <input onChange={handleChange} value={mechanic.id}
                                                           className="form-check-input" type="radio"
                                                           name="mechanic_id" id={mechanic.to}/>
                                                    <label className="form-check-label" htmlFor={mechanic.to}>
                                                        <b>{mechanic.name}</b>
                                                    </label>
                                                </div>
                                            )}
                                            {errors.mechanic_id && <div className="invalid-feedback mb-4">{errors.mechanic_id}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {/*<div className="col-6">*/}
                                    {/*    <div className="mb-3">*/}
                                    {/*        <label>Choisir un Produit</label>*/}
                                    {/*        {products && products.map(product =>*/}
                                    {/*            <div className="form-check" key={product.id}>*/}
                                    {/*                <input onChange={handleChange} value={product.id}*/}
                                    {/*                       className="form-check-input" type="radio"*/}
                                    {/*                       name="mechanic_id" id={product.id} />*/}
                                    {/*                <label className="form-check-label" htmlFor={product.id}>*/}
                                    {/*                    <b>{product.stock_name}</b>*/}
                                    {/*                </label>*/}
                                    {/*            </div>*/}
                                    {/*        )}*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="col-6">
                                        <label htmlFor="driver_id">Chauffeur</label>
                                        <select
                                            className={"form-control " + ( errors.driver_id && "is-invalid")}
                                            //size={(buses.length)}
                                            name="driver_id"
                                            id="driver_id"
                                            value={mechanicTask.driver_id}
                                            onChange={handleChange}
                                        >

                                            <option value="" selected>Choisir...</option>
                                            {drivers && drivers.map((driver) => (
                                                <option style={{fontWeight:"bold"}} key={driver.id} value={driver.id}>
                                                    {driver.name} </option>
                                            ))
                                            }
                                        </select>
                                        {errors.driver_id && <div className="invalid-feedback mb-4">{errors.driver_id}</div>}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <Field
                                            name="complaint"
                                            error={errors.complaint}
                                            value={mechanicTask.complaint}
                                            onChange={handleChange}
                                            label="Plainte"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <Textarea
                                            name="mechanicTask_name"
                                            error={errors.observation}
                                            defaultValue={mechanicTask.observation}
                                            onChange={handleChange}
                                            label="Observation"
                                            placeholder="Observation"
                                        />
                                    </div>

                                    <Field
                                        name="bus_id"
                                        type="hidden"
                                        value={bus.id}
                                    />
                                </div>


                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to={`/maintainer/${redirect}`} className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </div>
                    </form> }
                </div>
            </div>
        </div>
    );
};

export default MaintainerAddMechanicTask;
