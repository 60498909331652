import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import VendeurAPI from "../../../apis/customerApi/VendeurAPI";
import MyButton from "../../assets/MyButton";
import Field from "../../forms/Field";
import FormContentLoader from "../../loaders/FormContentLoader";

const ShareBus = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {assigmentId} = useParams();

    const [showLoader, setShowLoader] = useState(false);

    const [vendors, setVendors] = useState([]);
    const [agence, setAgence] = useState({
        assignmentId: assigmentId, vendorId: ""
    });

    const [assignment, setAssignment] = useState({
        bus_number : "", bus_numberplate : "", bus_number_of_seats : "",
        driver_name : "", manager_name : "", p_id : "", p_name : "",
        vendor_id : "", vendor_name : "", bus_hour : "", from : "", to : "",
        id : assigmentId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        vendorId: ""
    });


    //Recuperation d'une facture
    const fetchAssignment = async id => {
        try{
            const { bus_number, bus_numberplate, bus_number_of_seats, driver_name, manager_name, p_id, p_name ,
                vendor_id, vendor_name, bus_hour, from, to } = await VendeurAPI.findOneAssignment(id);
            setAssignment({ bus_number, bus_numberplate, bus_number_of_seats, driver_name, manager_name, p_id, p_name ,
                vendor_id, vendor_name, bus_hour, from, to });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le bus démandé !");
            navigate("/customer/newVente");
        }
    };

    const fetchSameAgencies = async () => {
        try{
            const data =  await VendeurAPI.getSameAgenceCity();
            setVendors(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des autres agences !");
        }
    };


    useEffect(() => {
        setEditing(true);
        fetchSameAgencies();
        fetchAssignment(assigmentId);
    }, [assigmentId]);


    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setAgence({...agence, [name] : value});
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await VendeurAPI.shareBus(agence);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Bus partagé avec succès");
            navigate("/customer/newVente");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du bus !");
            }
        }
    };


    return (
        <div className="container">
            {loading && <FormContentLoader />}

            {!loading && <form onSubmit={handleSubmit}>
            <div className="row">
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title text-danger">
                                    <i className="bi bi-info-circle text-success"></i> Partager ce bus avec un autre guichet ou une autre Agence de la meme ville
                                </h5>

                                <Field
                                    value={assignment.bus_numberplate}
                                    label="Bus"
                                    disabled="disabled"
                                />

                                <ul>
                                    <li>Destination : <b>{assignment.to}</b></li>
                                    <li>Heure : <b>{assignment.bus_hour}</b></li>
                                    <li>Chauffeur : <b>{assignment.driver_name}</b></li>
                                    <li>Escorteur : <b>{assignment.manager_name}</b></li>
                                </ul>

                                <h5>Agence d'origine : <b>{assignment.p_name}</b></h5>
                                <h5>Vendeur d'origine : <b>{assignment.vendor_name}</b></h5>

                                <input
                                    name="id"
                                    value={assignment.id}
                                    type="hidden"
                                />

                            </div>
                        </div>

                    </div>
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Choisir un vendeur d'une agence</h5>

                                <select
                                    className={"form-select-lg form-control mb-2 " + ( errors.vendorId && "is-invalid")}
                                    //size={(buses.length)}
                                    name="vendorId"
                                    id="vendorId"
                                    value={agence.vendorId}
                                    onChange={handleChange}
                                >

                                    <option value="" selected>Choisir...</option>
                                    {vendors && vendors.map((vend) => (
                                        <option style={{fontWeight:"bold"}} key={vend.id} value={vend.id}>
                                            {vend.name} : {vend.agency_name}
                                        </option>
                                    ))
                                    }
                                </select>
                                {errors.vendorId && <div className="invalid-feedback mb-4">{errors.vendorId}</div>}


                                {agence.vendorId !== "" ? <MyButton
                                    text="Partager"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                /> : <></>}
                                <Link to="/customer/newVente" className="btn btn-info mx-2">Annuler</Link>
                            </div>
                        </div>
                    </div>

            </div>
            </form>
            }
        </div>
    );
};

export default ShareBus;
